import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import api from '../utils/api';


export function AllowReqForQuote(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.isReqForQuote() || api.Auth.isAdmin()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}
export function AllowProformaInv(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.isProformaInv()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}
export function AllowPriAdj(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.isPriAdj()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}
export function AllowHauler(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.hauler()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}

export function AllowBusinessSetup(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.isBusinessSetup()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}

export function AllowAddProduct(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.isAddProduct()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}

export function AllowPriceAdj(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.isAddProduct() && !api.Auth.isPriAdj() || api.Auth.isAdmin()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}

export function AllowTransaction(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.isTransaction() && !api.Auth.isReqForQuote() || api.Auth.isAdmin()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}

export function AllowTransact(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.isTransaction() && !api.Auth.isPriAdj()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}

export function AllowTrans(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && !api.Auth.isTransaction() && !api.Auth.isProformaInv() || api.Auth.isAdmin()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}

export function IsManu(ComposedComponent) {

    const Authenticated = (props) => {
        const history = useHistory();

        useEffect(() => {
            if (api.Auth.isAuth() && api.Auth.isManu()) {
                history.push('/403');
            }
        }, [])

        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}

