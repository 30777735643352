import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { notifyError, notifySuccess } from '../../utils/toast';
import api from '../../utils/api';
import Paginate from '../../components/paginate';
import Limit from '../../components/limit';
import Backbutton from '../../components/backbutton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function FavouriteHauler() {

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: 'onChange'
    });
    const [haulers, setHaulers] = useState([]);
    const [favouriteHaulers, setFavouriteHaulers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [limit, setLimit] = useState(15);
    const [search, setSearch] = useState("");
    const page = 1;
    const [selectedHaulersIds, setSelectedHaulersIds] = useState([]);
    const [defaultHaulerId, setDefaultHaulerId] = useState("");
    const [v, setV] = useState();
    const auth = JSON.parse(window.localStorage.getItem('auth'));
    const history = useHistory();

    const getFavouriteHaulers = async () => {
        const response = await api.Business.loadFavouriteHaulers();
        let haulers = [];
        if (response?.data) {
            for (const s of response?.data) {
                let obj = {
                    haulerId: s.id,
                    businessName: s.businessName
                }
                haulers.push(obj);
            }
            setSelectedHaulersIds(haulers);
            setDefaultHaulerId(haulers[0]?.haulerId)
            setV(haulers?.map(x => x.businessName));
            setFavouriteHaulers(response?.data);
        }
    };

    useEffect(() => {
        try {
            getFavouriteHaulers();
        }
        catch (error) {
            console.log(error)
        }
    }, []);

    useEffect(() => {
        try {
            const getHaulers = async () => {
                const response = await api.Business.getAllHaulers(page, limit, search);
                const count = response?.data.totalCount;
                setPageCount(Math.ceil(count / limit))
                setHaulers(response?.data?.data)
            };
            getHaulers();
        }
        catch (error) {
            console.log(error)
        }
    }, [search, limit]);

    const searchHauler = (value) => {
        setSearch(value)
    }

    const addHauler = (haulerId, businessName) => {
        if (selectedHaulersIds.length === 5) {
            return notifyError(`Selected haulers must not be greater than 5`)
        }
        if (selectedHaulersIds.length === 0) {
            setDefaultHaulerId(haulerId);
        }

        const obj = {
            haulerId,
            businessName
        }
        let exist = selectedHaulersIds.find(x => x.haulerId === haulerId);
        if (exist) {
            return;
        }
        setSelectedHaulersIds([...selectedHaulersIds, obj]);
    }

    const removeHauler = (haulerId, businessName) => {
        const obj = {
            haulerId,
            businessName
        }
        let exist = selectedHaulersIds.find(x => x.haulerId === haulerId);

        if (exist) {
            const filteredHaulers = selectedHaulersIds.filter(function (item) {
                return item.haulerId !== obj.haulerId;
            })
            setSelectedHaulersIds([...filteredHaulers]);
        }
    }

    const addDefaultSupplier = (haulerId) => {
        const selected = selectedHaulersIds.find(x => x.haulerId === haulerId);
        if (!selected) {
            return notifyError("Default hauler must be among favourite haulers")
        }
        const filtered = selectedHaulersIds.filter(x => x.haulerId !== haulerId);
        filtered.unshift(selected);
        setSelectedHaulersIds(filtered);
        setDefaultHaulerId(haulerId);
    }

    const closeMod = () => {
        const checkDefaultHauler = selectedHaulersIds.find(x => x.haulerId === defaultHaulerId);
        if (!checkDefaultHauler) {
            return notifyError("Default hauler must be among favourite haulers")
        }
        setV(selectedHaulersIds?.map(x => x.businessName));
        document.getElementById("closeModal").click();
    }

    const saveHauler = async () => {
        const hasPermissionToCreate = (auth?.role.rolePermission.find(e => e.module.moduleName === "Favourite Hauler"))?.canCreate
        if (hasPermissionToCreate) {
            try {
                if (selectedHaulersIds?.length === 0) {
                    return notifyError("Haulers cannot be empty")
                }
                setLoading(true)
                let obj = {
                    haulersIds: selectedHaulersIds?.map(x => x.haulerId)
                }
                const response = await api.Business.saveFavouriteHaulers(obj);
                if (response.sucessMessage) {
                    notifySuccess(response.sucessMessage);
                    setLoading(false);
                    getFavouriteHaulers();
                } else {
                    setLoading(false)
                }
            }
            catch (error) {
                console.log(error)
                setLoading(false);
            }
        } else {
            history.push('/403');
        }
    }

    return <>
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="text-center page-head">
                            <span className="card-title">SELECT FAVOURITE HAULERS</span>
                        </div>

                        <div className="d-flex float-right mt-2">
                            {/* <Backbutton></Backbutton> */}
                            <button type="submit" onClick={handleSubmit(saveHauler)} class="btn btn-primary btn-sm btn-icon-text text-white d-flex" disabled={loading ? true : false}>
                                <i className="ti-save mr-1" title="Submit"></i>
                                <span className="d-none d-md-block">{loading ? "SAVING..." : "SAVE"}</span>
                            </button>
                        </div>
                        <form className="mt-5">
                            <div className="form-group">
                                <label htmlFor="haulers">Favourite Haulers<span className="text-danger">*</span>
                                    {errors.haulers &&
                                        <span className="text-danger font-weight-bold"> required</span>
                                    }
                                </label>
                                <div className='input-group text-capitalize'>
                                    <input type="text" className="form-control"
                                        id="haulers"
                                        name="haulers"
                                        value={v?.length > 0 ? v?.join(", ") : ""}
                                        {...register("haulers", { required: false })}
                                        readOnly
                                        placeholder="SELECT HUALERS" />
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        className="input-group-prepend"
                                        data-toggle="modal"
                                        data-target="#participantsModal">
                                        <span
                                            className="input-group-text bg-primary text-light"
                                            id="inputGroup-sizing-default"
                                        >
                                            <i
                                                className={
                                                    "mdi mdi-plus"
                                                }
                                            ></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {favouriteHaulers.length > 0 && <div className="stretch-card grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-center page-head">
                                <span className="card-title">FAVOURITE HAULERS</span>
                            </div>
                            <div className='table-responsive mt-5'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                S/N
                                            </th>
                                            <th>
                                                IS DEFAULT
                                            </th>
                                            <th>
                                                HAULER CODE
                                            </th>
                                            <th>
                                                HAULER NAME
                                            </th>
                                            <th>
                                                HAULER ADDRESS
                                            </th>
                                            <th>
                                                HAULER EMAIL
                                            </th>
                                            <th>
                                                HAULER PHONE
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {favouriteHaulers &&
                                            favouriteHaulers?.map((hauler, index) =>
                                                <tr className="py-0">
                                                    <td className="text-uppercase text-wrap">
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {index === 0 &&
                                                            <span className="btn btn-primary btn-sm" >
                                                                Is Default
                                                            </span>}
                                                        {index !== 0 &&
                                                            <span className="btn btn-secondary btn-sm text-white" >
                                                                Not Default
                                                            </span>}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {hauler.businessCode}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {hauler.businessName}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {hauler.businessAddress}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {hauler.businessEmail}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {hauler.businessPhone}
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            {
                                haulers.length === 0 &&
                                <div className='row'>
                                    <strong className='text-muted mx-auto mt-5 h5'>No Hauler Record</strong>
                                </div>
                            }
                        </div>
                    </div>
                </div>}
            </div>
        </div>

        {/* Hauler Modal */}
        <div className="modal fade" id="participantsModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h3 className="font-weight-bold text-center">SELECT HAULERS</h3>
                        <button type="button" className="close" id="closeModal" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Limit setLimit={setLimit} />
                        {/* <div className='row justify-content-end'> */}
                        <button onClick={closeMod} type="button" className="btn btn-primary btn-sm btn-icon-text text-white d-flex float-right mr-2">
                            <i className="ti-save mr-1" title="Submit"></i>
                            <span className="d-none d-md-block">SAVE</span>
                        </button>
                        {/* </div> */}
                        <div className="form-group col-md-6 mx-auto">
                            <input type="text" className="form-control"
                                placeholder="Search Hauler" onChange={e => searchHauler(e.target.value)} />
                        </div>
                        <div className="row">
                            <div className="col-md-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="">
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            ACTION
                                                        </th>
                                                        <th>
                                                            IS DEFAULT
                                                        </th>
                                                        <th>
                                                            S/N
                                                        </th>
                                                        <th>
                                                            HAULER CODE
                                                        </th>
                                                        <th>
                                                            HAULER NAME
                                                        </th>
                                                        <th>
                                                            HAULER LOCATION
                                                        </th>
                                                        <th>
                                                            AREA OF COVERAGE
                                                        </th>
                                                        <th>
                                                            HAULER EMAIL
                                                        </th>
                                                        <th>
                                                            HAULER PHONE
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {haulers &&
                                                        haulers?.map((hauler, index) =>
                                                            <tr className="py-0">
                                                                <td>
                                                                    {!selectedHaulersIds.find(x => x.haulerId === hauler.id) && <span className="btn" onClick={() => addHauler(hauler.id, hauler.businessName)}>
                                                                        <i className="mdi mdi-star-outline"></i>
                                                                    </span>}
                                                                    {selectedHaulersIds.find(x => x.haulerId === hauler.id) && <span className="btn" onClick={() => removeHauler(hauler.businessId, hauler.businessName)}>
                                                                        <i className="mdi mdi-star" style={{ color: "orange" }}></i>
                                                                    </span>}
                                                                </td>
                                                                <td>
                                                                    {!(defaultHaulerId === hauler.id) && <span className="btn" onClick={() => addDefaultSupplier(hauler.id)}>
                                                                        <i className="mdi mdi-plus"></i>
                                                                    </span>}
                                                                    {(defaultHaulerId === hauler.id) && <span className="btn">
                                                                        <i className="mdi mdi-check"></i>
                                                                    </span>}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {index + 1}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {hauler.businessCode}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {hauler.businessName}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {hauler.businessAddress}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {hauler.areaOfCoverage}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {hauler.businessEmail}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {hauler.businessPhone}
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            haulers.length === 0 &&
                                            <div className='row'>
                                                <strong className='text-muted mx-auto mt-5 h5'>No Hauler Record</strong>
                                            </div>
                                        }
                                        {
                                            haulers.length !== 0 &&
                                            <Paginate
                                                limit={limit}
                                                setData={setHaulers}
                                                apiToCall={api.Business.getAllHaulers}
                                                pageCount={pageCount}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
