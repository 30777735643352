import { NotificationType } from '../utils/enum';

export const ViewModuleFromNotification = (notification) => {
    switch (notification?.notificationType?.name) {
        case NotificationType.QUOTE: {
            window.location.href = `${window.location.origin}/quoterequestdetail/${notification.entityId}`;
            return;
        }
        case NotificationType.ORDER: {
            window.location.href = `${window.location.origin}/orderdetail/${notification.entityId}`;
            return;
        }
        case NotificationType.INVOICE: {
            window.location.href = `${window.location.origin}/invoicelist`;
            return;
        }
        case NotificationType.ISSUE: {
            window.location.href = `${window.location.origin}/viewstockissue/${notification.entityId}`;
            return;
        }
        case NotificationType.RECIEPT: {
            window.location.href = `${window.location.origin}/viewreceiptnote/${notification.entityId}`;
            return;
        }
        case NotificationType.QUOTERESPONSE: {
            window.location.href = `${window.location.origin}/quoteresponsedetail/${notification.entityId}`;
            return;
        }
        case NotificationType.PAYMENT: {
            window.location.href = `${window.location.origin}/viewpayment/${notification.entityId}`;
            return;
        }
        case NotificationType.WALLETREFUND: {
            window.location.href = `${window.location.origin}/mywallet`;
            return;
        }
        case NotificationType.SETPRICE: {
            window.location.href = `${window.location.origin}/productprice`;
            return;
        }
        case NotificationType.REORDERLEVEL: {
            window.location.href = `${window.location.origin}/newquoterequest`;
            return;
        }
        case NotificationType.SETBILLRATE: {
            window.location.href = `${window.location.origin}/admin/createbillrate`;
            return;
        }
        case NotificationType.HAULAGEREQUEST: {
            window.location.href = `${window.location.origin}/viewhaulagerequest/${notification.entityId}`;
            return;
        }
        case NotificationType.HAULERRESPONSE: {
            window.location.href = `${window.location.origin}/viewhaulagebill/${notification.entityId}`;
            return;
        }
        case NotificationType.DELIVERYNOTE: {
            window.location.href = `${window.location.origin}/viewdeliverynote/${notification.entityId}`;
            return;
        }
        case NotificationType.WITHDRAWALREQUEST: {
            window.location.href = `${window.location.origin}/viewwithdrawaldetail/${notification.entityId}`;
            return;
        }
        default: {
            window.location.href = `${window.location.origin}/notifications/${notification.id}`;
            return;
        }
    }
}