import React, { useEffect, useState, useRef } from "react";
import api from '../../utils/api';
import { Link } from 'react-router-dom';
import Paginate from '../../components/paginate';
import { useForm } from 'react-hook-form';
import Limit from '../../components/limit';
import moment from 'moment';
import { formatter, formatter1, formatter2, fourDecimal, toDate } from "../../utils/formatter";
import PrintButton from "../../components/printbutton";



function WalletTransactions() {
    const [walletTransactions, setWalletTransactions] = useState([]);
    const [limit, setLimit] = useState(15);
    const [search, setSearch] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const page = 1;
    const componentRef = useRef();
    let today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    today = yyyy + "/" + mm + "/" + dd;

    useForm({
        mode: "onChange",
        reValidateMode: 'onChange'
    });

    const [todate, settodate] = useState("");
    const To = (todate) => {
        settodate(todate);
    }

    const [fromdate, setfromdate] = useState("");
    const From = (fromdate) => {
        setfromdate(fromdate);
    }
    const auth = JSON.parse(window.localStorage.getItem('auth'));

    useEffect(() => {
        try {
            const UserAsync = async () => {
                const loadWalletTransactions = await api.Wallet.getWalletTransactions(page, limit, search);
                const wt = loadWalletTransactions.data.data;
                const count = loadWalletTransactions.data.totalCount;
                setPageCount(Math.ceil(count / limit))
                setWalletTransactions(wt)
            };
            UserAsync();
        }
        catch (error) {
            console.log(error)
        }
    }, [limit]);

    const searchWalletTransaction = async (e) => {
        const search = e.target.value;
        const response = await api.Wallet.getWalletTransactions(page, limit, search);
        setWalletTransactions(response.data.data)
    }

    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="card mb-1">
                        <div className="card-body">
                            <div className="text-center page-head">
                                <span className="card-title">WALLET TRANSACTIONS</span>
                            </div>
                            {<div className="mt-1 d-flex float-right align-item-center">
                                <PrintButton componentRef={componentRef} />
                            </div>}
                            <form className="mt-3">
                                <Limit setLimit={setLimit} />

                                <div className="form-group col-md-6 mx-auto mt-3">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search Transaction" aria-label="Recipient's username" onChange={e => searchWalletTransaction(e)} />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-md-3">
                                        <label>From:</label>
                                        <input
                                            name="From"
                                            onChange={e => From(e.target.value)}
                                            type="date" className="form-control" placeholder="From" />
                                    </div>

                                    <div className="form-group col-md-3">
                                        <label>To:</label>
                                        <input
                                            name="To"
                                            onChange={e => To(e.target.value)}
                                            type="date" className="form-control" />
                                    </div>
                                </div>
                            </form>

                            <div className='table-responsive'>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                S/N
                                            </th>
                                            <th>
                                                TRANSACTION DATE
                                            </th>
                                            <th>
                                                REFERENCE NUMBER
                                            </th>
                                            <th>
                                                SENDER
                                            </th>
                                            <th>
                                                RECIPIENT
                                            </th>
                                            <th className="text-right">
                                                AMOUNT
                                            </th>
                                            <th>
                                                TRANSACTION TYPE
                                            </th>
                                            <th>
                                                NARRATION
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {walletTransactions && walletTransactions?.length > 0 &&
                                            walletTransactions?.map((wallTrans, index) =>
                                                <tr className="py-0">

                                                    <td className="text-uppercase text-wrap">
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-uppercase">
                                                        {moment(wallTrans?.transactionDate).format('MMM D, YYYY')}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {wallTrans?.referenceNo}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {wallTrans?.sender}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {wallTrans?.recipient}
                                                    </td>
                                                    <td className="text-uppercase text-right">
                                                        {formatter.format(wallTrans?.amount)}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {wallTrans?.transactionType}
                                                    </td>

                                                    <td>
                                                        <div className="text-uppercase text-wrap">
                                                            {wallTrans?.narration}
                                                        </div>
                                                    </td>

                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            {
                                walletTransactions.length === 0 &&
                                <div className='row'>
                                    <strong className='text-muted mx-auto mt-5 h5'>No Wallet Transaction Record</strong>
                                </div>
                            }
                            {
                                walletTransactions.length !== 0 &&
                                <Paginate
                                    limit={limit}
                                    setData={setWalletTransactions}
                                    apiToCall={api.Wallet.getWalletTransactions}
                                    pageCount={pageCount}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>

            {/* Print Modal */}
            <div class="modal fade" id="paymentModal" tabindex="-1" aria-labelledby="paymentModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog modal-lg">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="paymentModalLabel">Wallet Transaction</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="mx-5" ref={componentRef}>
                            <style>{`@page {margin: 0mm 0mm 17mm;}`}
                            </style>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex align-items-center mt-5" style={{ display: "inline-block" }}>
                                    <div className="d-flex align-items-center flex-column">
                                        <img style={{ width: "150px" }} src={auth?.businessLogo} />
                                        <span className="h6 m-3 d-md-block">{auth?.businessName}</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mt-5 flex-column">
                                    <p>Printed By: {auth?.email} </p>
                                    <p>Date Printed: {today}</p>
                                </div>
                                <div className="d-flex align-items-center mt-5" style={{ display: "inline-block" }}>
                                    <div className="d-flex align-items-center flex-column">
                                        <img style={{ width: "150px" }} src="assets/images/logo.png" />
                                        <span className="h6 m-3 d-md-block"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5">
                                <h4 className="card-title text-center">WALLET TRANSACTION</h4>
                                <div className="table-responsive">
                                    <table class="table table-striped m-0 p-0" style={{ width: "100%" }}>
                                        <thead>
                                            <style>{`@media print {position: fixed;bottom: 0;}`}
                                            </style>
                                            <tr>
                                                <th>SN</th>
                                                <th>TRANSACTION DATE</th>
                                                <th>TRANSACTION TYPE</th>
                                                <th>REFERENCE NUMBER</th>
                                                <th>SENDER</th>
                                                <th>RECIPIENT</th>
                                                <th className="text-right">AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {walletTransactions &&
                                                walletTransactions.length > 0 &&
                                                walletTransactions?.map(
                                                    (trans, index) => (
                                                        <tr key={trans.id}>
                                                            <td className="text-uppercase text-wrap">{index + 1}</td>
                                                            <td className="text-uppercase">
                                                                {moment(trans?.createdAt).format('MMM D, YYYY')}
                                                            </td>
                                                            <td className="text-uppercase text-wrap">
                                                                {trans?.transactionType}
                                                            </td>
                                                            <td className="text-uppercase text-wrap">
                                                                {trans?.referenceNo}
                                                            </td>
                                                            <td className="text-uppercase text-wrap">
                                                                {trans?.sender}
                                                            </td>
                                                            <td className="text-uppercase text-wrap">
                                                                {trans?.recipient}
                                                            </td>
                                                            <td className="text-uppercase text-right">
                                                                &#8358;{formatter1.format(trans?.amount ? trans?.amount : 0)}
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WalletTransactions