import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Limit from "../../../components/limit";
import Paginate from "../../../components/paginate"
import api from "../../../utils/api";

export default function ListAdvert() {

    const [advert, setAdvert] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const page = 1;
    const [limit, setLimit] = useState(15);
    const [search, setSearch] = useState("");


    const nul = search || limit;
    const nu = (nul ? null : advert);
    useEffect(() => {
        const load = async () => {
            const response = await api.Advert.load(page, limit, "");
            const count = response.data.totalCount;
            setPageCount(Math.ceil(count / limit));
            setAdvert(response.data.data);
        };
        load();
    }, [nu, limit]);

    const searchProduct = (value) => {
        if (!value) {
            const load = async () => {
                const response = await api.Advert.load(page, limit, "");
                setAdvert(response.data.data);
            };
            load();
        }
        else if (value) {
            const load = async () => {
                setSearch(value)
                const response = await api.Advert.load(page, limit, search);
                setAdvert(response.data.data);
            };
            load();
        }
    }


    return <>
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card mb-5">
                            <div className="card-body">
                                <div className="text-center page-head">
                                    <span className="card-title">ADVERT</span>
                                </div>
                                <div className="mt-1 d-flex float-right align-item-center">
                                    <Link to={'/admin/createadvert'}>
                                        <button class="btn btn-primary btn-sm btn-icon-text text-white d-flex mx-1">
                                            <i className="ti-plus mr-1" title="Back"></i>
                                            <span className="d-none d-md-block">CREATE ADVERT</span>
                                        </button>
                                    </Link>

                                </div>
                                <form className="mt-3">
                                    <Limit setLimit={setLimit} />
                                    <div className="form-group col-md-6 mx-auto mt-3">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search Product" aria-label="Recipient's username" onChange={e => searchProduct(e.target.value)} />
                                        </div>
                                    </div>
                                </form>

                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>
                                                    ACTIONS
                                                </th>
                                                <th>
                                                    S/N
                                                </th>
                                                <th>
                                                    IMAGE
                                                </th>
                                                <th>
                                                    CONTACT PERSON
                                                </th>
                                                <th>
                                                    CONTACT PHONE
                                                </th>

                                                <th>
                                                    EMAIL
                                                </th>

                                                <th>
                                                    URL
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {advert && advert.length > 0 &&
                                                advert?.map((pro, index) =>
                                                    <tr>
                                                        <td>
                                                            <div className="">
                                                                <Link to={`/admin/viewadvert/${pro.id}`}><i className="ti-eye btn-icon-append text-primary mr-3" title="View" /></Link>
                                                            </div>
                                                        </td>
                                                        <td className="text-uppercase text-wrap">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-uppercase text-wrap">
                                                            <a download href={pro?.image} target="_blank">View</a>

                                                        </td>
                                                        <td className="text-uppercase text-wrap">
                                                            {pro.contactPerson}
                                                        </td>
                                                        <td className="text-uppercase text-wrap">
                                                            {pro.contactPhone}
                                                        </td>

                                                        <td className="text-uppercase text-wrap">
                                                            {pro.email}
                                                        </td>
                                                        <td className="text-uppercase text-wrap">
                                                            {pro.url}
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    advert.length === 0 &&
                                    <div className='row'>
                                        <strong className='text-muted mx-auto mt-5 h5'>No Advert Record</strong>
                                    </div>
                                }

                                {
                                    advert.length !== 0 &&
                                    <Paginate
                                        limit={limit}
                                        setData={setAdvert}
                                        apiToCall={api.Advert.load}
                                        pageCount={pageCount}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>
}