import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Limit from '../../components/limit';
import Paginate from '../../components/paginate';
import api from '../../utils/api';
import moment from 'moment';
import Swal from 'sweetalert2';
import { notifySuccess } from '../../utils/toast';
import { formatter, formatter1, formatter2, fourDecimal, toDate } from "../../utils/formatter";

export default function Pendingpayment() {
    const [PendingPayment, setPendingPayment] = useState([]);
    const [limit, setLimit] = useState(15);
    const [pageCount, setPageCount] = useState(0);
    const [search, setSearch] = useState("");
    const page = 1;
    const [loading, setLoading] = useState(false);
    // const [process, setProcess] = useState({});



    // const nul = search || limit;
    // const nu = (nul ? null : locations);
    useEffect(() => {
        try {
            window.scrollTo(0, 0)

            const getPendingPayment = async () => {
                const response = await api.Wallet.getPendingPayments(page, limit, "");
                const count = response.data.totalCount;
                setPageCount(Math.ceil(count / limit))
                setPendingPayment(response.data.data)
            };
            getPendingPayment();

        }
        catch (error) {
            console.log(error)
        }
    }, [limit]);

    const searchPendingPayment = async (value) => {
        if (!value) {
            const getPendingPayment = async () => {
                const response = await api.Wallet.getPendingPayments(page, limit, "");
                setPendingPayment(response.data.data)
            };
            getPendingPayment();
        }
        else if (value) {
            setSearch(value)
            const getPendingPayment = async () => {
                const response = await api.Wallet.getPendingPayments(page, limit, search);
                setPendingPayment(response.data.data)
            };
            getPendingPayment();
        }
    }

    const proccessPayment = async (reference_code) => {
        try {
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4B49AC',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const response = await api.Wallet.proccessPayment(reference_code)
                    if (response?.sucessMessage) {
                        notifySuccess(response?.sucessMessage)
                        setLoading(false)
                        const getPaymentRecord = await api.Wallet.getPendingPayments(page, limit, "");
                        setPendingPayment(getPaymentRecord.data.data)
                    }
                }
            })
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="row">
                    <div className="col-md-12 grid-margin stretch-card">
                        <div className="card">
                            <div className="card-body">
                                <div className="text-center page-head">
                                    <span className="card-title">PENDING PAYMENT</span>
                                </div>
                                {/* {!auth.isBusinessUser && <div className="mt-1 d-flex float-right align-item-center">
                                  <Link to={'/createlocation'}>
                                      <button class="btn btn-primary btn-sm btn-icon-text text-white d-flex mx-1">
                                          <i className="ti-plus mr-1" title="Back"></i>
                                          <span className="d-none d-md-block">CREATE</span>
                                      </button>
                                  </Link>
                              </div>} */}
                                <form className="mt-3">
                                    <Limit setLimit={setLimit} />

                                    <div className="form-group col-md-6 mx-auto mt-3">
                                        <div className="input-group">
                                            <input type="text" className="form-control" placeholder="Search Pending Payment" aria-label="Recipient's username" onKeyUp={e => searchPendingPayment(e.target.value)} />
                                        </div>
                                    </div>
                                </form>

                                <div className="table-responsive">
                                    <table className="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>
                                                    REFERENCE
                                                </th>
                                                <th>
                                                    STATUS
                                                </th>
                                                <th>
                                                    ACTIONS
                                                </th>
                                                <th>
                                                    S/N
                                                </th>
                                                <th>
                                                    AMOUNT
                                                </th>
                                                <th>
                                                    FEES
                                                </th>
                                                <th>
                                                    PAYMENT DATE
                                                </th>
                                                <th>
                                                    TRANSACTION DATE
                                                </th>
                                                <th>
                                                    TRANSACTION TYPE
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {PendingPayment && PendingPayment.length > 0 &&
                                                PendingPayment?.map((pending, index) =>
                                                    <tr className="text-capitalize">
                                                        <td className="text-uppercase text-wrap">
                                                            {pending.reference}
                                                        </td>
                                                        <td >
                                                            {pending?.status !== "success" ? <span className="badge bg-warning p-2">PENDING</span>
                                                                : <span className="badge bg-success p-2">SUCCESS</span>}
                                                        </td>
                                                        <td>
                                                            <button type="submit" onClick={() => proccessPayment(pending.reference)} class="btn btn-primary btn-sm btn-icon-text text-white d-flex">
                                                                {/* <i className="ti-save mr-1" title="Submit"></i> */}
                                                                PROCESS
                                                            </button>
                                                        </td>
                                                        <td className="text-uppercase text-wrap">
                                                            {index + 1}
                                                        </td>
                                                        <td className="text-uppercase text-wrap">
                                                            &#8358;{formatter1.format(pending.amount ? pending.amount : 0)}
                                                        </td>
                                                        <td className="text-uppercase text-wrap">
                                                            &#8358;{formatter1.format(pending.fees ? pending.fees : 0)}
                                                        </td>
                                                        <td className="text-uppercase text-wrap">
                                                            {pending.paymentDate ? moment(pending.paymentDate).format("DD-MMM-YYYY") : "N/A"}
                                                        </td>
                                                        {/* <td style={pending.premiseLicense ? { cursor: 'pointer' } : {}}>
                                                            <a download href={pending.premiseLicense} target="_blank">{pending.premiseLicense ? 'View' : 'None'}</a>
                                                        </td> */}
                                                        < td className="text-uppercase text-wrap" >
                                                            {pending.transactionDate ? moment(pending.transactionDate).format("DD-MMM-YYYY") : "N/A"}
                                                        </td>
                                                        <td>
                                                            {pending.transactionType}
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                                {
                                    PendingPayment.length === 0 &&
                                    <div className='row'>
                                        <strong className='text-muted mx-auto mt-5 h5'>No Pending Payment Record</strong>
                                    </div>
                                }
                                {
                                    PendingPayment.length !== 0 &&
                                    <div className="mx-auto mt-4 col-12">
                                        <Paginate
                                            limit={limit}
                                            setData={setPendingPayment}
                                            apiToCall={api.Wallet.getPendingPayments}
                                            pageCount={pageCount}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}
