import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from "react-router-dom";
import { notifySuccess } from '../../utils/toast';
import api from '../../utils/api';
import Swal from 'sweetalert2';
import Backbutton from '../../components/backbutton';
import { formatter, formatter1, fourDecimal, toDate } from "../../utils/formatter";

export default function Viewwithdrawaldetail() {
    const { id } = useParams();
    const [withdrawal, setWithdrawal] = useState({});
    const [loading, setloading] = useState(false);
    const auth = JSON.parse(window.localStorage.getItem('auth'));
    const history = useHistory();

    useEffect(() => {
        try {
            const getWithdrawal = async () => {
                const loadWithdrawal = await api.Withdrawal.view(id);
                setWithdrawal(loadWithdrawal.data)
            };
            getWithdrawal();
        }
        catch (error) {
            console.log(error)
        }
    }, []);

    const approveWithdrawal = async () => {
        try {
            setloading(true);
            Swal.fire({
                title: "Are you sure?",
                text: "You won't be able to revert this!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#4B49AC",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yes, Approve!",
            }).then(async (result) => {
                if (result.isConfirmed) {
                    const approved = await api.Withdrawal.approve(id);
                    notifySuccess(approved.sucessMessage);
                    setloading(false);
                    history.push("/mywallet");
                } else {
                    setloading(false);
                }
            });
        } catch (error) {
            console.log(error);
            setloading(false);
        }
    };

    return <>
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="card mb-5">
                    <div className="card-body">
                        <div className="text-center">
                            <span className="card-title">VIEW WITHDRAWAL DETAILS</span>
                        </div>
                        <div className="row d-flex float-right mt-2">
                            <Backbutton></Backbutton>
                            {/* {(!withdrawal.approved &&
                                (auth.isAdmin)) && (
                                    <button
                                        type="submit"
                                        onClick={approveWithdrawal}
                                        class="btn btn-success btn-sm btn-icon-text text-white d-flex"
                                        disabled={loading ? true : false}
                                    >
                                        <i className="ti-check mr-1" title="Delete"></i>
                                        <span className="d-none d-md-block">
                                            {loading ? "APPROVING" : "APPROVE"}
                                        </span>
                                    </button>
                                )} */}
                            {/* <button type="submit" onClick={deleteLocation} class="btn btn-danger btn-sm btn-icon-text text-white d-flex" disabled={loading ? true : false}>
                                <i className="ti-close mr-1" title="Delete"></i>
                                <span className="d-none d-md-block">{loading ? "DELETING" : "DELETE"}</span>
                            </button> */}
                        </div>

                        <div className="row mt-5">
                            <div className="col-md-4 mb-3">
                                <label className="font-weight-bold">Amount to Withdraw:</label>&nbsp;
                                <span className="text-capitalize">
                                    &#8358;{formatter1.format(withdrawal?.amountToWithdraw ? withdrawal?.amountToWithdraw : 0)}
                                </span>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label className="font-weight-bold">Charges:</label>&nbsp;
                                <span className="text-capitalize">
                                    &#8358;{formatter1.format(withdrawal?.amountCharged ? withdrawal?.amountCharged : 0)}
                                </span>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label className="font-weight-bold">Amount to Disburse:</label>&nbsp;
                                <span className="text-capitalize">
                                    &#8358;{formatter1.format(withdrawal?.amountToDisburse ? withdrawal?.amountToDisburse : 0)}
                                </span>
                            </div>
                            <div className="col-md-4 mb-3">
                                <label className="font-weight-bold">Status:</label>&nbsp;
                                {withdrawal?.status === "reversed" && <span className="badge bg-info p-2 text-uppercase">{withdrawal?.status}</span>}
                                {withdrawal?.status === "failed" && <span className="badge bg-danger p-2 text-uppercase">{withdrawal?.status}</span>}
                                {withdrawal?.status === "paid" && <span className="badge bg-success p-2 text-uppercase">{withdrawal?.status}</span>}
                                {withdrawal?.status === "pending" && <span className="badge bg-warning p-2 text-uppercase">{withdrawal?.status}</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
