import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import api from "../utils/api";
import Footer from './footer';
import Sidebar from './sidebar';
import ThemeSetting from './themeSetting';
import Header from './header';

const PermissionProtectedRoute = ({ path, itemName, action, component: Component, ...rest }) => {
  const auth = JSON.parse(localStorage.getItem("auth"));
  const hasPermission = (action) => {
    return auth?.role.rolePermission.find((e) => e.module.moduleName === `${itemName}`)?.[action];
  };
  const [wActivated, setWalletActivated] = useState(null);
  const [licenseExpiration, setLicenseExpiration] = useState(null);

  useEffect(() => {

    const checkActivationStatus = async () => {
      const { data: { walletActivated, licenseExpiryDate } } = await api.Business.getByID(auth.businessId);
      setWalletActivated(walletActivated);
      if (new Date(licenseExpiryDate) >= new Date()) {
        setLicenseExpiration(true);
      } else {
        setLicenseExpiration(false);
      }
    }

    checkActivationStatus();

  }, [])

  if (api.Auth.isAuth()) {
    const route = window.location.pathname;
    if ((route !== '/dashboard'
      && route !== '/mywallet' &&
      route !== '/mywallet?menuFrom=activate'
      && route !== '/documentation'
      && route !== '/editprofile'
      && route !== '/edituserprofile'
      && route !== '/changepassword'
      && route !== '/notifications'
      && wActivated !== null
      && !auth.isAdmin)
      && !wActivated) {

      return <Redirect to={{ pathname: "/305" }} />;

    } 
    else if (
      (route !== '/dashboard' && route !== '/mywallet' &&
        route !== '/mywallet?menuFrom=activate' &&
        route !== '/mywallet?menuFrom=license' &&
        route !== '/documentation'
        && route !== '/editprofile'
        && route !== '/edituserprofile'
        && route !== '/changepassword'
        && route !== '/notifications'
        && licenseExpiration !== null
        && !auth.isAdmin)
      && licenseExpiration === false
    ) {
      return <Redirect to={{ pathname: "/licenseexpiration" }} />;
    } 
  else if (!hasPermission(action)) {
    return <Redirect to={{ pathname: "/403" }} />;
  }
else{
  return(
    <div className="container-scroller">
        <Header />

        <div className="container-fluid page-body-wrapper ">
            <ThemeSetting />
            <Sidebar />
            <Route path={path} component={Component} {...rest} />;
        </div>
        <Footer />
    </div>
  )
  }
} else {
  localStorage.setItem("lastAccessedUrl", window.location.pathname);
  return <Redirect to={{ pathname: "/signin" }} />;
}
};

export default PermissionProtectedRoute;
