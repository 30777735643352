import React from 'react';
import { Link, useHistory } from 'react-router-dom';


export default function documentation() {
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="card mb-1">
                    <div className="card-body">
                        <div className="text-center page-head">
                            <span className="card-title">P2P DOCUMENTATION</span>
                        </div>
                        {<div className="mt-1 d-flex float-right align-item-center">
                            {/* <Link to={"/"}>
                                <button class="btn btn-dark btn-sm btn-icon-text text-white d-flex mx-1">
                                    <i className="ti-arrow-left mr-1" title="Back"></i>
                                    <span className="d-none d-md-block">BACK TO DASHBOARD</span>
                                </button>
                            </Link> */}
                        </div>}
                        <div className="my-5">
                            <h1 className="text-muted text-center" style={{ margin: "200px 0" }}>Coming Soon...</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
