import React, { useState, useEffect } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import AdminSidebar from '../pages/admin/sidebar';
import api from '../utils/api';
import PubSub from 'pubsub-js';
import { notifyError } from '../utils/toast';

export default function Sidebar() {
  const history = useHistory();
  const [retail, setRetail] = useState(false);
  const [ma, setMa] = useState(false);
  const [ma2, setMa2] = useState(false);
  const [ma3, setMa3] = useState(false);
  const [hauCo, setHauCo] = useState(false);
  const [retailApp, setRetailApp] = useState(false);
  const [role, setRole] = useState({});
  const [isExhibitor, setIsExhibitor] = useState(false);
  const auth = JSON.parse(window.localStorage.getItem('auth'));
  let final = [];
  const [hasRetailApp, setHasRetailApp] = useState(null);

  useEffect(() => {
    const auth = JSON.parse(window.localStorage.getItem('auth'));
    const part = auth.participantTypes[0]?.participantName;
    const getRetail = part === "Retailer (Community Pharmacist)" || part === 'Distributor' || part === 'Wholesaler'
    if (getRetail) {
      setRetail(true)
    }
    const getMa = part === ("Retailer (Community Pharmacist)") || part === ("Wholesaler") || part === ("Distributor")
    if (getMa) {
      setMa(true)
    }
    const getMa2 = part === ("Manufacturer") || part === ("Importer") || part === ("Wholesaler") || part === ("Distributor")
    if (getMa2) {
      setMa2(true)
    }
    const getMa3 = part === ("Manufacturer") || part === ("Importer") || part === ("Retailer (Community Pharmacist)") || part === ("Wholesaler") || part === ("Distributor")
    if (getMa3) {
      setMa3(true)
    }
    const getHauCo = part === ("Hauler") || part === ("Courier")
    if (getHauCo) {
      setHauCo(true)
    }
    const getRetailApp = part === ("Retailer (Community Pharmacist)")
    if (getRetailApp) {
      setRetailApp(true)
    }
  }, []);


  const logout = () => {
    api.Auth.logout();
    localStorage.removeItem("lastAccessedUrl");
    history.push("/signin");

  }

  useEffect(() => {
    const roleaccess = () => {
      const authString = localStorage.getItem("auth");
      if (!authString) {
        console.error("Authentication data not found in sessionStorage");
        return;
      }
      const auth = JSON.parse(authString);
      const roles = auth?.role.rolePermission
      const obj = {
        Location: (roles.find(e => e.module.moduleName === "Location"))?.isVisible,
        User: (roles.find(e => e.module.moduleName === "User"))?.isVisible,
        DocumentSetting: (roles.find(e => e.module.moduleName === "Document Setting"))?.isVisible,
        BusinessSetting: (roles.find(e => e.module.moduleName === "Business Setting"))?.isVisible,
        AddProduct: (roles.find(e => e.module.moduleName === "Add Product"))?.isVisible,
        Partnership: (roles.find(e => e.module.moduleName === "Partnership"))?.isVisible,
        Charge: (roles.find(e => e.module.moduleName === "Charge"))?.isVisible,
        Discount: (roles.find(e => e.module.moduleName === "Discount"))?.isVisible,
        PackUnitConversion: (roles.find(e => e.module.moduleName === "Pack Unit Conversion"))?.isVisible,
        ReorderLevel: (roles.find(e => e.module.moduleName === "Reorder Level"))?.isVisible,
        FavouriteSupplier: (roles.find(e => e.module.moduleName === "Favourite Supplier"))?.isVisible,
        FavoriteHauler: (roles.find(e => e.module.moduleName === "Favourite Hauler"))?.isVisible,
        QuoteRequest: (roles.find(e => e.module.moduleName === "Quote Request"))?.isVisible,
        QuoteResponse: (roles.find(e => e.module.moduleName === "Quote Response"))?.isVisible,
        Order: (roles.find(e => e.module.moduleName === "Order"))?.isVisible,
        InvoiceNote: (roles.find(e => e.module.moduleName === "Invoice Note"))?.isVisible,
        PaymentNote: (roles.find(e => e.module.moduleName === "Payment Note"))?.isVisible,
        StockIssueNote: (roles.find(e => e.module.moduleName === "Stock Issue Note"))?.isVisible,
        BalanceAdjustment: (roles.find(e => e.module.moduleName === "Balance Adjustment"))?.isVisible,
        DeliveryNote: (roles.find(e => e.module.moduleName === "Delivery Note"))?.isVisible,
        RecieptNote: (roles.find(e => e.module.moduleName === "Receipt Note"))?.isVisible,
        CreditNote: (roles.find(e => e.module.moduleName === "Credit Note"))?.isVisible,
        CreditFacility: (roles.find(e => e.module.moduleName === "Credit Facility"))?.isVisible,
        HaulerRequest: (roles.find(e => e.module.moduleName === "Hauler Request"))?.isVisible,
        PriceAdjustment: (roles.find(e => e.module.moduleName === "Price Adjustment"))?.isVisible,
        HaulerBill: (roles.find(e => e.module.moduleName === "Hauler Bill"))?.isVisible,
        PickUp: (roles.find(e => e.module.moduleName === "Pick up"))?.isVisible,
        Retail: (roles.find(e => e.module.moduleName === "Retail"))?.isVisible,
        StockBalance: (roles.find(e => e.module.moduleName === "Stock Balance"))?.isVisible,
        ProductSearch: (roles.find(e => e.module.moduleName === "Product Search"))?.isVisible,
        ProductOffload: (roles.find(e => e.module.moduleName === "Product Offload"))?.isVisible,
        ProductBroadcast: (roles.find(e => e.module.moduleName === "Product Broadcast"))?.isVisible,
        AccountBalance: (roles.find(e => e.module.moduleName === "Account Balance"))?.isVisible,
        FinanicialLedger: (roles.find(e => e.module.moduleName === "Finanicial Ledger"))?.isVisible,
        AggregatedDocument: (roles.find(e => e.module.moduleName === "Aggregated Document"))?.isVisible,
        ConferenceExhibitors: (roles.find(e => e.module.moduleName === "Conference Exhibitors"))?.isVisible,
        ProductReOrder: (roles.find(e => e.module.moduleName === "Product Re-order"))?.isVisible,
        Wallet: (roles.find(e => e.module.moduleName === "Wallet"))?.isVisible,
        RoleSetting: (roles.find(e => e.module.moduleName === "Role Setting"))?.isVisible,
        ExhibitionPayment: (roles.find(e => e.module.moduleName === "Exhibition Payment"))?.isVisible,
        BankAccount: (roles.find(e => e.module.moduleName === "Bank Account"))?.isVisible,
        Sales: (roles.find(e => e.module.moduleName === "Sales"))?.isVisible,
        Shop: (roles.find(e => e.module.moduleName === "Shop"))?.isVisible,
      }
      setRole(obj)
    }

    roleaccess()
  }, [])


  const activateWallet = async () => {
    const r = await api.BusinessLocation.getHQVerificationStatus();
    if (r.sucessMessage) {
      if (window.location.pathname === '/mywallet') {
        history.push(`/mywallet?menuFrom=activate`);
        return document.getElementById('act-btn').click();
      } else {
        return history.push(`/mywallet?menuFrom=activate`);
      }
    } else {
      return notifyError('You cannot activate account that has not been verified');
    }
  }

  const reOrder = async () => {
    const response = await api.Utility.getReorderProducts();
    const reOrderProducts = response.data
    if (response?.data.length > 0) {
      for (const pro of reOrderProducts) {
        let obj = {
          minOrderQuantity: pro.reOrderLevel,
          orderQuantity: pro.reOrderQuantity,
          packId: pro.productPack.id,
          packName: pro.productPack.packName,
          productId: pro.product.id,
          productImage: pro.product.image,
          productName: pro.product.name,
          unitPrice: 0
        }
        final.push(obj);
      }

      const obj = {
        transactionOrigin: "",
        transactionValue: 0,
        itemTotal: 0,
        deliveryLocationId: "",
        shipToAddress: "",
        comment: "Ok",
        orderPickedBy: "",
        customerId: auth.id,
        quotationNoteDetail: final,
        quoteRequestSupplierIds: []
      }

      localStorage.setItem("quotes", JSON.stringify(obj))
      PubSub.publish("quote", obj);
      history.push("/viewquoterequest");
    } else {
      return notifyError("You do not have products due for re-order")
    }
  };

  useEffect(() => {
    const load = async () => {
      const res = await api.ConferenceExhibitor.isExhibitor(auth.businessId);
      if (res.data) {
        setIsExhibitor(true);
      }
    }

    load()
  }, [])

  useEffect(() => {
    const loadBusiness = async () => {
      const bus = await api.Business.getByID(auth.businessId);
      if (bus) {
        const { data } = bus;
        setHasRetailApp(data.hasRetailApp);
      }
    }

    loadBusiness();

  }, [])


  return <>
    {!auth.isAdmin && <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li className="nav-item" type="button" data-toggle="offcanvas">
          <NavLink className="nav-link" to={"/dashboard"}>
            <i className="icon-grid menu-icon" />
            <span className="menu-title">Dashboard</span>
          </NavLink>
        </li>

        {(!auth.isBussinessUser && !auth.walletActivated) && <li style={{ cursor: 'pointer' }} className="nav-item">
          <a onClick={activateWallet} className="nav-link" aria-expanded="false" aria-controls="ui-basic">
            <i className="icon-square-check menu-icon" />
            <span className="menu-title">Activate Account</span>
          </a>
        </li>}

        <li className="nav-item">
          <a className="nav-link" data-toggle="collapse" href="#ui-set-up" aria-expanded="false" aria-controls="ui-basic">
            <i className="icon-cog menu-icon" />
            <span className="menu-title">Business Setup</span>
            <i className="menu-arrow" />
          </a>
          <div className="collapse" id="ui-set-up">
            <ul className="nav flex-column sub-menu">
              {role.Location &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/locationlist"} className="nav-link">Location</NavLink>
                </li>}
              {role.RoleSetting &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/roles"} isVisible={role.RoleSetting} className="nav-link">Role</NavLink>
                </li>
              }
              {role.RoleSetting &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/roleaccess"} className="nav-link">Role Access</NavLink>
                </li>
              }
              {role.User &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/userlist"} isVisible={role.User} className="nav-link">User</NavLink>
                </li>
              }
              {role.DocumentSetting &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/documentsetting"} isVisible={role.DocumentSetting} className="nav-link">Document Setting</NavLink>
                </li>}

              {role.BusinessSetting &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/businesssetting"} isVisible={role.BusinessSetting} className="nav-link">Business Setting</NavLink>
                </li>
              }
              {role.AddProduct &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/myproductlist"} isVisible={role.AddProduct} className="nav-link">Add Product</NavLink>
                </li>
              }
              {/* <li className="nav-item" type="button" data-toggle="offcanvas">
              <NavLink to={"/addProduct"} className="nav-link">Add Product</NavLink>
              </li> */}
              {role.Partnership && (!auth.isBussinessUser) &&

                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/partnership"} isVisible={role.Partnership} className="nav-link">Partnership</NavLink>
                </li>}

              {role.Charge &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/chargelist"} isVisible={role.Charge} className="nav-link"> Charge</NavLink>
                </li>
              }
              {role.Discount &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/discountlist"} isVisible={role.AddProduct} className="nav-link"> Discount</NavLink>
                </li>}
              {role.PackUnitConversion &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/conversion"} isVisible={role.PackUnitConversion} className="nav-link"> Pack Unit Conversion</NavLink>
                </li>
              }
              {role.ReorderLevel &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/reorderlevel"} isVisible={role.ReorderLevel} className="nav-link"> Reorder Level</NavLink>
                </li>}

              {role.FavouriteSupplier && !(auth?.participantTypes[0]?.participantName === "Manufacturer") && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/favouritesuppliers"} isVisible={role.FavouriteSupplier} className="nav-link"> Favourite Suppliers</NavLink>
              </li>}
              {role.FavoriteHauler &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/favouritehaulers"} isVisible={role.FavoriteHauler} className="nav-link"> Favourite Haulers</NavLink>
                </li>
              }

              {role.BankAccount && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/listbankdetails"} className="nav-link">Bank Account</NavLink>
              </li>}
            </ul>
          </div>
        </li>

        <li className="nav-item">
          <a className="nav-link" data-toggle="collapse" href="#ui-transactions" aria-expanded="false" aria-controls="ui-basic">
            <i className="icon-paper-stack menu-icon" />
            <span className="menu-title">Transactions</span>
            <i className="menu-arrow" />
          </a>
          <div className="collapse" id="ui-transactions">
            <ul className="nav flex-column sub-menu">
              {(ma3 && role.QuoteRequest) && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/quoterequestlist"} className="nav-link">Quote Request</NavLink>
              </li>}
              {(ma3 && role.QuoteResponse) && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/quoteresponselist"} className="nav-link">Quote Response</NavLink>
              </li>}
              {(ma3 && role.Order) && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/orderlist"} className="nav-link">Order</NavLink>
              </li>}
              {(ma3 && role.InvoiceNote) && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/invoicelist"} className="nav-link">Invoice Note</NavLink>
              </li>}
              {role.PaymentNote &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/paymentlist"} className="nav-link">Payment Note</NavLink>
                </li>}
              {(ma3 && role.StockIssueNote) && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/issuelist"} className="nav-link">Stock Issue Note</NavLink>
              </li>}
              {role.DeliveryNote && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/deliverynotelist"} className="nav-link">Delivery Note</NavLink>
              </li>}
              {(ma && role.RecieptNote) && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/receiptnotelist"} className="nav-link">Receipt Note</NavLink>
              </li>}
              {(ma3 && role.BalanceAdjustment) && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/balanceadjustmentlist"} className="nav-link">Balance Adjustment</NavLink>
              </li>}

              {(ma3 && role.PriceAdjustment) && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/productpricelist"} className="nav-link">Price Adjustment</NavLink>
              </li>}

              {role.CreditNote &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/listcreditnote"} className="nav-link">Credit Note</NavLink>
                </li>}
              {role.CreditFacility &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/listcreditfacility"} className="nav-link"> Credit Facilty</NavLink>
                </li>
              }

              {role.HaulerRequest &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/haulagelist"} className="nav-link"> Hauler Request</NavLink>
                </li>
              }
              {role.HaulerBill &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/listhaulerbill"} className="nav-link"> Hauler Bill</NavLink>
                </li>}
              {role.PickUp &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/pickup"} className="nav-link"> Pick Up</NavLink>
                </li>}

              {/* {ma3 && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/onlinepayments"} className="nav-link">Online Payments</NavLink>
              </li>} */}
            </ul>
          </div>
        </li>

        {retail && <li className="nav-item">
          <a className="nav-link" data-toggle="collapse" href="#ui-retailshop" aria-expanded="false" aria-controls="ui-basic">
            <i className="icon-marquee menu-icon" />
            <span className="menu-title">My Shop</span>
            <i className="menu-arrow" />
          </a>
          <div className="collapse" id="ui-retailshop">
            <ul className="nav flex-column sub-menu">
              {/* {hasRetailApp  ? <li className="nav-item" type="button" data-toggle="offcanvas"> 
                 Retail
              </li> : <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/retailshop"} className="nav-link">Retail</NavLink>
              </li> } */}

              {role.Shop && <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/retailshop"} className="nav-link">Shop</NavLink>
              </li>}
              {retailApp && <li className="nav-item" type="button" data-toggle="offcanvas">
                <a href="https://retail.phamart.ng/" className='nav-link' target="_blank">Retail App</a>
              </li>}
              {role.Sales && <li className="nav-item" type="button" data-toggle="offcanvas"> <NavLink to={"/saleslist"} className="nav-link">Sales</NavLink></li>}
              {role.StockBalance &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/stockbalance"} className="nav-link"> Stock Balance</NavLink>
                </li>}
            </ul>
          </div>
        </li>}

        {((!auth.isBussinessUser) && !hauCo) && <li className="nav-item">
          <a className="nav-link" data-toggle="collapse" href="#ui-productbroadcast" aria-expanded="false" aria-controls="ui-basic">
            <i className="icon-microphone menu-icon" />
            <span className="menu-title">Broadcast</span>
            <i className="menu-arrow" />
          </a>
          <div className="collapse" id="ui-productbroadcast">
            <ul className="nav flex-column sub-menu">
              {role.ProductSearch &&
                <li className="nav-item" type="button" data-toggle="offcanvas"> <NavLink to={"/productrequest"} className="nav-link">Product Search</NavLink></li>}
              {role.ProductOffload &&
                <li className="nav-item" type="button" data-toggle="offcanvas"> <NavLink to={"/productoffload"} className="nav-link">Product OffLoad</NavLink></li>}
              {role.ProductBroadcast &&
                <li className="nav-item" type="button" data-toggle="offcanvas"> <NavLink to={"/productbroadcasts"} className="nav-link">Product Broadcasts</NavLink></li>}
            </ul>
          </div>
        </li>
        }
        <li className="nav-item">
          <a className="nav-link" data-toggle="collapse" href="#ui-report" aria-expanded="false" aria-controls="ui-basic">
            <i className="icon-folder menu-icon" />
            <span className="menu-title">Reports</span>
            <i className="menu-arrow" />
          </a>
          <div className="collapse" id="ui-report">
            <ul className="nav flex-column sub-menu">
              {role.AccountBalance &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/accountbalance"} className="nav-link">Account Balance</NavLink>
                </li>}
              {role.FinanicialLedger &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/listfinancialledger"} className="nav-link"> Financial Ledger</NavLink>
                </li>}
              {role.StockBalance &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/stockbalance"} className="nav-link"> Stock Balance</NavLink>
                </li>}
              {role.AggregatedDocument &&
                <li className="nav-item" type="button" data-toggle="offcanvas">
                  <NavLink to={"/aggregatedreports"} className="nav-link"> Aggregated Document</NavLink>
                </li>}
              {/* <li className="nav-item" type="button" data-toggle="offcanvas">
                <NavLink to={"/pendingpayments"} className="nav-link"> Pending Payment</NavLink>
              </li> */}
            </ul>
          </div>
        </li>
        {role.ConferenceExhibitors &&

          <li className="nav-item" type="button" data-toggle="offcanvas">
            <NavLink className="nav-link" to={"/conferenceexhibitorlist"}>
              <i className="icon-target menu-icon" />
              <span className="menu-title">Conference Exhibitors</span>
            </NavLink>
          </li>
        }
        {(isExhibitor && role.ExhibitionPayment) && <li className="nav-item" type="button" data-toggle="offcanvas">
          <NavLink className="nav-link" to={"/exhibitionpayment"}>
            <i className="icon-target menu-icon" />
            <span className="menu-title">Exhibition Payment</span>
          </NavLink>
        </li>}

        {role.ProductReOrder &&
          <li className="nav-item" type="button" data-toggle="offcanvas">
            <a className="nav-link" onClick={reOrder}>
              <i className="mdi mdi-cart-outline menu-icon" />
              <span className="menu-title">Product Re-Order</span>
            </a>
          </li>
        }

        {((!auth.isBussinessUser) && (role.Wallet)) && <li className="nav-item" type="button" data-toggle="offcanvas">
          <NavLink className="nav-link" to={"/mywallet"}>
            <i className="ti-wallet menu-icon" />
            <span className="menu-title">My Wallet</span>
          </NavLink>
        </li>}

        {auth.role?.name === "Sales Rep" &&
          <li className="nav-item" type="button" data-toggle="offcanvas">
            <NavLink className="nav-link" to={"/assignedbusiness"}>
              <i className="icon-target menu-icon" />
              <span className="menu-title">Assigned Business</span>
            </NavLink>
          </li>}

        {/* <li className="nav-item">
          <a className="nav-link" data-toggle="collapse" href="#ui-basic" aria-expanded="false" aria-controls="ui-basic">
            <i className="icon-layout menu-icon" />
            <span className="menu-title">UI Elements</span>
            <i className="menu-arrow" />
          </a>
          <div className="collapse" id="ui-basic">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/generateproformainvoice"} className="nav-link">Gen Proforma Invoice</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/viewquoterequest"} className="nav-link">View Quote Request</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/viewquote"} className="nav-link">View Quote</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/newQuoteRequest"} className="nav-link">New Quote Request</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/placeOrder"} className="nav-link">Place Order</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/payments"} className="nav-link">Payment</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/paymentsuccess"} className="nav-link">Payment Success</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/haulagerequest"} className="nav-link">Haulage Request</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/viewhaulagerequest"} className="nav-link">View Haulage Req.</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/generatehaulagebill"} className="nav-link">Gen Haulage bill</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/newhaulagebill"} className="nav-link">New Haulage Bill</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/accepthaulagebill"} className="nav-link">Accept Haulage Bill</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/pickuprequest"} className="nav-link">Pickup Request</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/viewReceiptNote"} className="nav-link">View Receipt Note</NavLink>
              </li>
              <li className="nav-item" type="button" data-toggle="offcanvas"> 
              <NavLink to={"/newReceiptNote"} className="nav-link">New Receipt Note</NavLink>
              </li>
            </ul>
          </div>
        </li> */}

        <li className="nav-item" type="button" data-toggle="offcanvas">
          <a href='https://doc.phamart.ng' rel='noreferrer' target={'_blank'} className="nav-link">
            <i className="icon-help menu-icon" />
            <span className="menu-title">Documentation</span>
          </a>
        </li>
        {/* <li className="nav-item">
          <a className="nav-link" data-toggle="collapse" href="#ui-support" aria-expanded="false" aria-controls="ui-basic">
            <i className="icon-help menu-icon" />
            <span className="menu-title">Support</span>
            <i className="menu-arrow" />
          </a>
          <div className="collapse" id="ui-support">
            <ul className="nav flex-column sub-menu">
              <li className="nav-item" type="button" data-toggle="offcanvas"> <a href='https://doc.phamart.ng' rel='noreferrer' style={{ cursor: 'pointer' }} target={'_blank'} className="nav-link">Documentation </a></li>
            </ul>
          </div>
        </li> */}

        <li style={{ cursor: 'pointer' }} className="nav-item">
          <a onClick={logout} className="nav-link" aria-expanded="false" aria-controls="ui-basic">
            <i className="icon-power menu-icon text-danger" />
            <span className="menu-title">Logout</span>
          </a>
        </li>
      </ul>
    </nav>
    }
    {auth.isAdmin && <AdminSidebar />}

  </>;
}
