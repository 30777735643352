import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { notifyError, notifySuccess } from '../../utils/toast';
import api from '../../utils/api';
import Paginate from '../../components/paginate';
import Limit from '../../components/limit';
import Backbutton from '../../components/backbutton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function FavouriteSupplier() {

    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: 'onChange'
    });
    const [suppliers, setSuppliers] = useState([]);
    const [favouriteSuppliers, setFavouriteSuppliers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pageCount, setPageCount] = useState(0);
    const [limit, setLimit] = useState(15);
    const [search, setSearch] = useState("");
    const page = 1;
    const [selectedSuppliersIds, setSelectedSuppliersIds] = useState([]);
    const [defaultSupplierId, setDefaultSupplierId] = useState("");
    const [v, setV] = useState();
    const auth = JSON.parse(window.localStorage.getItem('auth'));
    const history = useHistory();

    const getFavouriteSuppliers = async () => {
        const response = await api.Business.loadFavouriteSuppliers();
        let suppliers = [];
        if (response?.data) {
            for (const s of response?.data) {
                let obj = {
                    supplierId: s.id,
                    businessName: s.businessName
                }
                suppliers.push(obj);
            }
            setSelectedSuppliersIds(suppliers);
            setDefaultSupplierId(suppliers[0]?.supplierId)
            setV(suppliers?.map(x => x.businessName));
            setFavouriteSuppliers(response?.data);
        }
    };

    useEffect(() => {
        try {
            getFavouriteSuppliers();
        }
        catch (error) {
            console.log(error)
        }
    }, []);

    useEffect(() => {
        try {
            const getSuppliers = async () => {
                const response = await api.QuotationNote.loadSuppliers(page, limit, search);
                const count = response.data.totalCount;
                setPageCount(Math.ceil(count / limit))
                setSuppliers(response.data.data)
            };
            getSuppliers();
        }
        catch (error) {
            console.log(error)
        }
    }, [search, limit]);

    const searchSupplier = (value) => {
        setSearch(value)
    }

    const addSupplier = (supplierId, businessName) => {
        if (selectedSuppliersIds.length === 5) {
            return notifyError(`Selected suppliers must not be greater than 5`)
        }
        if (selectedSuppliersIds.length === 0) {
            setDefaultSupplierId(supplierId);
        }
        const obj = {
            supplierId,
            businessName
        }
        let exist = selectedSuppliersIds.find(x => x.supplierId === supplierId);
        if (exist) {
            return;
        }
        setSelectedSuppliersIds([...selectedSuppliersIds, obj]);
    }

    const removeSupplier = (supplierId, businessName) => {
        const obj = {
            supplierId,
            businessName
        }
        let exist = selectedSuppliersIds.find(x => x.supplierId === supplierId);

        if (exist) {
            const filteredSuppliers = selectedSuppliersIds.filter(function (item) {
                return item.supplierId !== obj.supplierId;
            })
            setSelectedSuppliersIds([...filteredSuppliers]);
        }
    }

    const addDefaultSupplier = (supplierId) => {
        const selected = selectedSuppliersIds.find(x => x.supplierId === supplierId);
        if (!selected) {
            return notifyError("Default supplier must be among favourite suppliers")
        }
        const filtered = selectedSuppliersIds.filter(x => x.supplierId !== supplierId);
        filtered.unshift(selected);
        setSelectedSuppliersIds(filtered);
        setDefaultSupplierId(supplierId);
    }

    const closeMod = () => {
        const checkDefaultSupplier = selectedSuppliersIds.find(x => x.supplierId === defaultSupplierId);
        if (!checkDefaultSupplier) {
            return notifyError("Default supplier must be among favourite suppliers")
        }
        setV(selectedSuppliersIds?.map(x => x.businessName));
        document.getElementById("closeModal").click();
    }

    const saveSupplier = async () => {
        const hasPermissionToCreate = (auth?.role.rolePermission.find(e => e.module.moduleName === "Favourite Supplier"))?.canCreate
        if (hasPermissionToCreate) {
            try {
                if (selectedSuppliersIds?.length === 0) {
                    return notifyError("Suppliers cannot be empty")
                }
                setLoading(true)
                let obj = {
                    suppliersIds: selectedSuppliersIds?.map(x => x.supplierId)
                }
                const response = await api.Business.saveFavouriteSuppliers(obj);
                if (response.sucessMessage) {
                    notifySuccess(response.sucessMessage);
                    setLoading(false);
                    getFavouriteSuppliers();
                } else {
                    setLoading(false)
                }
            }
            catch (error) {
                console.log(error)
                setLoading(false);
            }
        }
        else {
            history.push('/403');
        }
    }

    return <>
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="text-center page-head">
                            <span className="card-title">SELECT FAVOURITE SUPPLIERS</span>
                        </div>
                        <div className="d-flex float-right mt-2">
                            {/* <Backbutton></Backbutton> */}
                            <button type="submit" onClick={handleSubmit(saveSupplier)} class="btn btn-primary btn-sm btn-icon-text text-white d-flex" disabled={loading ? true : false}>
                                <i className="ti-save mr-1" title="Submit"></i>
                                <span className="d-none d-md-block">{loading ? "SAVING..." : "SAVE"}</span>
                            </button>
                        </div>
                        <form className="mt-5">
                            <div className="form-group">
                                <label htmlFor="suppliers">Favourite Suppliers<span className="text-danger">*</span>
                                    {errors.suppliers &&
                                        <span className="text-danger font-weight-bold"> required</span>
                                    }
                                </label>
                                <div className='input-group text-capitalize'>
                                    <input type="text" className="form-control"
                                        id="suppliers"
                                        name="suppliers"
                                        value={v?.length > 0 ? v?.join(", ") : ""}
                                        {...register("suppliers", { required: false })}
                                        readOnly
                                        placeholder="SELECT SUPPLIERS" />
                                    <div
                                        style={{ cursor: 'pointer' }}
                                        className="input-group-prepend"
                                        data-toggle="modal"
                                        data-target="#participantsModal">
                                        <span
                                            className="input-group-text bg-primary text-light"
                                            id="inputGroup-sizing-default"
                                        >
                                            <i
                                                className={
                                                    "mdi mdi-plus"
                                                }
                                            ></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                {favouriteSuppliers.length > 0 && <div className="stretch-card grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-center page-head">
                                <span className="card-title">FAVOURITE SUPPLIERS</span>
                            </div>
                            <div className='table-responsive mt-5'>
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                S/N
                                            </th>
                                            <th>
                                                IS DEFAULT
                                            </th>
                                            <th>
                                                SUPPLIER CODE
                                            </th>
                                            <th>
                                                SUPPLIER NAME
                                            </th>
                                            <th>
                                                SUPPLIER ADDRESS
                                            </th>
                                            <th>
                                                SUPPLIER EMAIL
                                            </th>
                                            <th>
                                                SUPPLIER PHONE
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {favouriteSuppliers &&
                                            favouriteSuppliers?.map((supplier, index) =>
                                                <tr className="py-0">
                                                    <td className="text-uppercase text-wrap">
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {index === 0 &&
                                                            <span className="btn btn-primary btn-sm" >
                                                                Is Default
                                                            </span>
                                                        }
                                                        {index !== 0 &&
                                                            <span className="btn btn-secondary btn-sm text-white" >
                                                                Not Default
                                                            </span>
                                                        }
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {supplier.businessCode}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {supplier.businessName}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {supplier.businessAddress}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {supplier.businessEmail}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {supplier.businessPhone}
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            {
                                suppliers.length === 0 &&
                                <div className='row'>
                                    <strong className='text-muted mx-auto mt-5 h5'>No Supplier Record</strong>
                                </div>
                            }
                        </div>
                    </div>
                </div>}
            </div>
        </div>

        {/* Supplier Modal */}
        <div className="modal fade" id="participantsModal" data-backdrop="static" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h3 className="font-weight-bold text-center">SELECT SUPPLIERS</h3>
                        <button type="button" className="close" data-dismiss="modal" id="closeModal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Limit setLimit={setLimit} />
                        {/* <div className='row justify-content-end'> */}
                        <button onClick={closeMod} type="button" className="btn btn-primary btn-sm btn-icon-text text-white d-flex float-right mr-2">
                            <i className="ti-save mr-1" title="Submit"></i>
                            <span className="d-none d-md-block">SAVE</span>
                        </button>
                        {/* </div> */}
                        <div className="form-group col-md-6 mx-auto">
                            <input type="text" className="form-control"
                                placeholder="Search Supplier" onChange={e => searchSupplier(e.target.value)} />
                        </div>

                        <div className="row">
                            <div className="col-md-12 grid-margin stretch-card">
                                <div className="card">
                                    <div className="">
                                        <div className='table-responsive'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            ACTION
                                                        </th>
                                                        <th>
                                                            IS DEFAULT
                                                        </th>
                                                        <th>
                                                            S/N
                                                        </th>
                                                        <th>
                                                            SUPPLIER CODE
                                                        </th>
                                                        <th>
                                                            SUPPLIER NAME
                                                        </th>
                                                        <th>
                                                            SUPPLIER LOCATION
                                                        </th>
                                                        <th>
                                                            SUPPLIER EMAIL
                                                        </th>
                                                        <th>
                                                            SUPPLIER PHONE
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {suppliers &&
                                                        suppliers?.map((supplier, index) =>
                                                            <tr className="py-0">
                                                                <td>
                                                                    {!selectedSuppliersIds.find(x => x.supplierId === supplier.id) && <span className="btn" onClick={() => addSupplier(supplier.id, supplier.businessName)}>
                                                                        <i className="mdi mdi-star-outline"></i>
                                                                    </span>}
                                                                    {selectedSuppliersIds.find(x => x.supplierId === supplier.id) && <span className="btn" onClick={() => removeSupplier(supplier.id, supplier.businessName)}>
                                                                        <i className="mdi mdi-star" style={{ color: "orange" }}></i>
                                                                    </span>}
                                                                </td>
                                                                <td>
                                                                    {!(defaultSupplierId === supplier.id) && <span className="btn" onClick={() => addDefaultSupplier(supplier.id)}>
                                                                        <i className="mdi mdi-plus"></i>
                                                                    </span>}
                                                                    {(defaultSupplierId === supplier.id) && <span className="btn">
                                                                        <i className="mdi mdi-check"></i>
                                                                    </span>}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {index + 1}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {supplier.businessCode}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {supplier.businessName}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {supplier.locations[0].locationAddress}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {supplier.businessEmail}
                                                                </td>
                                                                <td className="text-uppercase text-wrap">
                                                                    {supplier.businessPhone}
                                                                </td>
                                                            </tr>
                                                        )}
                                                </tbody>
                                            </table>
                                        </div>
                                        {
                                            suppliers.length === 0 &&
                                            <div className='row'>
                                                <strong className='text-muted mx-auto mt-5 h5'>No Supplier Record</strong>
                                            </div>
                                        }
                                        {
                                            suppliers.length !== 0 &&
                                            <Paginate
                                                limit={limit}
                                                setData={setSuppliers}
                                                apiToCall={api.QuotationNote.loadSuppliers}
                                                pageCount={pageCount}
                                            />
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
