import superagentPromise from 'superagent-promise';
import _superagent, { search } from 'superagent';
import { notifyError } from './toast';

const superagent = superagentPromise(_superagent, global.Promise);

export const API_ROOT = process.env.REACT_APP_API_URL;


let token = null;
let polarisRequestRef = null;
const responseBody = res => res.body;

const getAuthToken = () => {
  const auth = JSON.parse(window.localStorage.getItem('auth'));
  const token = auth ? auth.token : null;
  return token;
}

export const tokenPlugin = req => {
  req.set('Accept', 'application/json');
  token = getAuthToken();

  req.set("ngrok-skip-browser-warning", true);

  if (token) {
    req.set('Authorization', `Bearer ${token}`);
  }

  req.on('error', (error) => {
    if (error.status === undefined) {
      //
    }

  });

  req.on('response', function (res) {
    if (res.status === 401) {
      // redirect to login page here
      localStorage.setItem("lastAccessedUrl", window.location.pathname);
      window.location.href = `${window.location.origin}/signin`;
    }
    if (res.status === 403) {
      window.location.href = `${window.location.origin}/403`;
    }
    if (res.body.error) {
      notifyError(res.body.errorMessage);
      if (res.body?.errorMessage?.includes("Please set up a default bank account")) {
        setTimeout(() => {
          window.location.href = `${window.location.origin}/createbankdetail`;
        }, 1500)
      }

      if (res.body?.errorMessage?.includes("Please kindly add the email for recieving otp on your profile page")) {
        setTimeout(() => {
          window.location.href = `${window.location.origin}/editprofile?focus=true`;
        }, 1500)
      }
    }

    if (res.body?.message && Array.isArray(res.body.message) && res.body.message.length > 0) {
      notifyError(res.body.message.join(", "));
    }
  });

}

const requests = {
  del: url =>
    superagent.del(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  get: url =>
    superagent.get(`${API_ROOT}${url}`).use(tokenPlugin).then(responseBody),
  put: (url, body) =>
    superagent.put(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  post: (url, body) =>
    superagent.post(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody),
  patch: (url, body) =>
    superagent.patch(`${API_ROOT}${url}`, body).use(tokenPlugin).then(responseBody)
};
const Auth = {
  logError: (data) =>
    requests.post('log/error', { data }),
  isAuth: () => {
    const token = getAuthToken();
    return !!token;
  },
  saveAuthData: (_user) => {
    window.localStorage.setItem('auth', JSON.stringify(_user));
    token = _user.token;
  },

  logout: () => {
    window.localStorage.removeItem('auth')
    localStorage.removeItem("locationId")
    localStorage.removeItem("locationVerify")
    token = null
  },

  currentUser: () => JSON.parse(window.localStorage.getItem('auth')),
  isReqForQuote: () => {
    const user = Auth.currentUser();
    const part = user?.participantTypes[0]?.participantName;
    return part === ("Retailer (Community Pharmacist)") || part === ("Wholesaler") || part === ("Distributor")
  },
  isAdmin: () => {
    const user = Auth.currentUser();
    return user.isAdmin === true
  },
  isProformaInv: () => {
    const user = Auth.currentUser();
    const part = user?.participantTypes[0]?.participantName;
    return part === ("Manufacturer") || part === ("Importer") || part === ("Wholesaler") || part === ("Distributor")
  },
  isPriAdj: () => {
    const user = Auth.currentUser();
    const part = user?.participantTypes[0]?.participantName;
    return part === ("Manufacturer") || part === ("Importer") || part === ("Retailer (Community Pharmacist)") || part === ("Wholesaler") || part === ("Distributor")
  },
  hauler: () => {
    const user = Auth.currentUser();
    const part = user?.participantTypes[0]?.participantName;
    return part === ("Hauler") || part === ("Courier")
  },
  isManu: () => {
    const user = Auth.currentUser();
    const part = user?.participantTypes[0]?.participantName;
    return part === ("Manufacturer");
  },
  login: (email, password) =>
    requests.post('auth/login', { email, password }),
  register: data =>
    requests.post('auth/register', data),
  verifyEmailToken: token =>
    requests.get(`auth/verify-email/${token}`),
  verifyPasswordToken: token =>
    requests.get(`auth/valid-password-token/${token}`),
  forgotPassword: (email) =>
    requests.get(`auth/forgot-password/${email}`),
  changePassword: (data) =>
    requests.post(`auth/change-password`, data),
  resetPassword: (password, confirmPassword, token) =>
    requests.post(`auth/reset-password`, { password, confirmPassword, resetToken: token }),
  sendResetToken: (email) =>
    requests.post(`auth/password/email`, { email }),
  resendVerification: (email) =>
    requests.get(`auth/resend-verification/${email}`),
  isBusinessSetup: () => {
    const user = Auth.currentUser();
    return (!user.isBusinessUser)
  },
  isAddProduct: () => {
    const user = Auth.currentUser();
    return (!user.isBusinessUser) || (user.isBusinessUser)
  },
  isTransaction: () => {
    const user = Auth.currentUser();
    return (!user.isBusinessUser) || (user.isBusinessUser)
  },
  contactUs: (data) =>
    requests.post(`auth/contact-us`, data),
}
const User = {
  save: (user) =>
    requests.post('user', user),
  load: (page, limit, search) =>
    requests.get(`user?page=${page}&limit=${limit}&search=${search}`),
  edit: (id, user) =>
    requests.put(`user/${id}`, user),
  delete: (id) =>
    requests.del(`user/${id}`),
  view: (id) =>
    requests.get(`user/${id}`),
  addAdmin: (user) =>
    requests.post('user/addAdmin/', user),
  findAdmin: (email) =>
    requests.get(`user/admin/${email}`),
  assignSalesRep: (salesRep) =>
    requests.post('user/assign/sale_rep', salesRep),
  loadSalesReps: (page, limit, search) =>
    requests.get(`user/role/sales_rep/?page=${page}&limit=${limit}&search=${search}`),
  viewSalesRep: (assignFromId) =>
    requests.get(`user/role/view_sale_rep/${assignFromId}`),
  loadAssignedBusiness: (search, page, limit) =>
    requests.get(`user/view/assigned_business/?page=${page}&limit=${limit}&search=${search}`),
};
const Business = {
  updateBusinessProfile: (email, data) =>
    requests.put(`business/individual/${email}`, data),
  updateProfilePicture: (image) =>
    requests.put('business/uploads', image),
  getAll: (page, limit, search) =>
    requests.get(`business/?page=${page}&limit=${limit}&search=${search}`),
  getByID: (id) =>
    requests.get(`business/${id}`),
  getByCode: (code) =>
    requests.get(`business/by-code/${code}`),
  getIndustries: () =>
    requests.get(`business/industries/all`),
  getDocumentType: () =>
    requests.get(`business/documentTypes/all`),
  getParticipantTypes: () =>
    requests.get(`business/participanttypes/all`),
  getBusinessAccount: (id) =>
    requests.get(`business/${id}`),
  updateBusinessAccount: (id, data) =>
    requests.put(`business/${id}`, data),
  deleteBusinessAccount: (id) =>
    requests.delete(`business/${id}`),
  GetCustomerOrSupplier: (page, limit, value, search) =>
    requests.get(`business/accountBalance/${value || null}?page=${page}&limit=${limit}&search=${search}`),
  viewAccount: (id) =>
    requests.get(`business/${id}`),
  getBizContactPersons: (id) =>
    requests.get(`business/users/bybusiness/${id}`),
  getAllHaulers: (page, limit, search) =>
    requests.get(`business/all/haulers-couriers?page=${page}&limit=${limit}&search=${search}`),
  saveFavouriteSuppliers: (data) =>
    requests.post(`business/favourite-suppliers`, data),
  loadFavouriteSuppliers: () =>
    requests.get(`business/favourite/suppliers`),
  saveFavouriteHaulers: (data) =>
    requests.post(`business/favourite-haulers`, data),
  loadFavouriteHaulers: () =>
    requests.get(`business/favourite/haulers`),
  saveBankDetails: (data) =>
    requests.post(`business/bank-details`, data),
  getAllBankDetails: (search, page, limit) =>
    requests.get(`business/bank-details/me?search=${search}&page=${page}&limit=${limit}`),
  deleteBankDetail: (id) =>
    requests.del(`business/bank-details/me/${id}`),
  viewBankDetail: (id) =>
    requests.get(`business/bank-details/me/${id}`),
  editBankDetail: (id, body) =>
    requests.put(`business/bank-details/me/${id}`, body),
}
const Country = {
  load: () => requests.get('country'),
};
const State = {
  loadByCountry: (countryid) => requests.get(`/state/getbycountry/${countryid}`),
};
const BusinessDocumentSetting = {
  save: (data) =>
    requests.post('businessDocumentSetting', data),
  saveGlobal: (data) =>
    requests.post('businessDocumentSetting/global', data),
  getByID: (id) =>
    requests.get(`businessDocumentSetting/document/${id}`),
  getByIdGlobal: (id) =>
    requests.get(`businessDocumentSetting/global/document/${id}`),
}
const BusinessLocation = {
  save: (data) =>
    requests.post('businessLocation', data),
  load: (page, limit, search) =>
    requests.get(`businessLocation/?page=${page}&limit=${limit}&search=${search}`),
  loadVerified: (page, limit, search) =>
    requests.get(`businessLocation/verified/all?page=${page}&limit=${limit}&search=${search}`),
  edit: (id, data) =>
    requests.put(`businessLocation/${id}`, data),
  view: (id) =>
    requests.get(`businessLocation/${id}`),
  delete: (id) =>
    requests.del(`businessLocation/${id}`),
  loadAdminBusinessLoc: (page, limit, search) =>
    requests.get(`businessLocation/admin/all?page=${page}&limit=${limit}&search=${search}`),
  verify: (id) =>
    requests.patch(`businessLocation/verify/${id}`),
  listHauler: (page, limit, search) =>
    requests.get(`businessLocation/admin/getHaulersAndCouriers?page=${page}&limit=${limit}&search=${search}`),
  getHQVerificationStatus: (id) =>
    requests.get(`businessLocation/hq/status`),
  reVerify: () =>
    requests.patch(`businessLocation/re-verify`),
}
const Product = {
  save: (data) =>
    requests.post(`product`, data),
  getAllProduct: (page, limit, search) =>
    requests.get(`product/?page=${page}&limit=${limit}&search=${search}`),
  getAllManu: (page, limit) =>
    requests.get(`utility/product/getManufacturers?page=${page}&limit=${limit}`),
  getAllOwner: (page, limit) =>
    requests.get(`utility/product/getOwners?page=${page}&limit=${limit}`),
  deleteProduct: (id) =>
    requests.del(`product/${id}`),
  viewProduct: (id) =>
    requests.get(`product/${id}`),
  updateProduct: (id, data) =>
    requests.put(`product/${id}`, data),
  migrate: (data) =>
    requests.post(`utility/productMigration`, data),
  loadProductForSorting: (page, limit, search, category, manufacturer, owner, genericName, sortBy) =>
    requests.get(`product/all/searchFilterSort?page=${page}&limit=${limit}&search=${search}&category=${encodeURIComponent(category)}&manufacturer=${encodeURIComponent(manufacturer)}&owner=${encodeURIComponent(owner)}&genericName=${encodeURIComponent(genericName)}&sortBy=${sortBy}`),
  migrate1: (data) =>
    requests.post(`utility/productMigration-qty-price`, data),

}
const Partnership = {
  save: (data) =>
    requests.post('partnership', data),
  loadBusinessData: (businesscode) =>
    requests.get(`partnership/bybusinesscode/${businesscode}`),
  load: (page, limit, search) =>
    requests.get(`partnership/?page=${page}&limit=${limit}&search=${search}`),
  delete: (id) =>
    requests.del(`partnership/${id}`),
  accept: (id) =>
    requests.patch(`partnership/${id}`),
  updateDefaultDiscount: (id, discount) =>
    requests.patch(`partnership/update-default-discount/${id}/${discount}`)
}
const ProductGeneralisation = {
  save: (data) =>
    requests.post('productgeneralization', data),
  load: (page, limit, search) =>
    requests.get(`productgeneralization/?page=${page}&limit=${limit}&search=${search}`),
  edit: (id, data) =>
    requests.put(`productgeneralization/${id}`, data),
  view: (id) =>
    requests.get(`productgeneralization/${id}`),
  delete: (id) =>
    requests.del(`productgeneralization/${id}`)

}
const StockBalance = {
  load: (page, limit, search, locationId) =>
    requests.get(`stockBalance/?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  updateReorder: (data) =>
    requests.put('stockBalance/updateReOrderLevelQty', data),
}
const productIndustry = {
  load: () =>
    requests.get(`product/Productindustries/all`)
}
const QuotationNote = {
  save: (data) =>
    requests.post('quotation', data),
  load: (page, limit, search, locationId) =>
    requests.get(`quotation/?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  approve: (id, data) =>
    requests.patch(`quotation/approve/${id}`, data),
  view: (id) =>
    requests.get(`quotation/${id}`),
  delete: (id) =>
    requests.del(`quotation/${id}`),
  loadProductForQuote: (page, limit, search, category, manufacturer, owner, genericName, sortBy) =>
    requests.get(`product/all/searchFilterSort?page=${page}&limit=${limit}&search=${search}&category=${encodeURIComponent(category)}&manufacturer=${encodeURIComponent(manufacturer)}&owner=${encodeURIComponent(owner)}&genericName=${encodeURIComponent(genericName)}&sortBy=${sortBy}`),
  get: (userId, quoteId) =>
    requests.get(`quotation/userPrice/${userId}/${quoteId}`),
  loadSuppliers: (page, limit, search) =>
    requests.get(`quotation/getSuppliers/all/?page=${page}&limit=${limit}&search=${search}`),
  getDocumentSetting: (docType, busId) =>
    requests.get(`businessDocumentSetting/bybusinessAndDocument/${docType}/${busId}`),
  getDocumentSettingByDocName: (docName) =>
    requests.get(`businessDocumentSetting/${docName}`),
  cancel: (id, data) =>
    requests.patch(`quotation/cancel/${id}`, data),
  edit: (id, data) =>
    requests.put(`quotation/${id}`, data),
}
const BalanceAdjustment = {
  save: (data) =>
    requests.post('balanceAdjustment', data),
  load: (page, limit, search, locationId) =>
    requests.get(`balanceAdjustment/?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  edit: (id, data) =>
    requests.put(`balanceAdjustment/${id}`, data),
  view: (id) =>
    requests.get(`balanceAdjustment/${id}`),
  delete: (id) =>
    requests.del(`balanceAdjustment/${id}`),
  loadProduct: (page, limit, search) =>
    requests.get(`balanceAdjustment/product/all?page=${page}&limit=${limit}&search=${search}`),
  approve: (id) =>
    requests.patch(`balanceAdjustment/approve/${id}`),
  loadPack: (productId, packId) =>
    requests.get(`stockBalance/byProductAndPack/${productId}/${packId}/${localStorage.getItem('locationId')}`)
}
const BusinessProduct = {
  save: (data) =>
    requests.post(`businessProduct`, data),
  getAll: (page, limit, search) =>
    requests.get(`businessProduct?page=${page}&limit=${limit}&search=${search}`),
  getById: (id) =>
    requests.get(`businessProduct/${id}`),
  delete: (id) =>
    requests.del(`businessProduct/${id}`),
  update: (id, data) =>
    requests.put(`businessProduct/${id}`, data)
}
const Ordering = {
  getProducts: (page, limit, searchbyLoc, searchbybus, search, category, manufacturer, owner, genericName, sortBy) =>
    requests.get(`ordering/products?page=${page}&limit=${limit}&locationId=${searchbyLoc}&supplierId=${searchbybus}&stockId=${search}&category=${encodeURIComponent(category)}&manufacturer=${encodeURIComponent(manufacturer)}&owner=${encodeURIComponent(owner)}&genericId=${encodeURIComponent(genericName)}&sortBy=${sortBy}`),
  getPatnersAndLocation: (page, limit, search) =>
    requests.get(`ordering/partner/location?page=${page}&limit=${limit}&search=${search}`),
  save: (data) =>
    requests.post(`ordering`, data),
  edit: (id, data) =>
    requests.put(`ordering/${id}`, data),
  getAll: (page, limit, search, locationId) =>
    requests.get(`ordering/getall/business/order/?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  getById: (id) =>
    requests.get(`ordering/${id}`),
  approve: (id, data) =>
    requests.patch(`ordering/approve/${id}`, data),
  delete: (id) =>
    requests.del(`ordering/${id}`),
  getAllConferenceSupplier: (page, limit, search) =>
    requests.get(`conference/suppliers/live/?page=${page}&limit=${limit}&search=${search}`),
  getDeliveryNote: (id) =>
    requests.get(`deliverynote/get/deleiverynote/issueon/${id}`),
}
const QuotationResponse = {
  save: (data) =>
    requests.post('quotationResponse', data),
  load: (page, limit, search, locationId) =>
    requests.get(`quotationResponse/?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  view: (id) =>
    requests.get(`quotationResponse/${id}`),
  accept: (id) =>
    requests.patch(`quotationResponse/accept/${id}`),
  delete: (id) =>
    requests.del(`quotationResponse/${id}`),
  cancel: (id, data) =>
    requests.patch(`quotationResponse/cancel/${id}`, data),
  approve: (id, data) =>
    requests.patch(`quotationResponse/approve/${id}`, data)
}
const CustomerBalance = {
  save: (data) =>
    requests.post('customerBalance', data),
  edit: (id, data) =>
    requests.put(`customerBalance/${id}`, data),
  getAll: (page, limit, search) =>
    requests.get(`customerBalance?page=${page}&limit=${limit}&search=${search}`),
  view: (id) =>
    requests.get(`customerBalance/${id}`),
  delete: (id) =>
    requests.del(`customerBalance/${id}`),
  approve: (id) =>
    requests.patch(`customerBalance/approve/${id}`)
}
const CreateCountry = {
  save: (data) =>
    requests.post('country', data),
  load: (page, limit, search) =>
    requests.get(`country?page=${page}&limit=${limit}`),
  edit: (id, data) =>
    requests.put(`country/${id}`, data),
  view: (id) =>
    requests.get(`country/${id}`),
  delete: (id) =>
    requests.del(`country/${id}`),
  patch: (id, data) =>
    requests.patch(`country/${id}`, data)
}

const Payment = {
  loadOnlinePayment: (page, limit, search) =>
    requests.get(`online_payment?page=${page}&limit=${limit}&search=${search}`),
  save: (data) =>
    requests.post('payment/postpayment', data),
  load: () =>
    requests.get(`payment/paymentTypes/partial`),
  loadPayments: (page, limit, search) =>
    requests.get(`payment?page=${page}&limit=${limit}&search=${search}`),
  edit: (id, data) =>
    requests.put(`payment/${id}`, data),
  view: (id) =>
    requests.get(`payment/${id}`),
  delete: (id) =>
    requests.del(`payment/${id}`),
  patch: (id, data) =>
    requests.patch(`payment/${id}`),
  approve: (id) =>
    requests.patch(`payment/approve/${id}`),
  accept: (id) =>
    requests.patch(`payment/accept/${id}`),
  cancelOrDecline: (id, data) =>
    requests.patch(`payment/cancelOrDecline/${id}`, { reason: data }),
  paymentType: () =>
    requests.get(`payment/paymentTypes/all`),
  create: (data) =>
    requests.post(`payment`, data),
  generateVirtualAccountForInvoice: (invoiceNo, paymentTypeId) =>
    requests.get(`payment/invoice/generateVirtualAcctNo/${invoiceNo}/${paymentTypeId}`),
  generateVirtualAccountForHaulerBill: (billNo, paymentTypeId) =>
    requests.get(`payment/haulerBill/generateVirtualAcctNo/${billNo}/${paymentTypeId}`)
}
const Charge = {
  save: (data) =>
    requests.post('charge', data),
  load: (page, limit, search, locationId) =>
    requests.get(`charge/?search=${search}&page=${page}&limit=${limit}&locationId=${locationId}`),
  edit: (id, data) =>
    requests.put(`charge/${id}`, data),
  view: (id) =>
    requests.get(`charge/${id}`),
  delete: (id) =>
    requests.del(`charge/${id}`),
  getAllDefault: () =>
    requests.get(`charge/default/all`),
}
const IssueNote = {
  edit: (id, data) =>
    requests.put(`issuenote/${id}`, data),
  getInvoice: (page, limit, search) =>
    requests.get(`issuenote/getall/approved/invoice?page=${page}&limit=${limit}&search=${search}`),
  getAllIssue: (page, limit, search, locationId) =>
    requests.get(`issuenote?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  view: (id) =>
    requests.get(`issuenote/${id}`),
  approve: (id, data) =>
    requests.patch(`issuenote/approve/${id}`, data)
}
const FinancialLedger = {
  getAll: (page, limit, search) =>
    requests.get(`financialLedger/?page=${page}&limit=${limit}&search=${search}`),
}
const ReceiptNote = {
  getDeliNote: (page, limit, search) =>
    requests.get(`recieptNote/deliveryNote/approved?page=${page}&limit=${limit}&search=${search}`),
  save: (data) =>
    requests.post(`recieptNote`, data),
  getAll: (page, limit, search, locationId) =>
    requests.get(`recieptNote?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  view: (id) =>
    requests.get(`recieptNote/${id}`),
  approve: (id, locId) =>
    requests.patch(`recieptNote/approve/${id}/${locId}`),
  generate: (data) =>
    requests.post(`recieptNote/generateBalanceAdjustment`, data),
  notReceived: (id, data) =>
    requests.patch(`recieptNote/notrecieved/${id}`, data)
}
const Invoice = {
  load: (page, limit, search, locationId) =>
    requests.get(`invoice?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  getbyId: (id) =>
    requests.get(`invoice/${id}`),
  approveSave: (id, data) =>
    requests.patch(`invoice/approve/${id}`, data),
  cancel: (id, data) =>
    requests.patch(`invoice/cancel/${id}`, data),
  creditLimit: (id) =>
    requests.get(`creditLimit/forSupplier/${id}`),
  customerBalance: (id) =>
    requests.get(`customerBalance/forSupplier/${id}`),
  loadApprovedOrder: (page, limit, search) =>
    requests.get(`invoice/orders/approved?page=${page}&limit=${limit}&search=${search}`),
  generateInvoice: (data) =>
    requests.post(`invoice/generate`, data),
  getAllDiscount: () =>
    requests.get(`discount/negotiablediscounts/all?page=1&limit=2000`),
  getAllCharge: () =>
    requests.get(`charge/negotiablecharges/all?page=1&limit=2000`),
  getPartPaid: (page, limit, search) =>
    requests.get(`invoice/partlypaid/payment?page=${page}&limit=${limit}&search=${search}`)

}
const Discount = {
  save: (data) =>
    requests.post('discount', data),
  load: (page, limit, search) =>
    requests.get(`discount/?page=${page}&limit=${limit}&search=${search}`),
  edit: (id, data) =>
    requests.put(`discount/${id}`, data),
  view: (id) =>
    requests.get(`discount/${id}`),
  delete: (id) =>
    requests.del(`discount/${id}`),
  getAllDefault: () =>
    requests.get(`discount/default/all`),
}
const DeliveryNote = {
  save: (data) =>
    requests.post('deliverynote', data),
  loadApprovedIssue: (page, limit, search, locationId) =>
    requests.get(`deliverynote/issueddocs/?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  edit: (id, data) =>
    requests.put(`deliverynote/${id}`, data),
  view: (id) =>
    requests.get(`deliverynote/${id}`),
  delete: (id) =>
    requests.del(`deliverynote/${id}`),
  load: (page, limit, search, locationId) =>
    requests.get(`deliverynote/?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  loadIssueNote: (issueno) =>
    requests.get(`deliverynote/issueno/${issueno}`),
  approve: (id) =>
    requests.patch(`deliverynote/${id}`),
  getDeliveryWithPickUpCode: (code) =>
    requests.get(`deliverynote/get/deliverynote/${code}`),
  saveHaulerName: (data) =>
    requests.post("deliverynote/deliverypickup", data),
  loadDelForHaulerRequest: (page, limit, search, type) =>
    requests.get(`deliverynote/forHaulerRequest/filtered/${type}/?page=${page}&limit=${limit}&search=${search}`),
}
const Retailshop = {
  getProducts: (page, limit, search,) =>
    requests.get(`stockBalance/products/forRetailShop?page=${page}&limit=${limit}&search=${search}`),
  save: (data) =>
    requests.post(`retailShop`, data),
  getAll: (page, limit, search, locationId) =>
    requests.get(`retailShop?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  getById: (id) =>
    requests.get(`retailShop/${id}`),
}
const Utility = {
  getDashboardIssueNotes: () =>
    requests.get('utility/dashboard/issueNote'),
  getBroadcastProductRequests: (page, limit, search,) =>
    requests.get(`utility/dashboard/product-requests?page=${page}&limit=${limit}&search=${search}`),
  getBroadcastProductOffloads: (page, limit, search,) =>
    requests.get(`utility/dashboard/product-offloads?page=${page}&limit=${limit}&search=${search}`),
  getDashboardProformaInvoices: () =>
    requests.get('utility/dashboard/ProformaInvoice'),
  getQuotesResponses: () =>
    requests.get('utility/dashboard/quote'),
  getDashboardPayments: () =>
    requests.get('utility/dashboard/payments'),
  getDashboardHaulageBills: () =>
    requests.get('utility/dashboard/haulageBill'),
  getDashboardDeliveryNotes: () =>
    requests.get('utility/dashboard/deliveryNote'),
  getDashboardOrders: () =>
    requests.get('utility/dashboard/order'),
  getTopProducts: () =>
    requests.get('utility/dashboard/product'),
  getProductGraphs: () =>
    requests.get('utility/dashboard/productgraph'),
  getTopCustomers: (page, limit, search) =>
    requests.get(`utility/dashboard/topcustomers/?page=${page}&limit=${limit}&search=${search}`),
  getReorderLevels: (page, limit, search) =>
    requests.get(`utility/dashboard/reorderlevel/?page=${page}&limit=${limit}&search=${search}`),
  getTopSales: (dateform, page, limit, search) =>
    requests.get(`utility/dashboard/topsales/?dateform=${dateform}page=${page}&limit=${limit}&search=${search}`),
  getReorderLevelGraphs: () =>
    requests.get('utility/dashboard/productlevel'),
  getAllBusiness: (page, limit, search) =>
    requests.get(`utility/getAllBusinesses?page=${page}&limit=${limit}&search=${search}`),
  loadAllParticipants: (page, limit, search, sort, participantType) =>
    requests.get(`business?page=${page}&limit=${limit}&search=${search}&sortBy=${sort}&type=${participantType}`),
  saveDocumentType: (data) =>
    requests.post('utility/createDocType', data),
  loadDocumentTypes: () =>
    requests.get(`utility/getDocType`),
  loadADocumentType: (id) =>
    requests.get(`utility/getDocType/${id}`),
  saveParticipant: (data) =>
    requests.post(`utility/createParticipantType`, data),
  loadParticipantTypes: () =>
    requests.get('utility/getPartType'),
  ViewParticipantType: (id) =>
    requests.get(`utility/getPartType/${id}`),
  loadPaymentTypes: () =>
    requests.get(`utility/getPaymentType`),
  savePayment: (data) =>
    requests.post('utility/createPaymentType', data),
  ViewPaymentType: (id) =>
    requests.get(`utility/getPaymentType/${id}`),
  loadCategories: () =>
    requests.get(`utility/getCategories`),
  FundWallet: (data) =>
    requests.post(`utility/fundBonusWallet`, data),
  chargeableAmountForTransaction: (docType, type) =>
    requests.get(`utility/chargeableAmountForTransaction/${docType}/${type}`),
  chargeableAmountPerLineItem: (docType, type, itemNo) =>
    requests.get(`utility/chargeableAmountPerLineItem/${docType}/${type}/${itemNo}`),
  getAllManufacturer: (page, limit, search) =>
    requests.get(`utility/getManufacturers?page=${page}&limit=${limit}&search=${search}`),
  getReorderProducts: () =>
    requests.get(`utility/product-re-order`),
  getAllBanks: () =>
    requests.get('utility/get-banks'),
  getAllBanks1: () =>
    requests.get('utility/get-banks-1'),
  Participantstatistics: () =>
    requests.get(`utility/admin/participantsStatistics`),
}
const CreditLimit = {
  save: (data) =>
    requests.post('creditLimit', data),
  edit: (id, data) =>
    requests.put(`creditLimit/${id}`, data),
  getAll: (page, limit, search) =>
    requests.get(`creditLimit?page=${page}&limit=${limit}&search=${search}`),
  view: (id) =>
    requests.get(`creditLimit/${id}`),
  delete: (id) =>
    requests.del(`creditLimit/${id}`),
  approve: (id) =>
    requests.patch(`creditLimit/approve/${id}`)
}

const Conversion = {
  getAll: (page, limit, search, locationId) =>
    requests.get(`packunit-conversion?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  getProductPack: (id) =>
    requests.get(`packunit-conversion/${id}`),
  save: (data) =>
    requests.post(`packunit-conversion`, data)
}
const Productprice = {
  save: (data) =>
    requests.post('productPrice', data),
  load: (page, limit, search) =>
    requests.get(`productPrice/?page=${page}&limit=${limit}&search=${search}`),
  edit: (id, data) =>
    requests.put(`productPrice/${id}`, data),
  view: (id) =>
    requests.get(`productPrice/${id}`),
  approve: (id) =>
    requests.patch(`productPrice/approve/${id}`),
  delete: (id) =>
    requests.del(`productPrice/${id}`),
  getStockBalance: (page, limit, search) =>
    requests.get(`productPrice/stock/balance?page=${page}&limit=${limit}&search=${search}`),
}

const Notification = {
  load: () =>
    requests.get(`notification/?page=${1}&limit=${100}&search=`),
  update: (id) =>
    requests.put(`notification/${id}`),
  loadNotes: (page, limit, search) =>
    requests.get(`notification/byaccount/all?page=${page}&limit=${limit}&search=${search}`),
  ViewNotes: (id) =>
    requests.get(`notification/${id}`),
  ClearAll: () =>
    requests.del(`notification/me/clearall`),
  Remove: (id) =>
    requests.del(`notification/${id}`)

}

const Haulage = {
  save: (data) =>
    requests.post('haulage', data),
  load: (page, limit, search, locationId) =>
    requests.get(`haulage/?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  loadRequestForHauler: (page, limit, search) =>
    requests.get(`haulage/?page=${page}&limit=${limit}&search=${search}`),
  edit: (id, data) =>
    requests.put(`haulage/${id}`, data),
  view: (id) =>
    requests.get(`haulage/${id}`),
  loadHaulerPartners: (page, limit, search, id) =>
    requests.post(`haulage/partner/?page=${page}&limit=${limit}&search=${search}`, id),
  loadIssueNote: (page, limit, search, type) =>
    requests.get(`haulage/getall/issue/${type}/?page=${page}&limit=${limit}&search=${search}`),
  approve: (id, validity) =>
    requests.patch(`haulage/${id}/${validity}`),
  delete: (id) =>
    requests.del(`haulage/${id}`),
  cancel: (id, reason) =>
    requests.patch(`haulage/cancel/${id}/${reason}`),
  saveHaulerBill: (data) =>
    requests.post('haulerbill', data),
  loadHaulerBill: (page, limit, search, locationId) =>
    requests.get(`haulerbill/?page=${page}&limit=${limit}&search=${search}&locationId=${locationId}`),
  viewHaulageBill: (id) =>
    requests.get(`haulerbill/${id}`),
  approveHaulageBill: (id) =>
    requests.patch(`haulerbill/${id}`),
  deleteHaulageBill: (id) =>
    requests.del(`haulerbill/${id}`),
  cancelHaulerBill: (id, reason) =>
    requests.patch(`haulerbill/cancel/${id}/${reason}`),
  acceptHaulerBill: (id, body) =>
    requests.patch(`haulerbill/accept/${id}`, body),
  loadConfHauler: (page, limit, search) =>
    requests.get(`conference/haulers/live?page=${page}&limit=${limit}&search=${search}`),
  canViewQRCode: (issueNo) =>
    requests.get(`haulage/canViewQRCode/${issueNo}`),
  getPartPaid: (page, limit, search) =>
    requests.get(`haulerbill/partlypaid/payment?page=${page}&limit=${limit}&search=${search}`)
}
const Wallet = {
  getWallet: () =>
    requests.get("wallet/balance"),
  loadTransaction: (page, limit, search, from, To) =>
    requests.get(`walletTransaction?page=${page}&limit=${limit}&search=${search}&fromDate=${from || ""}&toDate=${To || ""}`),
  viewTransaction: (id) =>
    requests.get(`walletTransaction/${id}`),
  verifyPayment: (referenceCode, amount, menuFrom) =>
    requests.get(`wallet/verifyPayment/${referenceCode}/${amount}/${menuFrom}`),
  getPrevConferenceTrans: (businessId, conferenceId) =>
    requests.get(`WalletTransaction/getPrevConferenceTrans/${businessId}/${conferenceId}`),
  payment: (data) =>
    requests.post(`wallet/coral-pay/invoke-payment`, data),
  payForLicence: () => 
    requests.get(`wallet/license-payment/cordination`),
  transfer: (data) =>
    requests.post(`wallet/transfer`, data),
  validateOtp: (otp) =>
    requests.post(`wallet/validate-otp/${otp}`),
  getPendingPayments: (page, limit, search) =>
    requests.get(`wallet/webhook/not-processed?page=${page}&limit=${limit}&search=${search}`),
  proccessPayment: (reference_code) =>
    requests.put(`wallet/process-webhook/${reference_code}`),
  getWalletTransactions: (page, limit, search) =>
    requests.get(`walletTransaction/report?page=${page}&limit=${limit}&search=${search}`),
}

const GenerateInvoiceOtp = {
  getOtp: (data) => requests.post('payment/generate/otp', data)
}

const BusinessSetting = {
  save: (data) =>
    requests.post('businessSetting', data),
  saveGlobal: (data) =>
    requests.post('businessSetting/global', data),
  get: () =>
    requests.get(`businessSetting/bybusiness`),
  getId: (id) =>
    requests.get(`businessSetting/bybusiness/${id}`),
  getByIdGlobal: () =>
    requests.get(`businessSetting/global/setting`),
}

const ProductFunctionalCategory = {
  save: (data) =>
    requests.post('productFunctionalCategory', data),
  edit: (id, data) =>
    requests.put(`productFunctionalCategory/${id}`, data),
  getAll: (page, limit, search) =>
    requests.get(`productFunctionalCategory?search=${search}&page=${page}&limit=${limit}`),
  delete: (id) =>
    requests.del(`productFunctionalCategory/${id}`),
  getById: (id) =>
    requests.get(`productFunctionalCategory/${id}`),
}
const ProductPack = {
  save: (data) =>
    requests.post('productPack', data),
  edit: (id, data) =>
    requests.put(`productPack/${id}`, data),
  view: (id) =>
    requests.get(`productPack/${id}`),
  load: (page, limit, search) =>
    requests.get(`productPack/?page=${page}&limit=${limit}&search=${search}`),
  delete: (id) =>
    requests.del(`productPack/${id}`),
  getParentMeasure: (id) =>
    requests.get(`productPack/byProductId/${id}`)
}
const BillRate = {
  save: (data) =>
    requests.post('billRate', data),
  load: (page, limit, search) =>
    requests.get(`billRate/?page=${page}&limit=${limit}&search=${search}`),
  edit: (id, data) =>
    requests.put(`billRate/${id}`, data),
  view: (id) =>
    requests.get(`billRate/${id}`),
  delete: (id) =>
    requests.del(`billRate/${id}`),
  loadBusinessTypes: (page, limit, search) =>
    requests.get(`billRate/participant/?page=${page}&limit=${limit}&search=${search}`),
  loadTransactionTypes: (page, limit, search) =>
    requests.get(`billRate/transactiontypes/fetch/?page=${page}&limit=${limit}&search=${search}`),
  loadBusiness: (page, limit, search) =>
    requests.get(`billRate/all/business/?page=${page}&limit=${limit}&search=${search}`),
  getGeneralTransactionBill: (transactiontypename, businesstypename, businessId, statusType) =>
    requests.get(`billRate/billrate/transaction/${transactiontypename}/${businesstypename}/${businessId}/${statusType}`),
  getTransactionBill: (transactiontypename, businesstypeName, businessId, statusType) =>
    requests.get(`billRate/transaction/${transactiontypename}/${businesstypeName}/${businessId}/${statusType}`),
}
const ProductBroadCast = {
  saveProReq: (data) =>
    requests.post(`productRequest`, data),
  getProReq: (page, limit, search) =>
    requests.get(`productRequest?page=${page}&limit=${limit}&search=${search}`),
  delProReq: (id) =>
    requests.del(`productRequest/${id}`),
  saveProOffload: (data) =>
    requests.post(`productOffload`, data),
  getProOffload: (page, limit, search) =>
    requests.get(`productOffload?page=${page}&limit=${limit}&search=${search}`),
  delOffload: (id) =>
    requests.del(`productOffload/${id}`),
  stopRequest: (id) =>
    requests.put(`productRequest/stopRequest/${id}`),
  stopOffload: (id) =>
    requests.put(`productOffload/stopOffload/${id}`)

}
const Region = {
  save: (data) =>
    requests.post('region', data),
  load: (page, limit, search) =>
    requests.get(`region?page=${page}&limit=${limit}&search=${search}`),
  edit: (id, data) =>
    requests.put(`region/${id}`, data),
  delete: (id) =>
    requests.del(`region/${id}`),

}
const Conference = {
  save: (data) =>
    requests.post(`conference`, data),
  load: (page, limit, search) =>
    requests.get(`conference?page=${page}&limit=${limit}&search=${search}`),
  view: (id) =>
    requests.get(`conference/${id}`),
  viewSingle: (id, search) =>
    requests.get(`conference/single/${id}/${search}`),
  delete: (id) =>
    requests.del(`conference/${id}`),
  edit: (id, data) =>
    requests.put(`conference/${id}`, data),
  loadExhibitors: (page, limit, search) =>
    requests.get(`conference/live/exhibitors?page=${page}&limit=${limit}&search=${search}`)
}
const ConferenceExhibitor = {
  save: (data) =>
    requests.post(`conferenceExhibitor`, data),
  load: (page, limit, search) =>
    requests.get(`conferenceExhibitor?page=${page}&limit=${limit}&search=${search}`),
  view: (id) =>
    requests.get(`conferenceExhibitor/${id}`),
  delete: (id) =>
    requests.del(`conferenceExhibitor/${id}`),
  edit: (id, data) =>
    requests.put(`conferenceExhibitor/${id}`, data),
  isExhibitor: (businessId) =>
    requests.get(`conferenceExhibitor/isExhibitor/${businessId}`)
}
const Currency = {
  save: (data) =>
    requests.post(`currency`, data),
  load: (page, limit, search) =>
    requests.get(`currency?page=${page}&limit=${limit}&search=${search}`),
  view: (id) =>
    requests.get(`currency/${id}`),
  delete: (id) =>
    requests.del(`currency/${id}`),
  edit: (id, data) =>
    requests.put(`currency/${id}`, data)

}
const ConferenceProduct = {
  save: (data) =>
    requests.post(`conferenceProduct`, data),
  load: (page, limit, search, conferenceId) =>
    requests.get(`conferenceProduct?page=${page}&limit=${limit}&search=${search}&conferenceId=${conferenceId}`),
  getById: (id) =>
    requests.get(`conferenceProduct/${id}`),
}

const Advert = {
  save: (data) =>
    requests.post(`advert`, data),
  load: (page, limit, search) =>
    requests.get(`advert?page=${page}&limit=${limit}&search=${search}`),
  view: (id) =>
    requests.get(`advert/${id}`)
}

const EmailNotificationPreferences = {
  getPreferenceById: (id) =>
    requests.get(`email-notification-preference/${id}`),
  getPreferenceByEmail: (email) =>
    requests.get(`email-notification-preference/email/${email}`),
  setPreference: (data) =>
    requests.put(`email-notification-preference/update`, data),
  unsubscribe: (data) =>
    requests.post(`businessSetting/unsubscribe`, data)
}

const Report = {
  getAggregatedReport: (page, limit, search, documentTypeId, fromDate, toDate, type) =>
    requests.get(`reports/document/aggregated?page=${page}&limit=${limit}&search=${search}&documentTypeId=${documentTypeId}&fromDate=${fromDate}&toDate=${toDate}&type=${type}`),
  getAdminAggregatedReport: (page, limit, search, documentTypeId, fromDate, toDate, type) =>
    requests.get(`reports/admin/document/aggregated?page=${page}&limit=${limit}&search=${search}&documentTypeId=${documentTypeId}&fromDate=${fromDate}&toDate=${toDate}&type=${type}`),
  getAdminAggregatedSuppliersById: (id, documentTypeId) =>
    requests.get(`reports/admin/haulerSupplier/${id}/${documentTypeId}`),
}

const Withdrawal = {
  loadWithdrawals: (page, limit, status, search) =>
    requests.get(`withdrawal/me/?page=${page}&limit=${limit}&status=${status}&search=${search}`),
  loadWithdrawalsAdmin: (page, limit, status, search) =>
    requests.get(`withdrawal/?page=${page}&limit=${limit}&status=${status}&search=${search}`),
  delete: (id) =>
    requests.del(`withdrawal/${id}`),
  approve: (id, data) =>
    requests.put(`withdrawal/approve/${id}`, data),
  save: (data) =>
    requests.post(`withdrawal/request`, data),
  edit: (id, data) =>
    requests.put(`withdrawal/${id}`, data),
  view: (id, data) =>
    requests.get(`withdrawal/${id}`, data),
  approve: (id, data) =>
    requests.put(`withdrawal/approve/${id}`, data),
  getOtpForWithdrawal: (amount, bankAccountId) =>
    requests.get(`withdrawal/initiate/${amount}/${bankAccountId}`)
}

const WithdrawlChargeSetup = {
  save: (data) =>
    requests.post(`withdrawal-charge-setup`, data),
  load: (page, limit, search) =>
    requests.get(`withdrawal-charge-setup?page=${page}&limit=${limit}&search=${search}`),
  view: (id) =>
    requests.get(`withdrawal-charge-setup/${id}`),
  delete: (id) =>
    requests.del(`withdrawal-charge-setup/${id}`),
  edit: (id, data) =>
    requests.put(`withdrawal-charge-setup/${id}`, data),
}

const LicensePaymentCharge = {
  save: (data) =>
    requests.post(`license-charge-setup`, data),
  load: (page, limit, search) =>
    requests.get(`license-charge-setup?page=${page}&limit=${limit}&search=${search}`),
  view: (id) =>
    requests.get(`license-charge-setup/${id}`),
  delete: (id) =>
    requests.del(`license-charge-setup/${id}`),
  edit: (id, data) =>
    requests.put(`license-charge-setup/${id}`, data),
  getByParticipant: (id) =>
    requests.get(`license-charge-setup/participant/${id}`),
}

const Roles = {
  save: (data) =>
    requests.post(`roles`, data),
  load: (page, limit, search) =>
    requests.get(`roles/allRoles?page=${page}&limit=${limit}&search=${search}`),
  loadRoles: (data) =>
    requests.get(`roles/allRoles`),
  getRoles: (data) =>
    requests.get(`roles`),
  view: (id) =>
    requests.get(`roles/findOne/${id}`),
  delete: (id) =>
    requests.del(`roles/${id}`),
  edit: (data) =>
    requests.patch(`roles/update_permission`, data),
  editRoleItem: (id, data) =>
    requests.put(`roles/${id}`, data),
  updated: (data) =>
    requests.get(`roles/permission/updated`)

}

const Modules = {
  load: (page, limit, search) =>
    requests.get(`modules?page=${page}&limit=${limit}&search=${search}`)
}
const api = {
  Auth,
  Country,
  State,
  User,
  Business,
  BusinessDocumentSetting,
  BusinessLocation,
  Product,
  Partnership,
  ProductGeneralisation,
  StockBalance,
  BalanceAdjustment,
  productIndustry,
  QuotationNote,
  BusinessProduct,
  Ordering,
  QuotationResponse,
  CustomerBalance,
  CreateCountry,
  Payment,
  Charge,
  IssueNote,
  FinancialLedger,
  ReceiptNote,
  Invoice,
  DeliveryNote,
  Utility,
  setToken: _token => { token = _token; },
  Discount,
  Retailshop,
  CreditLimit,
  Conversion,
  Productprice,
  Notification,
  Haulage,
  Wallet,
  BusinessSetting,
  ProductFunctionalCategory,
  ProductPack,
  BillRate,
  ProductBroadCast,
  Region,
  Conference,
  ConferenceExhibitor,
  Currency,
  ConferenceProduct,
  Advert,
  EmailNotificationPreferences,
  Report,
  Withdrawal,
  GenerateInvoiceOtp,
  WithdrawlChargeSetup,
  LicensePaymentCharge,
  Roles,
  Modules

}

export default api;
