import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router";
import api from "../utils/api";
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import Footer from '../components/footer';
import ThemeSetting from '../components/themeSetting';


const CanSeeRoute = ({ showHead = true, children, ...rest }) => {
   

  if (api.Auth.isAuth()) {
      
        return (
          <div className="container-scroller">
              <Header />

            <div className="container-fluid page-body-wrapper ">
              <ThemeSetting />
              <Sidebar />
            <Route {...rest}>{children}</Route>
            </div>
              <Footer />
          </div>
        
        );
    
  } else {
      localStorage.setItem("lastAccessedUrl", window.location.pathname);
      return <Redirect to={{ pathname: "/signin" }} />;
  }
};

export default CanSeeRoute;
