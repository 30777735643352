import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, Link } from "react-router-dom";
import api from "../../../utils/api";
import { notifySuccess } from "../../../utils/toast";



export default function CreateAdvert() {
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        mode: "onChange",
        reValidateMode: 'onChange'
    });

    const history = useHistory();
    const [image, setImage] = useState("");
    const [loading, setLoading] = useState(false);
    const [itemToEdit, setItemToEdit] = useState({
        email: "",
        image: "",
        contactPerson: "",
        contactPhone: "",
        url: ""  
    })


    const handleOnChange = (e) => {
        const { name, value } = e.target
        setItemToEdit({
            ...itemToEdit,
            [name]: value ?? JSON.parse(value),
        })
    }

    const handleImageChange = (e) => {
        if (e.target.files.length) {
            
            setImage(e.target.files[0])
        }
    };
    
    const saveAdvert = async (itemToEdit) => {
        try {
            
            setLoading(true)

            const formData = new FormData()
            formData.append('image', image);
            formData.append('url', itemToEdit.url);
            formData.append('contactPerson', itemToEdit.contactPerson);
            formData.append('contactPhone', itemToEdit.contactPhone);
            formData.append('email', itemToEdit.email);
               
            const response = await api.Advert.save(formData);
            notifySuccess(response.sucessMessage)
            history.push("/admin/listadvert")
            setLoading(false)
        }
        catch (error) {
            console.log(error);
            setLoading(false)
        }
    }

    return <>
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="card">
                    <div className="card-body">
                        <div className="text-center">
                            <span className="card-title">CREATE ADVERT</span>
                            <div className="d-flex float-right mt-2">
                                <div className="d-flex float-right mt-2">
                                    <Link to={"/admin/listadvert"}>
                                        <button class="btn btn-dark btn-sm btn-icon-text text-white d-flex mx-1">
                                            <i className="ti-arrow-left mr-1" title="Back"></i>
                                            <span className="d-none d-md-block">BACK TO LIST</span>
                                        </button>
                                    </Link>
                                    <button onClick={handleSubmit(saveAdvert)} type="submit" class="btn btn-primary btn-sm btn-icon-text d-flex mx-1" disabled={loading ? true : false}>
                                        <i className="ti-save mr-1" title="Submit"></i>
                                        <span className="d-none d-md-block">{loading ? "SAVING" : "SAVE"}</span>
                                    </button>
                                </div>
                       
                            </div>
                        </div>
                       


                        <form key={1} className="row product mt-5" action="">

                        <div className="form-group col-md-3">
                                <label htmlFor="image">Image (<sapn>gif, jpg, jpeg</sapn>)</label>
                                {errors.image && (
                                    <span className="text-danger">required</span>
                                )}
                                <div className="custom-file">
                                    <input
                                        type="file"
                                        className="form-control"
                                        id="image"
                                        name="image"
                                        placeholder="Image"
                                        {...register("image", {
                                            required: true,
                                            onChange: (e) => { handleImageChange(e) }
                                        })}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-md-3">
                                <label htmlFor="email">Business Code </label>
                                {errors.businessCode && (
                                    <span className="text-danger"> required</span>
                                )}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="businessCode"
                                    name="businessCode"
                                    placeholder="businessCode"
                                    {...register("businessCode", {
                                        required: false,
                                        onChange: (e) => { handleOnChange(e) }
                                    })}
                                />
                            </div>
                           
                            <div className="form-group col-md-3">
                                <label htmlFor="email">Email </label>
                                {errors.email && (
                                    <span className="text-danger"> required</span>
                                )}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="email"
                                    name="email"
                                    placeholder="email"
                                    {...register("email", {
                                        required: false,
                                        onChange: (e) => { handleOnChange(e) }
                                    })}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="url">URL </label>
                                {errors.url && (
                                    <span className="text-danger"> required</span>
                                )}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="url"
                                    name="url"
                                    placeholder="url"
                                    {...register("url", {
                                        required: false,
                                        onChange: (e) => { handleOnChange(e) }
                                    })}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="contactPerson">
                                    Contact Person 
                                </label>
                                {errors.specdetails && (
                                    <span className="text-danger"> required</span>
                                )}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contactPerson"
                                    name="contactPerson"
                                    placeholder="Contact Person"
                                    {...register("contactPerson", {
                                        required: false,
                                        onChange: (e) => { handleOnChange(e) }
                                    })}
                                />
                            </div>
                            
                            <div className="form-group col-md-3">
                                <label htmlFor="contactPhone">
                                    Contact Phone 
                                </label>
                                {errors.contactPhone && (
                                    <span className="text-danger"> required</span>
                                )}
                                <input
                                    type="text"
                                    className="form-control"
                                    id="contactPhone"
                                    name="contactPhone"
                                    placeholder="Contact Phone"
                                    {...register("contactPhone", {
                                        required: false,
                                        onChange: (e) => { handleOnChange(e) }
                                    })}
                                />
                            </div>
                            
                        </form>
                    </div>
                </div>

            </div>
        </div>


    </>;
}
