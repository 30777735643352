export const NotificationType = {
    QUOTE: "quote",
    ORDER: "order",
    RECIEPT: "reciept",
    INVOICE: "invoice",
    PAYMENT: "payment",
    ISSUE: "issue",
    QUOTERESPONSE: "quoteresponse",
    WALLETREFUND: "walletrefund",
    SETPRICE: "setprice",
    REORDERLEVEL: "reorderlevel",
    SETBILLRATE: "setbillrate",
    HAULAGEREQUEST: "haulerrequest",
    HAULERRESPONSE: "haulerbill",
    DELIVERYNOTE: "deliverynote",
    WITHDRAWALREQUEST: "withdrawalrequest"
}

export const DocumentType = {
    QUOTE: "Request for Quote",
    QUOTERESPONSE: "Proforma Invoice",
    CREDITNOTE: "Credit Note",
    INVOICENOTE: "Invoice Note",
    ORDERNOTE: "Order Note",
    STOCKADJ: "Stock Adjustment",
    HAULERREQUEST: "Hauler Request",
    RECIEPTNOTE: "Receipt Note",
    PAYMENTDETAILS: "Payment Details",
    HAULERRESPONSE: "Hauler Response",
    ISSUENOTE: "Issue Note",
    CREDITFACILITY: "Credit Facility",
    PRICEADJ: "Price Adjustment",
    DELIVERYNOTE: "Delivery Note",
}

export const BillStatusType = {
    APPROVE: "approve",
    CREATE: "create"
}
