import React, { useEffect } from 'react';
import { notifyError } from "../../utils/toast";
import api from "../../utils/api"
import { onLogin } from '../../utils/onLogin';
import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2';
import { useState } from 'react';





const UnsubscribeEmail = (props) => {

    const history = useHistory();
    const [loading, setloading] = useState(false)

    const businessId = props.match.params.businessId;
    const subscriptionType = props.match.params.subscriptionType;

    const getUnsubscribe = async () => {
        try {
            Swal.fire({
                title: `We're sorry to bother you`,
                text: `Please click "Comfirm" to stop receiving email from P2P `,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#4B49AC',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Comfirm'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    try {
                        const payload = {
                            businessId: businessId,
                            subscriptionType: subscriptionType
                        }
                        const r = await api.EmailNotificationPreferences.unsubscribe(payload);
                        history.push("/")
                    } catch (error) {
                        console.log(error);
                        history.push("/")
                    }
                } else {
                    history.push("/")
                    setloading(false)
                }
            })
        }
        catch (error) {
            console.log(error)
            setloading(false)
        }
    }
    useEffect(() => {
        if (!businessId && !subscriptionType) {
            return notifyError("Unable to Unsubscribe");
        };
        getUnsubscribe()

    })


    return (
        <>
            <div>
                Please wait...
            </div>
        </>
    );
}

export default UnsubscribeEmail;