import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Backbutton from "../../../components/backbutton"
import api from "../../../utils/api";

export default function ViewAdvert() {
    const { id } = useParams();

    const [advert, setAdvert] = useState({});

    useEffect(() => {
        const loadAdvert = async () => {
            const response = await api.Advert.view(id);
            setAdvert(response.data);
        };
        loadAdvert();
    }, []);
    return <>
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="card">
                    <div className="card-body">
                        <div className="text-center mb-5">
                            <span className="card-title text-center">VIEW ADVERT</span>
                        </div>
                        <div className="row float-right form-group">
                            <Backbutton ></Backbutton>
                        </div>
                        <div className="row">
                            <div className="col-md-4 mt-3">
                                <img src={advert?.image} alt="image" style={{ float: 'none', height: "300px", width: "100%" }} className="wrap-words" />
                            </div>
                            <div className="col-md-8">
                                <div className="row mt-3">
                                    <div className="col-md-4 mb-3">
                                        <label className="font-weight-bold">Contact Person:</label>&nbsp;
                                        <span className="text-uppercase">{advert?.contactPerson}</span>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label className="font-weight-bold">Contact Phone:</label>&nbsp;
                                        <span className="text-uppercase">{advert?.contactPhone}</span>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label className="font-weight-bold">URL:</label>&nbsp;
                                        <span className="text-uppercase">{advert?.url}</span>
                                    </div>
                                    <div className="col-md-4 mb-3">
                                        <label className="font-weight-bold">Email:</label>&nbsp;
                                        <span className="text-uppercase">{advert?.email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
