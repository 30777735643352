import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { AllowAdminOnly } from "./components/AllowAdminOnly";
import { AllowUserOnly } from "./components/AllowUserOnly";
import {
  AllowPriAdj,
  AllowProformaInv,
  AllowReqForQuote,
  AllowBusinessSetup,
  AllowAddProduct,
  AllowPriceAdj,
  AllowTransaction,
  AllowTransact,
  AllowTrans,
  IsManu,
} from "./components/AllowSpecificUser";
import AnonymousRoute from "./components/AnonymousRoute";
import ProtectedRoute from "./components/protectedroute";
import documentation from "./pages/support/documentation";
import CanSeeRoute from "./components/canSeeRoute";
import Exhibitionpayment from "./pages/conference/exhibitionpayment";
import CreateAdvert from "./pages/admin/advert/create";
import ViewAdvert from "./pages/admin/advert/view";
import ListAdvert from "./pages/admin/advert/list";
import UnsubscribeEmail from "./pages/signIn/unsubscribe";
import FavouriteSupplier from "./pages/favourites/favouritesupplier";
import FavouriteHauler from "./pages/favourites/favouritehauler";
import Createbankdetail from "./pages/bankdetail/createbankdetail";
import Viewwithdrawaldetail from "./pages/wallet/viewwithdrawaldetail";
import Pendingpayment from "./pages/pendingpayment/pendingpayment";
import walletTransactions from "./pages/wallettransactions/walletTransactions";
import { Roles, roles } from "./pages/role/roles";
import { EditRole } from "./pages/role/editrole";
import { RoleAccess } from "./pages/roleaccess/roleaccess";
import PermissionProtectedRoute from "./components/permissionProtectedRoute";
// import AssignedBusiness from "./pages/salesrep/assignedbusiness";

const OnlinePayments = React.lazy(() => import("./pages/payments/onlinepayments"));
const ViewHauler = React.lazy(() => import("./pages/admin/hauler/viewhauler"));
const VerifyHauler = React.lazy(() => import("./pages/admin/hauler/verifyhauler"));
const CreateLicencePaymentCharge = React.lazy(() => import("./pages/admin/licence/createlicencepaymentcharge"));
const EditLicencePaymentCharge = React.lazy(() => import("./pages/admin/licence/editlicencepaymentcharge"));
const ListLicencePaymentCharge = React.lazy(() => import("./pages/admin/licence/listlicencepaymentcharge"));
const ViewLicencePaymentCharge = React.lazy(() => import("./pages/admin/licence/viewlicencepaymentcharge"));
const CreateWithdrawalCharge = React.lazy(() => import("./pages/admin/withdrawal/createwithdrawalcharge"));
const EditWithdrawalCharge = React.lazy(() => import("./pages/admin/withdrawal/editwithdrawalcharge"));
const ListWithdrawalCharge = React.lazy(() => import("./pages/admin/withdrawal/listwithdrawalcharge"));
const ViewWithdrawalCharge = React.lazy(() => import("./pages/admin/withdrawal/viewwithdrawalcharge"));
const EditRegion = React.lazy(() => import("./pages/country/region/editregion"));
const ListRegion = React.lazy(() => import("./pages/country/region/listregion"));
const CreateRegion = React.lazy(() => import("./pages/country/region/createregion"));
const ViewLocation = React.lazy(() => import("./pages/admin/business/viewlocation"));
const ViewNofication = React.lazy(() => import("./pages/notification/viewnotification"));
const HeaderNofications = React.lazy(() => import("./pages/notification/headernotification"));
const FundWallet = React.lazy(() => import("./pages/admin/wallet/fundwallet"));
const ViewPaymentType = React.lazy(() => import("./pages/admin/payment/viewpaymenttype"));
const ListPaymentype = React.lazy(() => import("./pages/admin/payment/listpaymenttype"));
const CreatePaymentType = React.lazy(() => import("./pages/admin/payment/createpaymenttype"));
const ViewDocumentType = React.lazy(() => import("./pages/admin/document/viewdocumenttype"));
const Listdocument = React.lazy(() => import("./pages/admin/document/listdocument"));
const Createdocument = React.lazy(() => import("./pages/admin/document/createdocumenttype"));
const ViewParticipantType = React.lazy(() => import("./pages/admin/participant/viewparticipanttype"));
const ListParticipantype = React.lazy(() => import("./pages/admin/participant/listparticipanttype"));
const CreateParticipanttype = React.lazy(() => import("./pages/admin/participant/createparticipanttype"));
const BusinessList = React.lazy(() => import("./pages/admin/business/businesslist"));
const ViewBusiness = React.lazy(() => import("./pages/admin/business/viewbusiness"));
const AdminLocationList = React.lazy(() => import("./pages/admin/business/locationlist"));
const AdminDashboard = React.lazy(() => import("./pages/admin/dashboard"));
const AdminCreateCharge = React.lazy(() => import("./pages/admin/charge/createcharge"));
const AdminChargeList = React.lazy(() => import("./pages/admin/charge/chargelist"));
const AdminEditCharge = React.lazy(() => import("./pages/admin/charge/editcharge"));
const AdminViewCharge = React.lazy(() => import("./pages/admin/charge/viewcharge"));
const AdminCreatediscount = React.lazy(() => import("./pages/admin/discount/creatediscount"));
const AdminDiscountlist = React.lazy(() => import("./pages/admin/discount/discountlist"));
const AdminEditdiscount = React.lazy(() => import("./pages/admin/discount/editdiscount"));
const AdminViewdiscount = React.lazy(() => import("./pages/admin/discount/viewdiscount"));
const CreateExhibitor = React.lazy(() => import("./pages/admin/conferenceexhibitors/create"));
const EditExhibitor = React.lazy(() => import("./pages/admin/conferenceexhibitors/edit"));
const ViewExhibitor = React.lazy(() => import("./pages/admin/conferenceexhibitors/view"));
const ConferenceExhibitors = React.lazy(() => import("./pages/admin/conferenceexhibitors/list"));
const Conferences = React.lazy(() => import("./pages/conference/conferences"));
const ConferenceExhibitorsMain = React.lazy(() => import("./pages/conference/conferenceexhibitors"));

const CreateBankDetail = React.lazy(() => import("./pages/bankdetail/createbankdetail"));
const ListBankDetail = React.lazy(() => import("./pages/bankdetail/listbankdetail"));
const ViewBankDetail = React.lazy(() => import("./pages/bankdetail/viewbankdetail"));
const EditBankDetail = React.lazy(() => import("./pages/bankdetail/editbankdetail"));

const ViewWithdrawalDetail = React.lazy(() => import("./pages/wallet/viewwithdrawaldetail"));

const AssignedBusiness = React.lazy(() => import("./pages/salesrep/assignedbusiness"));

const Home = React.lazy(() => import("./pages/home/home"));
const AccountBalance = React.lazy(() =>
  import("./pages/accountbalance/getaccountbalance")
);
const Userlist = React.lazy(() => import("./pages/user/userlist"));
const Signin = React.lazy(() => import("./pages/signIn/signIn"));
const Dashboard = React.lazy(() => import("./pages/dashboard/dashboard"));
const CreatePayment = React.lazy(() =>
  import("./pages/payments/createpayment")
);
const Paymentsuccess = React.lazy(() =>
  import("./pages/payments/paymentsuccess")
);
const Placeorder = React.lazy(() => import("./pages/placeorder/placeorder"));
const Newquoterequest = React.lazy(() =>
  import("./pages/quote/newquoterequest")
);
const Viewquoterequest = React.lazy(() =>
  import("./pages/quote/viewquoterequest")
);
const Viewquote = React.lazy(() => import("./pages/quote/ViewQuote"));
const Quoterequestlist = React.lazy(() =>
  import("./pages/quote/quoterequestlist")
);
const Generateproformainvoice = React.lazy(() =>
  import("./pages/proformainvoice/generateproformainvoice")
);
const Changepassword = React.lazy(() =>
  import("./pages/signIn/changePassword")
);
const Resetpassword = React.lazy(() => import("./pages/signIn/resetPassword"));
const ForgetPassword = React.lazy(() =>
  import("./pages/signIn/forgetpassword")
);
const Invoice = React.lazy(() => import("./pages/invoice/invoice"));
const Signup = React.lazy(() => import("./pages/signup/signup"));
const Stockissue = React.lazy(() =>
  import("./pages/stockissuenote/generatestockissue")
);
const Generatestockissue = React.lazy(() =>
  import("./pages/stockissuenote/generatestockissue")
);
const Viewstockissue = React.lazy(() =>
  import("./pages/stockissuenote/viewstockissue")
);
const Haulagerequest = React.lazy(() =>
  import("./pages/haulage/haulagerequest")
);
const Viewhaulagerequest = React.lazy(() =>
  import("./pages/haulage/viewhaulagerequest")
);
const Newhaulagebill = React.lazy(() =>
  import("./pages/haulage/newhaulagebill")
);
const Pickuprequest = React.lazy(() =>
  import("./pages/pickuprequest/pickuprequest")
);
const Newreceiptnote = React.lazy(() =>
  import("./pages/stockreceiptNote/newReceiptNote")
);
const Viewreceiptnote = React.lazy(() =>
  import("./pages/stockreceiptNote/viewReceiptNote")
);
const Generatereceiptnote = React.lazy(() =>
  import("./pages/stockreceiptNote/generatereceiptnote")
);
const Newdeliverynote = React.lazy(() =>
  import("./pages/deliverynote/newdeliverynote")
);
const Createlocation = React.lazy(() =>
  import("./pages/location/createlocation")
);
const Editlocation = React.lazy(() => import("./pages/location/editlocation"));
const Locationlist = React.lazy(() => import("./pages/location/locationlist"));
const Viewlocation = React.lazy(() => import("./pages/location/viewlocation"));
const Partnership = React.lazy(() => import("./pages/partnership/partnership"));
const DocumentSetting = React.lazy(() =>
  import("./pages/settings/documentsetting")
);
const Createuser = React.lazy(() => import("./pages/user/createuser"));
//const AnonymousRoute = React.lazy(() => import("./components/AnonymousRoute"));
const userViewSingle = React.lazy(() => import("./pages/user/userViewSingle"));
const VerifyEmail = React.lazy(() => import("./pages/signIn/verify"));
const CreateProduct = React.lazy(() => import("./pages/product/createProduct"));
const EditUser = React.lazy(() => import("./pages/user/editUser"));
const ViewProduct = React.lazy(() => import("./pages/product/viewProduct"));
const ListProduct = React.lazy(() => import("./pages/product/listProduct"));
const CreateBalanceAdjustment = React.lazy(() =>
  import("./pages/balanceadjustment/createbalanceadjustment")
);
const CreateProductGeneralization = React.lazy(() =>
  import("./pages/productgeneralization/createproductgeneralization")
);
const EditProductGeneralization = React.lazy(() =>
  import("./pages/productgeneralization/editproductgeneralization")
);
const Listproductgeneralization = React.lazy(() =>
  import("./pages/productgeneralization/listproductgeneralization")
);
const ViewProductGeneralization = React.lazy(() =>
  import("./pages/productgeneralization/viewproductgeneralization")
);

const ViewBalanceAdjustment = React.lazy(() =>
  import("./pages/balanceadjustment/viewbalanceadjustment")
);
const BalanceAdjustmentList = React.lazy(() =>
  import("./pages/balanceadjustment/balanceadjustmentlist")
);
const Stockbalance = React.lazy(() => import("./pages/stock/stockbalance"));
const EditBalanceAdjustment = React.lazy(() =>
  import("./pages/balanceadjustment/editbalanceadjustment")
);
const Editprofile = React.lazy(() => import("./pages/profile/editprofile"));

const EditProduct = React.lazy(() => import("./pages/product/editProduct"));

const GeneralProduct = React.lazy(() =>
  import("./pages/myProduct/generalProduct")
);
const MyProductList = React.lazy(() =>
  import("./pages/myProduct/myProductList")
);
const ViewMyProduct = React.lazy(() =>
  import("./pages/myProduct/myProductView")
);
const Quoterequestdetail = React.lazy(() =>
  import("./pages/quote/quoterequestdetail")
);
const Quoteresponselist = React.lazy(() =>
  import("./pages/quoteresponse/quoteresponselist")
);
const Quoteresponsedetail = React.lazy(() =>
  import("./pages/quoteresponse/quoteresponsedetail")
);

const Createcreditnote = React.lazy(() =>
  import("./pages/creditnote/createcreditnote")
);
const Viewcreditnote = React.lazy(() =>
  import("./pages/creditnote/viewcreditnote")
);
const Editcreditnote = React.lazy(() =>
  import("./pages/creditnote/editcreditnote")
);
const Listcreditnote = React.lazy(() =>
  import("./pages/creditnote/listcreditnote")
);

const EditReceiptNote = React.lazy(() =>
  import("./pages/stockreceiptNote/editreceiptnote")
);
const ListReceiptNote = React.lazy(() =>
  import("./pages/stockreceiptNote/listreceiptnote")
);
const DeleteBalanceAdjustment = React.lazy(() =>
  import("./pages/balanceadjustment/deletebalanceadjustment")
);
const CreateCountry = React.lazy(() => import("./pages/country/createcountry"));
const ListCountries = React.lazy(() => import("./pages/country/listcountries"));
const EditCountry = React.lazy(() => import("./pages/country/editcountry"));
const ViewCountry = React.lazy(() => import("./pages/country/viewcountry"));
const PaymentList = React.lazy(() => import("./pages/payments/paymentlist"));
const PreviewOrder = React.lazy(() =>
  import("./pages/placeorder/previewOrder")
);
const Orderlist = React.lazy(() => import("./pages/placeorder/orderlist"));
const Orderdetail = React.lazy(() => import("./pages/placeorder/orderdetail"));
const Financialledgerlist = React.lazy(() =>
  import("./pages/financialledger/listfinancialledger")
);
const IssueList = React.lazy(() => import("./pages/stockissuenote/issuelist"));
const Creatediscount = React.lazy(() =>
  import("./pages/discount/creatediscount")
);
const Editdiscount = React.lazy(() => import("./pages/discount/editdiscount"));
const Discountlist = React.lazy(() => import("./pages/discount/discountlist"));
const Viewdiscount = React.lazy(() => import("./pages/discount/viewdiscount"));

const CreateCharge = React.lazy(() => import("./pages/charge/createcharge"));
const ListCharge = React.lazy(() => import("./pages/charge/chargelist"));
const EditCharge = React.lazy(() => import("./pages/charge/editcharge"));
const ViewCharge = React.lazy(() => import("./pages/charge/viewcharge"));
const EditPayment = React.lazy(() => import("./pages/payments/editpayment"));
const ViewPayment = React.lazy(() => import("./pages/payments/viewpayment"));
const Retailshop = React.lazy(() => import("./pages/retailshop/retailshop"));
const Previewretailshop = React.lazy(() =>
  import("./pages/retailshop/previewretailshop")
);
const Saleslist = React.lazy(() => import("./pages/retailshop/saleslist"));
const Saledetail = React.lazy(() => import("./pages/retailshop/saledetail"));
const Createcreditfacility = React.lazy(() =>
  import("./pages/creditfacility/createcreditfacility")
);
const Editcreditfacility = React.lazy(() =>
  import("./pages/creditfacility/editcreditfacility")
);
const Listcreditfacility = React.lazy(() =>
  import("./pages/creditfacility/listcreditfacility")
);
const Viewcreditfacility = React.lazy(() =>
  import("./pages/creditfacility/viewcreditfacility")
);

const InvoiceList = React.lazy(() => import("./pages/invoice/invoicelist"));

const ListDeliveryNote = React.lazy(() =>
  import("./pages/deliverynote/deliverynotelist")
);
const EditDeliveryNote = React.lazy(() =>
  import("./pages/deliverynote/editdeliverynote")
);
const ViewDeliveryNote = React.lazy(() =>
  import("./pages/deliverynote/viewdeliverynote")
);
const Productprice = React.lazy(() =>
  import("./pages/productprice/productprice")
);
const Productpricelist = React.lazy(() =>
  import("./pages/productprice/productpricelist")
);
const ViewProductPrice = React.lazy(() =>
  import("./pages/productprice/viewproductprice")
);
const Editproductprice = React.lazy(() =>
  import("./pages/productprice/editproductprice")
);

const Conversion = React.lazy(() =>
  import("./pages/productconversion/conversion")
);
const Wallet = React.lazy(() => import("./pages/wallet/wallet"));
const Viewtransaction = React.lazy(() =>
  import("./pages/wallet/viewtransaction")
);
const GlobalSetting = React.lazy(() =>
  import("./pages/settings/globalSetting")
);
const BusinessDocumentSetting = React.lazy(() =>
  import("./pages/settings/businessSetting")
);
const GlobalBusinessDocumentSetting = React.lazy(() =>
  import("./pages/settings/globalBusinessSetting")
);
const ActivateAccount = React.lazy(() => import("./pages/activateaccount/305"));
const AccessDenied = React.lazy(() => import("./pages/accessdenied/403"));
const PageNotFound = React.lazy(() => import("./pages/pagenotfound/404"));
const licenseExpire = React.lazy(() => import("./pages/license/licenseExpire"));

const CreateProductFunction = React.lazy(() =>
  import("./pages/productFunctionality/createproductfunction")
);
const ListProductFunction = React.lazy(() =>
  import("./pages/productFunctionality/productfunctionlist")
);
const EditProductFunction = React.lazy(() =>
  import("./pages/productFunctionality/editproductfunction")
);
const ViewProductFunction = React.lazy(() =>
  import("./pages/productFunctionality/viewproductfunction")
);

const CreateProductPack = React.lazy(() =>
  import("./pages/productpack/createproductpack")
);
const ListProductPack = React.lazy(() =>
  import("./pages/productpack/productpacklist")
);
const ViewProductPack = React.lazy(() =>
  import("./pages/productpack/viewproductpack")
);
const EditProductPack = React.lazy(() =>
  import("./pages/productpack/editproductpack")
);

const HaulageList = React.lazy(() => import("./pages/haulage/listhaulage"));
const CreateBillRate = React.lazy(() =>
  import("./pages/billrate/createbillrate")
);
const BillRateList = React.lazy(() => import("./pages/billrate/billratelist"));
const ViewBillRate = React.lazy(() => import("./pages/billrate/viewbillrate"));
const EditBillRate = React.lazy(() => import("./pages/billrate/editbillrate"));

const HaulerBillList = React.lazy(() => import("./pages/haulage/listhaulerbill"));
const ViewHaulageBill = React.lazy(() => import("./pages/haulage/viewhaulagebill"));


const EditUserProfile = React.lazy(() => import("./pages/profile/edituserprofile"));


const ProductRequest = React.lazy(() => import("./pages/productbroadcast/productrequest"));
const ProductOffload = React.lazy(() => import("./pages/productbroadcast/productoffload"));
const productBroadcastList = React.lazy(() => import("./pages/productbroadcast/productbroadcastlist"));

const ListConference = React.lazy(() => import("./pages/conference/conferencelist"));
const CreateConference = React.lazy(() => import("./pages/conference/createconference"));
const ViewConference = React.lazy(() => import("./pages/conference/viewconference"));
const EditConference = React.lazy(() => import("./pages/conference/editconference"));
const ExhibitionPayment = React.lazy(() => import("./pages/conference/exhibitionpayment"));

const PickUp = React.lazy(() => import("./pages/pickup/pickup"));
const Documentation = React.lazy(() => import("./pages/support/documentation"));

const Currency = React.lazy(() => import("./pages/currency/currency"));
const SetEmailPreferences = React.lazy(() => import("./pages/preferredemail/setemailpreferences"))
const ReOrderSettings = React.lazy(() => import("./pages/settings/reOrderSettings"))
const PaymentStatus = React.lazy(() => import("./pages/wallet/paymentstatus"))

const AggregatedReports = React.lazy(() => import("./pages/reports/aggregatedReports"))
const PendingPayment = React.lazy(() => import("./pages/pendingpayment/pendingpayment"))
const WalletTransaction = React.lazy(() => import("./pages/wallettransactions/walletTransactions"))
const AdminAggregatedReports = React.lazy(() => import("./pages/admin/report/adminAggregatedReport"))

export default function RouterPage(props) {
  return (
    <BrowserRouter>
      <Switch>
        <ProtectedRoute exact path="/dashboard" component={AllowUserOnly(Dashboard)} />
        <PermissionProtectedRoute exact path="/reorderlevel" itemName='Reorder Level'
          action='isVisible' component={AllowUserOnly(ReOrderSettings)} />
        <ProtectedRoute exact path="/documentation" component={AllowUserOnly(Documentation)}
        />
        <PermissionProtectedRoute
          exact
          path="/createpayments"
          itemName='Payment Note'
          action='canCreate'
          component={AllowTransaction(CreatePayment)}
        />
        <ProtectedRoute
          exact
          path="/paymentsuccess"
          component={AllowReqForQuote(Paymentsuccess)}
        />
        <PermissionProtectedRoute
          exact
          path="/placeorder"
          itemName='Order'
          action='canCreate'
          component={AllowTransaction(Placeorder)}
        />
        <PermissionProtectedRoute
          exact
          path="/newquoterequest"
          itemName='Quote Request'
          action='canCreate'
          component={AllowReqForQuote(Newquoterequest)}
        />
        <ProtectedRoute exact path="/viewquote" component={Viewquote} />
        <PermissionProtectedRoute
          exact
          path="/viewquoterequest"
          itemName='Quote Request'
          action='canEdit'
          component={AllowUserOnly(Viewquoterequest)}
        />
        <PermissionProtectedRoute
          exact
          path="/quoterequestlist"
          itemName='Quote Request'
          action='isVisible'
          component={AllowUserOnly(Quoterequestlist)}
        />
        <Route exact path="/signin" component={(Signin)} />
        <Route exact path="/payment-status" component={(PaymentStatus)} />
        <Route exact path="/" component={(Home)} />

        <Route exact path="/reset-password/:token" component={Resetpassword} />
        <Route exact path="/forgot-password" component={ForgetPassword} />
        <Route path="/verify/:token" exact component={VerifyEmail} />
        <Route path="/unsubscribe/:businessId/:subscriptionType" exact component={UnsubscribeEmail} />
        <ProtectedRoute
          exact
          path="/changepassword"
          component={Changepassword}
        />
        <Route exact path="/signup" component={Signup} />

        <PermissionProtectedRoute
          exact
          path="/invoice/:id"
          itemName='Invoice Note'
          action='canView'
          component={Invoice}
        />
        <PermissionProtectedRoute
          exact
          path="/generatestockissue"
          itemName='Stock Issue Note'
          action='canCreate'
          component={AllowTransact(Generatestockissue)}
        />
        <PermissionProtectedRoute
          exact
          path="/viewstockissue/:id"
          itemName='Stock Issue Note'
          action='canView'
          component={Viewstockissue}
        />
        <PermissionProtectedRoute
          exact
          path="/haulagerequest"
          itemName='Hauler Request'
          action='canCreate'
          component={AllowUserOnly(Haulagerequest)}
        />
        <PermissionProtectedRoute
          exact
          path="/viewhaulagerequest/:id"
          itemName='Hauler Request'
          action='canView'
          component={AllowUserOnly(Viewhaulagerequest)}
        />
        <PermissionProtectedRoute
          exact
          path="/newhaulagebill/:id"
          itemName='Receipt Note'
          action='canCreate'
          component={AllowUserOnly(Newhaulagebill)}
        />
        <ProtectedRoute exact path="/pickuprequest" component={Pickuprequest} />
        <PermissionProtectedRoute
          exact
          path="/newreceiptnote"
          itemName='Receipt Note'
          action='canCreate'
          component={AllowTransaction(Newreceiptnote)}
        />
        <PermissionProtectedRoute
          exact
          path="/viewreceiptnote/:id"
          itemName='Receipt Note'
          action='canView'
          component={AllowReqForQuote(Viewreceiptnote)}
        />
        <PermissionProtectedRoute
          exact
          path="/generatereceiptnote"
          itemName='Receipt Note'
          action='canCreate'
          component={AllowReqForQuote(Generatereceiptnote)}
        />
        <PermissionProtectedRoute
          exact
          path="/newdeliverynote"
          itemName='Delivery Note'
          action='canCreate'
          component={AllowUserOnly(Newdeliverynote)}
        />
        <PermissionProtectedRoute
          exact
          path="/createlocation"
          itemName='Location'
          action='canCreate'
          component={Createlocation}
        />
        <PermissionProtectedRoute exact path="/locationlist" itemName='Location' action='isVisible' component={Locationlist} />
        <PermissionProtectedRoute
          exact
          path="/partnership"
          itemName='Partnership'
          action='canView'
          component={Partnership}
        />
        <PermissionProtectedRoute
          exact
          path="/documentsetting"
          itemName='Document Setting'
          action='canView'
          component={DocumentSetting}
        />
        <PermissionProtectedRoute
          exact
          path="/createuser"
          itemName='User'
          action='canCreate'
          component={Createuser}
        />
        <PermissionProtectedRoute exact path="/userlist" itemName='User' action='isVisible' component={Userlist} />
        <ProtectedRoute
          exact
          path="/generateproformainvoice"
          component={AllowProformaInv(Generateproformainvoice)}
        />
        <PermissionProtectedRoute
          exact
          path="/editlocation/:id"
          itemName='Location'
          action='canEdit'
          component={Editlocation}
        />
        <PermissionProtectedRoute
          exact
          path="/viewlocation/:id"
          itemName='Location'
          action='canView'
          component={AllowUserOnly(Viewlocation)}
        />
        <PermissionProtectedRoute
          exact
          path="/edituser/:id"
          itemName='User'
          action='canEdit'
          component={EditUser}
        />
        <PermissionProtectedRoute exact path="/viewuser/:id" itemName='User'
          action='canView' component={userViewSingle} />
        <ProtectedRoute
          exact
          path="/admin/createproduct"
          component={AllowAdminOnly(CreateProduct)}
        />
        <CanSeeRoute exact path="/admin/viewproduct/:id" component={ViewProduct} />
        <CanSeeRoute exact path="/admin/listproduct" component={AllowAdminOnly(ListProduct)} />
        <PermissionProtectedRoute
          exact
          path="/createbalanceadjustment"
          itemName='Balance Adjustment'
          action='canCreate'
          component={CreateBalanceAdjustment}
        />

        <ProtectedRoute
          exact
          path="/admin/createadvert"
          component={AllowAdminOnly(CreateAdvert)}
        />

        <ProtectedRoute exact path="/admin/viewadvert/:id" component={ViewAdvert} />
        <ProtectedRoute exact path="/admin/listadvert" component={AllowAdminOnly(ListAdvert)} />

        <ProtectedRoute
          exact
          path="/admin/editproduct/:id"
          component={AllowAdminOnly(EditProduct)}
        />
        <ProtectedRoute
          exact
          path="/admin/editproductgeneralization/:id"
          component={AllowAdminOnly(EditProductGeneralization)}
        />

        <ProtectedRoute
          exact
          path="/admin/viewproductgeneralization/:id"
          component={AllowAdminOnly(ViewProductGeneralization)}
        />

        <PermissionProtectedRoute
          exact
          path="/viewbalanceadjustment/:id"
          itemName='Balance Adjustment'
          action='canView'
          component={ViewBalanceAdjustment}
        />


        <PermissionProtectedRoute
          exact
          path="/balanceadjustmentlist"
          itemName='Balance Adjustment'
          action='isVisible'
          component={BalanceAdjustmentList}
        />
        <ProtectedRoute exact path="/editprofile" component={Editprofile} />
        <PermissionProtectedRoute exact path="/stockbalance" itemName='Stock Balance'
          action='canView' component={Stockbalance} />
        <PermissionProtectedRoute
          exact
          path="/editbalanceadjustment/:id"
          itemName='Balance Adjustment'
          action='canEdit'
          component={EditBalanceAdjustment}
        />

        <PermissionProtectedRoute
          exact
          path="/addProduct"
          itemName='Add Product'
          action='canCreate'
          component={GeneralProduct} />
        <PermissionProtectedRoute itemName='Add Product'
          action='isVisible' exact path="/myproductlist" component={MyProductList} />
        <PermissionProtectedRoute
          exact
          path="/viewmyproduct/:id"
          itemName='Add Product'
          action='canView'
          component={ViewMyProduct}
        />
        <PermissionProtectedRoute
          exact
          path="/quoterequestdetail/:id"
          itemName='Quote Request'
          action='canView'
          component={Quoterequestdetail}
        />
        <PermissionProtectedRoute
          exact
          path="/quoteresponselist"
          itemName='Quote Response'
          action='isVisible'
          component={Quoteresponselist}
        />
        <PermissionProtectedRoute
          exact
          path="/quoteresponsedetail/:id"
          itemName='Quote Response'
          action='canView'
          component={Quoteresponsedetail}
        />

        <PermissionProtectedRoute
          exact
          path="/createcreditnote"
          itemName='Credit Note'
          action='canCreate'
          component={Createcreditnote}
        />
        <PermissionProtectedRoute
          exact
          path="/viewcreditnote/:id"
          itemName='Credit Note'
          action='canView'
          component={Viewcreditnote}
        />
        <PermissionProtectedRoute
          exact
          path="/editcreditnote/:id"
          itemName='Credit Note'
          action='canEdit'
          component={Editcreditnote}
        />
        <PermissionProtectedRoute
          exact
          path="/listcreditnote"
          itemName='Credit Note'
          action='isVisible'
          component={Listcreditnote}
        />

        <PermissionProtectedRoute
          exact
          path="/editreceiptnote"
          itemName='Receipt Note'
          action='canEdit'
          component={EditReceiptNote}
        />
        <PermissionProtectedRoute
          exact
          path="/receiptnotelist"
          itemName='Receipt Note'
          action='isVisible'
          component={AllowReqForQuote(ListReceiptNote)}
        />
        <PermissionProtectedRoute
          exact
          path="/deletebalanceadjustment/:id"
          itemName='Balance Adjustment'
          action='canDelete'
          component={DeleteBalanceAdjustment}
        />

        <ProtectedRoute exact path="/admin/createcountry" component={AllowAdminOnly(CreateCountry)} />
        <ProtectedRoute exact path="/admin/editcountry/:id" component={AllowAdminOnly(EditCountry)} />
        <ProtectedRoute exact path="/admin/viewcountry/:id" component={AllowAdminOnly(ViewCountry)} />
        <PermissionProtectedRoute
          exact
          path="/paymentlist"
          itemName='Payment Note'
          action='isVisible'
          //component={AllowReqForQuote(PaymentList)}
          component={PaymentList}

        />

        <PermissionProtectedRoute
          exact
          path="/previeworder"
          itemName='Order'
          action='canEdit'
          component={AllowTransaction(PreviewOrder)}
        />
        <PermissionProtectedRoute
          exact
          path="/orderlist"
          itemName='Order'
          action='isVisible'
          component={AllowUserOnly(Orderlist)}
        />
        <PermissionProtectedRoute
          exact
          path="/orderdetail/:id"
          itemName='Order'
          action='canView'
          component={AllowUserOnly(Orderdetail)}
        />
        <PermissionProtectedRoute
          exact
          path="/creatediscount"
          itemName='Discount'
          action='canCreate'
          component={Creatediscount}
        />
        <PermissionProtectedRoute
          exact
          path="/editdiscount/:id"
          itemName='Discount'
          action='canEdit'
          component={Editdiscount}
        />
        <PermissionProtectedRoute exact path="/discountlist" itemName='Discount'
          action='isVisible' component={Discountlist} />
        <PermissionProtectedRoute
          exact
          path="/viewdiscount/:id"
          itemName='Discount'
          action='canView'
          component={AllowUserOnly(Viewdiscount)}
        />

        <PermissionProtectedRoute
          exact
          path="/issuelist"
          itemName='Stock Issue Note'
          action='isVisible'
          component={IssueList}
        />

        <PermissionProtectedRoute
          exact
          path="/listfinancialledger"
          itemName='Finanicial Ledger'
          action='canView'
          component={AllowUserOnly(Financialledgerlist)}
        />
        <PermissionProtectedRoute
          exact
          path="/invoicelist"
          itemName='Invoice Note'
          action='canView'
          component={InvoiceList}
        />

        <PermissionProtectedRoute
          exact
          path="/createcharge"
          itemName='Charge'
          action='canCreate'
          component={CreateCharge}
        />
        <PermissionProtectedRoute exact path="/chargelist" itemName='Charge'
          action='isVisible' component={ListCharge} />
        <PermissionProtectedRoute
          exact
          path="/editcharge/:id"
          itemName='Charge'
          action='canEdit'
          component={EditCharge}
        />
        <CanSeeRoute exact path="/viewcharge/:id" itemName='Charge'
          action='canView' component={AllowUserOnly(ViewCharge)} />
        <ProtectedRoute
          exact
          path="/editpayment/:id"
          action='canEdit'
          component={AllowUserOnly(EditPayment)}
        />
        <PermissionProtectedRoute
          exact
          path="/viewpayment/:id"
          itemName='Payment Note'
          action='canView'
          //component={AllowReqForQuote(ViewPayment)}
          component={AllowUserOnly(ViewPayment)}

        />

        <PermissionProtectedRoute
          exact
          path="/deliverynotelist"
          itemName='Delivery Note'
          action='isVisible'
          component={AllowUserOnly(ListDeliveryNote)}
        />
        <PermissionProtectedRoute
          exact
          path="/editdeliveryNote/:id"
          itemName='Delivery Note'
          action='canEdit'
          component={AllowUserOnly(EditDeliveryNote)}
        />
        <PermissionProtectedRoute
          exact
          path="/viewdeliverynote/:id"
          itemName='Delivery Note'
          action='canView'
          component={AllowUserOnly(ViewDeliveryNote)}
        />
        <PermissionProtectedRoute
          exact
          path="/retailshop"
          itemName='Shop'
          action='canCreate'
          component={AllowUserOnly(Retailshop)} />
        <ProtectedRoute
          exact
          path="/previewretailshop"
          component={AllowUserOnly(Previewretailshop)}
        />
        <PermissionProtectedRoute exact path="/saleslist"
          itemName='Sales'
          action='isVisible'
          component={AllowUserOnly(Saleslist)} />
        <PermissionProtectedRoute exact path="/saledetail/:id"
          itemName='Sales'
          action='canView'
          component={AllowUserOnly(Saledetail)} />
        <PermissionProtectedRoute
          exact
          path="/accountbalance"
          itemName='Account Balance'
          action='canView'
          component={AllowUserOnly(AccountBalance)}
        />

        <PermissionProtectedRoute
          exact
          path="/createcreditfacility"
          itemName='Credit Facility'
          action='canCreate'
          component={Createcreditfacility}
        />
        <PermissionProtectedRoute
          exact
          path="/editcreditfacility/:id"
          itemName='Credit Facility'
          action='canEdit'
          component={Editcreditfacility}
        />
        <PermissionProtectedRoute
          exact
          path="/listcreditfacility"
          itemName='Credit Facility'
          action='isVisible'
          component={Listcreditfacility}
        />
        <PermissionProtectedRoute
          exact
          path="/viewcreditfacility/:id"
          itemName='Credit Facility'
          action='canView'
          component={Viewcreditfacility}
        />
        <PermissionProtectedRoute
          exact
          path="/productprice"
          itemName='Price Adjustment'
          action='canCreate'
          component={AllowPriceAdj(Productprice)}
        />
        <PermissionProtectedRoute
          exact
          path="/productpricelist"
          itemName='Price Adjustment'
          action='isVisible'
          component={Productpricelist}
        />
        <PermissionProtectedRoute
          exact
          path="/viewProductPrice/:id"
          itemName='Price Adjustment'
          action='canView'
          component={ViewProductPrice}
        />
        <PermissionProtectedRoute
          exact
          path="/editproductprice/:id"
          itemName='Price Adjustment'
          action='canEdit'
          component={AllowPriceAdj(Editproductprice)}
        />

        <PermissionProtectedRoute
          exact
          path="/conversion"
          itemName='Pack Unit Conversion'
          action='canView'
          component={Conversion}
        />
        <PermissionProtectedRoute
          exact
          path="/mywallet"
          itemName='Wallet'
          action='canView'
          component={Wallet}
        />
        <PermissionProtectedRoute
          exact
          path="/wallettransaction/:id"
          itemName='Wallet'
          action='canView'
          component={Viewtransaction}
        />
        <ProtectedRoute exact path="/admin/globalsetting" component={AllowAdminOnly(GlobalSetting)} />
        <PermissionProtectedRoute
          exact
          path="/businesssetting"
          itemName='Business Setting'
          action='canView'
          component={BusinessDocumentSetting}
        />
        <ProtectedRoute
          exact
          path="/admin/globalbusinesssetting"
          component={AllowAdminOnly(GlobalBusinessDocumentSetting)}
        />

        <ProtectedRoute
          exact
          path="/admin/createproductfunction"
          component={AllowAdminOnly(CreateProductFunction)}
        />
        <ProtectedRoute
          exact
          path="/admin/listproductfunction"
          component={AllowAdminOnly(ListProductFunction)}
        />
        <ProtectedRoute
          exact
          path="/admin/editproductfunction/:id"
          component={AllowAdminOnly(EditProductFunction)}
        />
        <ProtectedRoute
          exact
          path="/admin/viewproductfunction/:id"
          component={AllowAdminOnly(ViewProductFunction)}
        />

        <ProtectedRoute
          exact
          path="/admin/createproductpack"
          component={AllowAdminOnly(CreateProductPack)}
        />
        <ProtectedRoute
          exact
          path="/admin/productpacklist"
          component={AllowAdminOnly(ListProductPack)}
        />
        <ProtectedRoute
          exact
          path="/admin/viewproductpack/:id"
          component={AllowAdminOnly(ViewProductPack)}
        />
        <ProtectedRoute
          exact
          path="/admin/editproductpack/:id"
          component={AllowAdminOnly(EditProductPack)}
        />

        <PermissionProtectedRoute exact path="/haulagelist" itemName='Hauler Request'
          action='isVisible' component={AllowUserOnly(HaulageList)} />
        <ProtectedRoute
          exact
          path="/admin/createbillrate"
          component={AllowAdminOnly(CreateBillRate)}
        />
        <ProtectedRoute exact path="/admin/listbillrate" component={AllowAdminOnly(BillRateList)} />
        <ProtectedRoute
          exact
          path="/admin/viewbillrate/:id"
          component={AllowAdminOnly(ViewBillRate)}
        />
        <ProtectedRoute
          exact
          path="/admin/editbillrate/:id"
          component={AllowAdminOnly(EditBillRate)}
        />

        <PermissionProtectedRoute exact path="/listhaulerbill" itemName='Hauler Bill'
          action='isVisible' component={AllowUserOnly(HaulerBillList)} />
        <PermissionProtectedRoute exact path="/viewhaulagebill/:id" itemName='Hauler Bill'
          action='canView' component={AllowUserOnly(ViewHaulageBill)} />

        <PermissionProtectedRoute exact path="/conferenceexhibitorlist" itemName='Conference Exhibitors'
          action='isVisible' component={(Conferences)} />
        <ProtectedRoute exact path="/exhibitionpayment" component={(ExhibitionPayment)} />
        <PermissionProtectedRoute exact path="/conferenceexhibitormain/:id" itemName='Conference Exhibitors'
          action='canView' component={(ConferenceExhibitorsMain)} />

        <PermissionProtectedRoute itemName='Bank Account'
          action='canCreate' exact path="/createbankdetail" component={AllowUserOnly(CreateBankDetail)} />
        <PermissionProtectedRoute itemName='Bank Account'
          action='isVisible' exact path="/listbankdetails" component={AllowUserOnly(ListBankDetail)} />
        <PermissionProtectedRoute itemName='Bank Account'
          action='canView' exact path="/viewbankdetail/:id" component={ViewBankDetail} />
        <PermissionProtectedRoute itemName='Bank Account'
          action='canEdit' exact path="/editbankdetail/:id" component={EditBankDetail} />

        <PermissionProtectedRoute exact path="/roles" itemName='Role Setting'
          action='isVisible' component={AllowUserOnly(Roles)} />
        <ProtectedRoute exact path="/editrole/:id" itemName='Role Setting'
          action='canEdit' component={AllowUserOnly(EditRole)} />
        <ProtectedRoute exact path="/roleaccess" itemName='Role Setting'
          action='canCreate' component={AllowUserOnly(RoleAccess)} />


        <ProtectedRoute exact path="/viewwithdrawaldetail/:id" component={ViewWithdrawalDetail} />


        <Route exact path="/305" component={ActivateAccount} />
        <Route exact path="/403" component={AccessDenied} />
        <Route exact path="/404" component={PageNotFound} />
        <Route exact path="/licenseexpiration" component={licenseExpire} />


        <ProtectedRoute exact path="/admin/listcountries"
          component={AllowAdminOnly(ListCountries)} />
        <ProtectedRoute exact path="/admin/createproductgeneralization"
          component={AllowAdminOnly(CreateProductGeneralization)} />
        <ProtectedRoute exact path="/admin/listproductgeneralization"
          component={AllowAdminOnly(Listproductgeneralization)} />
        <ProtectedRoute exact path="/admin/viewpaymenttype/:id"
          component={AllowAdminOnly(ViewPaymentType)} />
        <ProtectedRoute exact path="/admin/listpaymenttype"
          component={AllowAdminOnly(ListPaymentype)} />
        <ProtectedRoute exact path="/admin/createpayment"
          component={AllowAdminOnly(CreatePaymentType)} />
        <ProtectedRoute exact path="/admin/viewdocumenttype/:id"
          component={AllowAdminOnly(ViewDocumentType)} />
        <ProtectedRoute exact path="/admin/listdocumenttypes"
          component={AllowAdminOnly(Listdocument)} />
        <ProtectedRoute exact path="/admin/createdocument"
          component={AllowAdminOnly(Createdocument)} />
        <ProtectedRoute exact path="/admin/viewparticipanttype/:id"
          component={AllowAdminOnly(ViewParticipantType)} />
        <ProtectedRoute exact path="/admin/listparticipanttype"
          component={AllowAdminOnly(ListParticipantype)} />
        <ProtectedRoute exact path="/admin/createparticipanttype"
          component={AllowAdminOnly(CreateParticipanttype)} />
        <ProtectedRoute exact path="/admin/businesslist"
          component={AllowAdminOnly(BusinessList)} />
        <ProtectedRoute exact path="/admin/viewbusiness/:id"
          component={AllowAdminOnly(ViewBusiness)} />
        <ProtectedRoute exact path="/admin/locationlist"
          component={AllowAdminOnly(AdminLocationList)} />
        <ProtectedRoute exact path="/admin/dashboard"
          component={AllowAdminOnly(AdminDashboard)} />
        <ProtectedRoute exact path="/admin/globalbusinesssetting"
          component={AllowAdminOnly(GlobalBusinessDocumentSetting)} />
        <ProtectedRoute exact path="/admin/viewlocation/:id"
          component={AllowAdminOnly(ViewLocation)} />





        <ProtectedRoute exact path="/edituserprofile" component={EditUserProfile} />
        <ProtectedRoute exact path="/admin/fundwallet" component={AllowAdminOnly(FundWallet)} />
        <ProtectedRoute exact path="/notifications" component={HeaderNofications} />
        <ProtectedRoute exact path="/viewnotification/:id" component={ViewNofication} />


        <PermissionProtectedRoute exact path="/productrequest" itemName='Product Search'
          action='canView' component={AllowUserOnly(ProductRequest)} />
        <PermissionProtectedRoute exact path="/productoffload" itemName='Product Offload'
          action='canView' component={AllowUserOnly(ProductOffload)} />
        <ProtectedRoute exact path="/admin/createregion" component={AllowAdminOnly(CreateRegion)} />
        <ProtectedRoute exact path="/admin/listregion" component={AllowAdminOnly(ListRegion)} />
        <ProtectedRoute exact path="/admin/editregion/:id" component={AllowAdminOnly(EditRegion)} />
        <PermissionProtectedRoute exact path="/productbroadcasts" itemName='Product Broadcast'
          action='canView' component={AllowUserOnly(productBroadcastList)} />
        <ProtectedRoute exact path="/admin/verifyhauler" component={AllowAdminOnly(VerifyHauler)} />

        <CanSeeRoute exact path="/admin/createlicencepaymentcharge" component={AllowAdminOnly(CreateLicencePaymentCharge)} />
        <CanSeeRoute exact path="/admin/editlicencepaymentcharge/:id" component={AllowAdminOnly(EditLicencePaymentCharge)} />
        <CanSeeRoute exact path="/admin/listlicencepaymentcharge" component={AllowAdminOnly(ListLicencePaymentCharge)} />
        <CanSeeRoute exact path="/admin/viewlicencepaymentcharge/:id" component={AllowAdminOnly(ViewLicencePaymentCharge)} />

        <CanSeeRoute exact path="/admin/createwithdrawalcharge" component={AllowAdminOnly(CreateWithdrawalCharge)} />
        <CanSeeRoute exact path="/admin/editwithdrawalcharge/:id" component={AllowAdminOnly(EditWithdrawalCharge)} />
        <CanSeeRoute exact path="/admin/listwithdrawalcharge" component={AllowAdminOnly(ListWithdrawalCharge)} />
        <CanSeeRoute exact path="/admin/viewwithdrawalcharge/:id" component={AllowAdminOnly(ViewWithdrawalCharge)} />

        <ProtectedRoute exact path="/admin/viewhauler/:id" component={AllowAdminOnly(ViewHauler)} />
        <ProtectedRoute exact path="/admin/conferencelist" component={AllowAdminOnly(ListConference)} />
        <ProtectedRoute exact path="/admin/createconference" component={AllowAdminOnly(CreateConference)} />
        <ProtectedRoute exact path="/admin/viewconference/:id" component={AllowAdminOnly(ViewConference)} />
        <ProtectedRoute exact path="/admin/editconference/:id" component={AllowAdminOnly(EditConference)} />
        <ProtectedRoute exact path="/admin/chargecreate" component={AllowAdminOnly(AdminCreateCharge)} />
        <ProtectedRoute exact path="/admin/chargelist" component={AllowAdminOnly(AdminChargeList)} />
        <ProtectedRoute exact path="/admin/chargeedit/:id" component={AllowAdminOnly(AdminEditCharge)} />
        <ProtectedRoute exact path="/admin/chargeview/:id" component={AllowAdminOnly(AdminViewCharge)} />
        <ProtectedRoute exact path="/admin/discountcreate" component={AllowAdminOnly(AdminCreatediscount)} />
        <ProtectedRoute exact path="/admin/discountlist" component={AllowAdminOnly(AdminDiscountlist)} />
        <ProtectedRoute exact path="/admin/discountedit/:id" component={AllowAdminOnly(AdminEditdiscount)} />
        <ProtectedRoute exact path="/admin/discountview/:id" component={AllowAdminOnly(AdminViewdiscount)} />
        <ProtectedRoute exact path="/admin/conferenceexhibitorcreate" component={AllowAdminOnly(CreateExhibitor)} />
        <ProtectedRoute exact path="/admin/conferenceexhibitoredit/:id" component={AllowAdminOnly(EditExhibitor)} />
        <ProtectedRoute exact path="/admin/conferenceexhibitorview/:id" component={AllowAdminOnly(ViewExhibitor)} />
        <ProtectedRoute exact path="/admin/conferenceexhibitorlist" component={AllowAdminOnly(ConferenceExhibitors)} />
        <ProtectedRoute exact path="/admin/aggregatedreport" component={AllowAdminOnly(AdminAggregatedReports)} />
        <PermissionProtectedRoute exact path="/pickup" itemName='Pick up'
          action='isVisible' component={PickUp} />
        <ProtectedRoute exact path="/admin/currency" component={AllowAdminOnly(Currency)} />
        <ProtectedRoute exact path="/onlinepayments" component={(OnlinePayments)} />
        <ProtectedRoute exact path="/emailpreferences" component={SetEmailPreferences} />
        <PermissionProtectedRoute exact path="/favouritesuppliers" itemName='Favourite Supplier'
          action='canView' component={IsManu(FavouriteSupplier)} />
        <PermissionProtectedRoute exact path="/favouritehaulers" itemName='Favourite Hauler'
          action='canView' component={FavouriteHauler} />

        <ProtectedRoute exact path="/assignedbusiness" component={AssignedBusiness} />


        <PermissionProtectedRoute exact path="/aggregatedreports" itemName='Aggregated Document'
          action='canView' component={AggregatedReports} />
        <CanSeeRoute exact path="/pendingpayments" component={PendingPayment} />
        <CanSeeRoute exact path="/walletTransactions" component={walletTransactions} />

        <Redirect to="/404" />
      </Switch>
    </BrowserRouter>
  );
}
