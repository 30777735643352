import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router";
import api from "../utils/api";
import Header from '../components/header';
import Sidebar from '../components/sidebar';
import Footer from '../components/footer';
import ThemeSetting from '../components/themeSetting';


const ProtectedRoute = ({ showHead = true, children, ...rest }) => {

  const auth = JSON.parse(localStorage.getItem("auth"));
  const [wActivated, setWalletActivated] = useState(null);
  const [licenseExpiration, setLicenseExpiration] = useState(null);

  useEffect(() => {

    const checkActivationStatus = async () => {
      const { data: { walletActivated, licenseExpiryDate } } = await api.Business.getByID(auth.businessId);
      setWalletActivated(walletActivated);
      if (new Date(licenseExpiryDate) >= new Date()) {
        setLicenseExpiration(true);
      } else {
        setLicenseExpiration(false);
      }
    }

    checkActivationStatus();

  }, [])

  if (api.Auth.isAuth()) {
    const route = window.location.pathname;
    if ((route !== '/dashboard'
      && route !== '/mywallet' &&
      route !== '/mywallet?menuFrom=activate'
      && route !== '/documentation'
      && route !== '/editprofile'
      && route !== '/edituserprofile'
      && route !== '/changepassword'
      && route !== '/notifications'
      && wActivated !== null
      && !auth.isAdmin)
      && !wActivated) {

      return <Redirect to={{ pathname: "/305" }} />;

    } 
    else if (
      (route !== '/dashboard' && route !== '/mywallet' &&
        route !== '/mywallet?menuFrom=activate' &&
        route !== '/mywallet?menuFrom=license' &&
        route !== '/documentation'
        && route !== '/editprofile'
        && route !== '/edituserprofile'
        && route !== '/changepassword'
        && route !== '/notifications'
        && licenseExpiration !== null
        && !auth.isAdmin)
      && licenseExpiration === false
    ) {
      return <Redirect to={{ pathname: "/licenseexpiration" }} />;
    }
    else {
      return (
        <div className="container-scroller">
          <Header />

          <div className="container-fluid page-body-wrapper ">
            <ThemeSetting />
            <Sidebar />
            <Route {...rest}>{children}</Route>
          </div>
          <Footer />
        </div>

      );
    }

  } else {
    localStorage.setItem("lastAccessedUrl", window.location.pathname);
    return <Redirect to={{ pathname: "/signin" }} />;
  }
};

export default ProtectedRoute;
