import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import Backbutton from '../../components/backbutton';
import api from '../../utils/api';
import { notifySuccess } from '../../utils/toast';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const EditRole = () => {

    const { id } = useParams();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [roleItems, setRoleItems] = useState({});
    const { register, handleSubmit, formState: { errors }, setValue } = useForm({
        mode: "onChange",
        reValidateMode: 'onChange'
    });

    const RolePackFormKeys = [
        "name"
    ];
    const auth = JSON.parse(window.localStorage.getItem('auth'));


    const EditRole = async () => {
        const hasPermissionToEdit = (auth?.role.rolePermission.find(e => e.module.moduleName === "Role Setting"))?.canCreate
        if (hasPermissionToEdit) {

            const payload = {
                name: roleItems.name,
            }
            try {
                setLoading(true)
                const response = await api.Roles.editRoleItem(id, payload);
                notifySuccess(response.sucessMessage)
                setLoading(false)
                history.push("/roles");
            }
            catch (error) {
                console.log(error)
                setLoading(false)
            }
        } else {
            history.push('/403')
        }
    };

    const handleOnChange = (e) => {
        const { name, value } = e.target
        let parsedValue = value
        setRoleItems({
            ...roleItems,
            [name]: parsedValue ?? JSON.parse(parsedValue),
        })
    }

    useEffect(() => {
        const loadRoles = async () => {
            const response = await api.Roles.view(id);
            setRoleItems(response.data);
            console.log("Role Items", roleItems)
        };
        loadRoles();
    }, []);

    useEffect(() => {
        if (roleItems.name) {
            setTimeout(() => {
                RolePackFormKeys?.map(key => { setValue(key, roleItems[key]) })
            }, 100);

        }
    }, [roleItems]);
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="card">
                    <div className="card-body">
                        <div className="text-center">
                            <span className="card-title">EDIT WITHDRAWAL CHARGE SETUP</span>
                        </div>
                        <div className="d-flex float-right mt-2">
                            <Backbutton />
                            <button
                                onClick={handleSubmit(EditRole)}
                                type="submit"
                                class="btn btn-primary btn-sm btn-icon-text d-flex mx-1"
                                disabled={loading ? true : false}>
                                <i className="ti-save mr-1" title="Submit"></i>
                                <span className="d-none d-md-block">{loading ? "SAVING" : "SAVE"}</span>
                            </button>
                        </div>

                        <form className="row mt-5" action="" >
                            <div className="col-md-4">
                                <div class="form-group">
                                    <label HtmlFor="rangeName" className="form-label">Role Name<span className='text-danger'>*</span>
                                        {errors.rangeName &&
                                            <span className="text-danger font-weight-bold"> required</span>
                                        }
                                    </label>
                                    <input type="text"
                                        className="form-control text-capitalize"
                                        name="name"
                                        id="name"
                                        placeholder="Role Name"
                                        {...register("name", {
                                            required: true,
                                            onChange: (e) => { handleOnChange(e) }
                                        })}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}
