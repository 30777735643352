import React from 'react'
import { useReactToPrint } from "react-to-print";

export default function PrintButton({ componentRef }) {
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return <>
        <button class="btn btn-success btn-sm btn-icon-text text-white d-flex mx-1" onClick={handlePrint}>
            <i className="ti-printer mr-1" title="Back" ></i>
            <span className="d-none d-md-block">PRINT</span>
        </button>
    </>
        ;
}