import React, { useContext, useEffect, useState, useRef } from 'react';
import api from '../utils/api';
import { useHistory, NavLink, Link } from 'react-router-dom';
import { p2pContext } from '../utils/p2pContext';
import PubSub from 'pubsub-js';
import moment from 'moment';
import { ViewModuleFromNotification } from '../utils/viewModuleFromNotification';
import Select from "react-select";
import { licenseExpiration, licenseExpirationWarning } from '../utils/checkTrialStatus';


export default function Header() {

  const history = useHistory();
  const [authData, setAuthData] = useState({});
  const [data, setData] = useState({});
  const [locations, setLocations] = useState([]);
  let [quoteCount, setQuoteCount] = useState(0);
  let [cartCount, setCartCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [rsDefaultValue, setRsDefaultValue] = useState(null);
  const [options, setOptions] = useState();
  //const { incrementQuoteCount, setincrementQuoteCount } = useContext(p2pContext);
  const reactSelectTypes = [];
  const locationId = localStorage.getItem("locationId");
  const [verifiedLocations, setVerifiedLocations] = useState([]);
  const [showLicenseWarning, setShowLicenseWarning] = useState(false);
  const [showLicenseExpiry, setShowLicenseExpiry] = useState(false);

  const logout = () => {
    api.Auth.logout();
    localStorage.removeItem("lastAccessedUrl");
    history.push("/signin");

  }
  useEffect(async () => {
    const auth = JSON.parse(localStorage.getItem("auth"))
    const response = await api.Business.getByID(auth.businessId);
    const data = response?.data
    setData(data)
    const licenseWarning = licenseExpirationWarning(data?.licenseExpiryDate)
    if (licenseWarning) {
      setShowLicenseWarning(true)
    } else {
      setShowLicenseWarning(false)
    }

    const licenseExpiry = licenseExpiration(data?.licenseExpiryDate)
    if (licenseExpiry) {
      setShowLicenseExpiry(true)
    } else {
      setShowLicenseExpiry(false)
    }
  }, [])
  const getNots = async () => {
    const r = await api.Notification.load();
    console.log({ r });
    setNotifications(r.data.data)
  }

  useEffect(() => {
    const s = JSON.parse(localStorage.getItem("auth"));
    setAuthData(s);
    getNots();
  }, [])

  useEffect(() => {
    const notiToken = PubSub.subscribe("notiCount", (msg, data) => {
      console.log("i am on header")
      getNots();
    });
    return (() => PubSub.unsubscribe(notiToken));
  }, [])


  useEffect(() => {
    const loadLocations = async () => {
      const response = await api.BusinessLocation.load(1, 15, "");
      setVerifiedLocations(response?.data.data.filter(x => x.verified))
      setLocations(response.data.data);
    };
    loadLocations();

  }, []);

  // useEffect( () => {
  //  setQuoteCount(incrementQuoteCount?.length);
  // }, [incrementQuoteCount])

  useEffect(async () => { // react select workings
    for (const s of locations) {
      let obj = {
        value: s.id,
        label: (
          <>
            <span style={{ paddingRight: "20px" }}> {s.locationName} </span>
            {s.verified && <i style={{ float: 'right', marginTop: '5px', fontSize: '15px', fontWeight: 'bolder' }} className="ti-check text-success" title="Verified"></i>}
            {!s.verified && <i style={{ float: 'right', marginTop: '5px', fontSize: '15px', fontWeight: 'bolder' }} className="ti-close text-danger" title="Not Verified"></i>}

          </>
        )
      }
      reactSelectTypes.push(obj);
      obj = {};
    }
    if (locationId) {
      const selected = reactSelectTypes.find(x => x.value === locationId);
      setRsDefaultValue(selected);
      localStorage.setItem("locationId", locationId);
      if (selected) {
        const response = await api.BusinessLocation.view(selected?.value);
        const res = (response.data);
        localStorage.setItem("locationVerify", res.verified);
      }
    } else {
      if (verifiedLocations.length > 0) {
        let obj = {
          value: verifiedLocations[0].id,
          label: (
            <>
              <span style={{ paddingRight: "20px" }}> {verifiedLocations[0].locationName} </span>
              {verifiedLocations[0].verified && <i style={{ float: 'right', marginTop: '5px', fontSize: '15px', fontWeight: 'bolder' }} className="ti-check text-success" title="Verified"></i>}
            </>
          )
        }
        setRsDefaultValue(obj);
        localStorage.setItem("locationId", verifiedLocations[0].id);
        const response = await api.BusinessLocation.view(verifiedLocations[0]?.id);
        const res = (response.data);
        localStorage.setItem("locationVerify", res.verified);
      } else {
        setRsDefaultValue(reactSelectTypes[0]);
        if (reactSelectTypes.length > 0) {
          localStorage.setItem("locationId", reactSelectTypes[0].value);
          const response = await api.BusinessLocation.view(reactSelectTypes[0].value);
          const res = (response.data);
          localStorage.setItem("locationVerify", res.verified);
        }
      }
    }
    setOptions(reactSelectTypes)

  }, [locations]);

  useEffect(() => {
    const token = PubSub.subscribe("quote", (msg, data) => {
      setQuoteCount(data?.quotationNoteDetail?.length)
    });
    return (() => PubSub.unsubscribe(token));
  }, [])

  useEffect(() => {
    const cartToken = PubSub.subscribe("product", (msg, data) => {
      setCartCount(data?.orderingDetails?.length)
    });
    return (() => PubSub.unsubscribe(cartToken));
  }, [])

  useEffect(() => {
    const cartItems = JSON.parse(localStorage.getItem("products"));
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (cartItems?.customerId === auth.id) {
      setCartCount(cartItems?.orderingDetails?.length)
    } else {
      setCartCount(0)
    }

  }, []);

  useEffect(() => {
    const quoteItems = JSON.parse(localStorage.getItem("quotes"));
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (quoteItems?.customerId === auth.id) {
      setQuoteCount(quoteItems?.quotationNoteDetail?.length)
    } else {
      setQuoteCount(0)
    }
  }, []);

  const locationChanged = (e) => {
    const id = e.value || e.target.value;
    localStorage.setItem("locationId", id);
    window.location.reload();

  }

  const viewNot = async (n) => {
    if (!n.id) return;
    await api.Notification.update(n.id);
    ViewModuleFromNotification(n);
  }

  const ClearAll = async () => {
    await api.Notification.ClearAll();
    window.location.reload();
  }

  const payLicense = async () => {
    if (window.location.pathname === '/mywallet') {
      return document.getElementById('license-btn').click();
    } else {
      return history.push(`/mywallet?menuFrom=license`);
    }
  }

  return <>

    <nav className="navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <NavLink className="navbar-brand brand-logo mr-5" to={authData.isAdmin ? "/admin/dashboard" : "/dashboard"}><img src="assets/images/logo.png" className="mr-2" alt="logo" /></NavLink>
        <NavLink className="navbar-brand brand-logo-mini" to={authData.isAdmin ? "/admin/dashboard" : "/dashboard"}><img src="assets/images/logo1.png" alt="logo" /></NavLink>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-end">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" data-toggle="minimize">
          <span className="icon-menu" />
        </button>
        <ul className="navbar-nav mr-lg-2">
          <li className="nav-item nav-search d-lg-block">

            {
              rsDefaultValue && <div className="input-group">
                <Select
                  className="rounded p-0 text-uppercase location-dropdown-lg"
                  onChange={locationChanged}
                  options={options}
                  defaultValue={rsDefaultValue}
                />
              </div>
            }

          </li>
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item dropdown">
            <strong className="full-name text-primary h6">{authData.fullName}</strong>
          </li>
          <li className="nav-item dropdown">
            <li className="nav-item dropdown">
              <strong className="text-primary h6">{authData.businessCode}</strong>
            </li>
            <span className='full-name text-muted h6'>{moment().format('llll')}</span>
          </li>
          <li className="nav-item dropdown">
            {!authData.isAdmin && <NavLink to={quoteCount ? "/viewquoterequest" : "/newquoterequest"} className="nav-link count-indicator" id="quote" >
              <i className="mdi mdi-receipt mx-1" title='Quote' />
              <span class="position-absolute translate-middle badge rounded-pill bg-danger mt-0" style={{ marginLeft: "-15px" }}>
                {quoteCount ? quoteCount : ''}
              </span>
            </NavLink>}
            {!authData.isAdmin && <NavLink to={cartCount ? "/previewOrder" : "/placeorder"} className="nav-link count-indicator" id="cart">
              <i class="mdi mdi-cart-outline mx-3" title='Cart' />
              <span class="position-absolute translate-middle badge rounded-pill bg-danger mt-0" style={{ marginLeft: "-25px" }}>
                {cartCount ? cartCount : ''}
              </span>
            </NavLink>}
            <a className="nav-link count-indicator dropdown-toggle" id="notification" href="#" data-toggle="dropdown">
              <i className="mdi mdi-bell-outline mx-0" title='notification' />
              <span class="position-absolute translate-middle badge rounded-pill bg-danger mt-0" style={{ marginLeft: "-15px" }}>
                {notifications.length > 0 ? notifications.length : ''}
              </span>
              <span class="position-absolute translate-middle badge rounded-pill bg-danger mt-0" style={{ marginLeft: "-10px" }}>

              </span>
            </a>
            <div
              style={{ overflowY: 'auto', maxHeight: '500px' }}
              className="dropdown-menu dropdown-menu-right navbar-dropdown preview-list"
              aria-labelledby="notificationDropdown">
              <p className="mb-0 font-weight-normal float-left dropdown-header">Notifications</p>

              {notifications.length > 1 &&
                <div class="template-demo float-right form-group">
                  <Link to={`/notifications`} className="btn btn-primary btn-rounded btn-sm">See All</Link>
                  <span onClick={ClearAll} className="btn btn-danger btn-rounded btn-sm">Clear All</span>
                </div>}

              {notifications.length === 0 && <div className="dropdown-item"
              // style={{ margin: 'auto', padding: '10px', width: '50%', }} 
              >No recent notifications</div>}

              {notifications.length > 0 && notifications.map(x =>
                <div onClick={() => viewNot(x)} className="dropdown-item preview-item" title={x.message}>
                  <div className="preview-thumbnail">
                    <div className="preview-icon bg-success">
                      <i className="ti-info-alt mx-0" />
                    </div>


                  </div>
                  <div className="preview-item-content" >
                    <p className="preview-subject font-weight-normal" >{x.message}</p>
                    <p className="font-weight-light small-text mb-0 text-muted">
                      {moment(x?.createdAt).fromNow()}

                    </p>
                  </div>
                </div>
              )}
            </div>
          </li>
          <li className="nav-item nav-profile dropdown">
            <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown" id="profileDropdown">
              <img src={authData.businessLogo || 'assets/images/default.jpg'} alt="profile" className="full-name" />
              <i className="icon-head menu-icon d-block d-md-none" />
            </a>
            <div className="dropdown-menu dropdown-menu-right navbar-dropdown" aria-labelledby="profileDropdown">
              <div className="dropdown-item bg-primary text-light">
                <img src={authData.businessLogo || 'assets/images/default.jpg'} alt="profile" />
                <div>
                  <h5 className='ml-3'>{authData.fullName}</h5>
                  <div className='ml-3 mr-3'>{authData.email}</div>
                </div>
              </div>
              <hr />
              <NavLink to={(authData.isBusinessUser || authData.isAdmin) ? "/edituserprofile" : "/editprofile"} className="dropdown-item">
                <i className="icon-head menu-icon" />
                Edit Profile
              </NavLink>

              <NavLink to={"/changepassword"} className="dropdown-item">
                <i className="icon-lock menu-icon" />
                Change Password
              </NavLink>
              {(authData.email === 'admin@p2p.com' || !authData.isAdmin) && <NavLink to={"/notifications"} className="dropdown-item">
                <i className="mdi mdi-bell-outline menu-icon" />
                Notifications
              </NavLink>}
              {/* <NavLink to={"/emailpreferences"} className="dropdown-item">
                <i className="mdi mdi-email-outline menu-icon" />
                Email Preferences
              </NavLink> */}
              {/* <a className="dropdown-item">
                <i className="icon-cog menu-icon" />
                Settings
              </a> */}
              <a onClick={logout} className="dropdown-item">
                <i className="icon-power menu-icon text-danger" />
                Logout
              </a>
            </div>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" data-toggle="offcanvas">
          <span className="icon-menu" />
        </button>
      </div>

      {

        rsDefaultValue &&
        <Select
          className="rounded text-uppercase location-dropdown-sm"
          onChange={locationChanged}
          options={options}
          defaultValue={rsDefaultValue}
        />
      }

    </nav>
    {
      (showLicenseWarning && authData?.email !== "admin@p2p.com") && <div className="row license-warning align-items-center d-flex flex-row bg-warning">
        {showLicenseWarning === true ? <i type="button" onClick={() => setShowLicenseWarning(!showLicenseWarning)} className="col-md-12 ti-close font-weight-bold mt-2 text-right"></i> : ''}
        <div className="col-lg-5 text-lg-right pr-lg-4">
          <h2 className="display-1 mb-0"><i className="ti-alert"></i></h2>
        </div>
        <div className="col-lg-6 error-page-divider text-lg-left pl-lg-4">
          <h4>{showLicenseExpiry ? `LICENSE EXPIRY` : `LICENSE ABOUT TO EXPIRE!`}</h4>
          <h3 className="font-weight-light">{showLicenseExpiry ? `License Expired on ${moment(data?.licenseExpiryDate).format('ll')}.` : `Renew your License on or before ${moment(data?.licenseExpiryDate).format('ll')}.`}</h3>
          <button className="btn btn-sm btn-primary font-weight-medium" style={{ cursor: "pointer" }} onClick={payLicense}>
            <i className="ti-check-box mr-1" title="Renew License"></i> Renew License</button>
        </div>
      </div>
    }


  </>;
}
