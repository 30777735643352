import React, { useState, useEffect } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import api from '../../utils/api';


export default function AdminSidebar() {

    const history = useHistory();

    const Auth = JSON.parse(localStorage.getItem("auth"));

    const logout = () => {
        api.Auth.logout();
        localStorage.removeItem("lastAccessedUrl");
        history.push("/signin");

    }

    return <>
        {Auth.email !== 'admin@p2p.com' && <nav className="sidebar sidebar-offcanvas" id="sidebar">
            <ul className="nav">

                {(Auth.roles).includes("Dashboard") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/dashboard"}>
                            <i className="icon-grid menu-icon" />
                            <span className="menu-title">Dashboard</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Global Doc Setting") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/globalsetting"}>
                            <i className="icon-globe menu-icon" />
                            <span className="menu-title">Global Doc. Setting</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Global Business Setting") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/globalbusinesssetting"}>
                            <i className="icon-cog menu-icon" />
                            <span className="menu-title">Global Business Setting</span>
                        </NavLink>
                    </li>}
                {(Auth.roles).includes("Currency") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/currency"}>
                            <i className="mdi mdi-cash menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title role">Currency</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Product Categories") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listproductfunction"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title">Product Categories</span>
                        </NavLink>
                    </li>}
                {(Auth.roles).includes("Product Generalization") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listproductgeneralization"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title">Product Generalization</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Product") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listproduct"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title">Product</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Product Pack") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/productpacklist"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title">Product Pack</span>
                        </NavLink>
                    </li>}
                {
                /* IsraelAsidere */
                }

                {
                /* What does line 86 do? */
                }
                {
                Auth.roles.includes('Licence Payment Charges') && (
                    <li className='nav-item' type='button' data-toggle='offcanvas'>
                    <NavLink className='nav-link' to={'/admin/listlicencepaymentcharge'}>
                        <i className='icon-target menu-icon' />
                        <span className='menu-title'>License Payment Charges</span>
                    </NavLink>
                    </li>
                )
                }

                {
                Auth.roles.includes('Withdrawal Charge Setup') && (
                    <li className='nav-item' type='button' data-toggle='offcanvas'>
                    <NavLink className='nav-link' to={'/admin/listwithdrawalcharge'}>
                        <i className='icon-target menu-icon' />
                        <span className='menu-title'>Withdrawal Charge Setup</span>
                    </NavLink>
                    </li>
                )
                }


                {(Auth.roles).includes("Conference") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/conferencelist"}>
                            <i className="icon-target menu-icon" />
                            <span className="menu-title">Conference</span>
                        </NavLink>
                    </li>}
                {(Auth.roles).includes("Advert") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listadvert"}>
                            <i className="icon-target menu-icon" />
                            <span className="menu-title">Advert</span>
                        </NavLink>
                    </li>}
                {(Auth.roles).includes("Conference Exhibitor") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/conferenceexhibitorlist"}>
                            <i className="icon-target menu-icon" />
                            <span className="menu-title">Conference Exhibitor</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Business List") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/businesslist"}>
                            <i className="icon-briefcase menu-icon" />
                            <span className="menu-title">Business List</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Document Report") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/aggregatedreport"}>
                            <i className="icon-briefcase menu-icon" />
                            <span className="menu-title">Document Report</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Locations") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/locationlist"}>
                            <i className="mdi mdi-map-marker-multiple menu-icon" />
                            <span className="menu-title">Locations</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Hauler Verification") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/verifyhauler"}>
                            <i className="mdi mdi-truck-delivery menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title">Hauler Verification</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Wallet") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/mywallet"}>
                            <i className="mdi mdi-wallet-membership menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title">Wallet</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Fund Bonus Wallet") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/fundwallet"}>
                            <i className="mdi mdi-wallet-giftcard menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title role">Fund Bonus Wallet</span>
                        </NavLink>
                    </li>}


                {(Auth.roles).includes("Fund Wallet") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/fundwallet"}>
                            <i className="mdi mdi-wallet-membership menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title">Fund Wallet</span>
                        </NavLink>
                    </li>}

                {(Auth.roles).includes("Bill Rate") &&
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listbillrate"}>
                            <i className="mdi mdi-currency-btc menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title">Bill Rate</span>
                        </NavLink>
                    </li>}

                {((Auth.roles).includes("Charges") || (Auth.roles).includes("Discounts") || (Auth.roles).includes("Country") || (Auth.roles).includes("Region") || (Auth.roles).includes("Document Types") || (Auth.roles).includes("Participants") || (Auth.roles).includes("Payment Types"))
                    &&
                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#modules" aria-expanded="false" aria-controls="ui-basic">
                            <i className="icon-layout menu-icon" />
                            <span className="menu-title">Modules</span>
                            <i className="menu-arrow" />
                        </a>
                        <div className="collapse" id="modules">
                            <ul className="nav flex-column sub-menu">
                                {(Auth.roles).includes("Charges") &&
                                    <li className="nav-item" type="button" data-toggle="offcanvas">
                                        <NavLink to={"/admin/chargelist"} className="nav-link"><i className="mdi mdi-cash menu-icon" style={{ fontSize: "20px" }} />
                                            Charges
                                        </NavLink>
                                    </li>
                                }
                                {(Auth.roles).includes("Discounts") &&
                                    <li className="nav-item" type="button" data-toggle="offcanvas">
                                        <NavLink to={"/admin/discountlist"} className="nav-link"><i className="mdi mdi-cash menu-icon" style={{ fontSize: "20px" }} />
                                            Discounts
                                        </NavLink>
                                    </li>
                                }
                                {(Auth.roles).includes("Country") &&
                                    <li className="nav-item" type="button" data-toggle="offcanvas">
                                        <NavLink to={"/admin/listcountries"} className="nav-link"><i className="icon-flag menu-icon" />Country</NavLink>
                                    </li>
                                }
                                {(Auth.roles).includes("Region") &&
                                    <li className="nav-item" type="button" data-toggle="offcanvas">
                                        <NavLink to={"/admin/listregion"} className="nav-link"><i className="icon-map menu-icon" />Region</NavLink>
                                    </li>
                                }
                                {(Auth.roles).includes("Document Types") &&
                                    <li className="nav-item" type="button" data-toggle="offcanvas">
                                        <NavLink to={"/admin/listdocumenttypes"} className="nav-link"><i className="icon-folder menu-icon" />Document Types</NavLink>
                                    </li>
                                }
                                {(Auth.roles).includes("Participants") &&
                                    <li className="nav-item" type="button" data-toggle="offcanvas">
                                        <NavLink to={"/admin/listparticipanttype"} className="nav-link"><i className="icon-head menu-icon" /> Participants</NavLink>
                                    </li>
                                }
                                {(Auth.roles).includes("Payment Types") &&
                                    <li className="nav-item" type="button" data-toggle="offcanvas">
                                        <NavLink to={"/admin/listpaymenttype"} className="nav-link"><i className="mdi mdi-cash-multiple menu-icon" style={{ fontSize: "20px" }} /> Payment Types</NavLink>
                                    </li>
                                }

                            </ul>
                        </div>
                    </li>}
                <li className="nav-item">
                    <a className="nav-link" onClick={logout} aria-expanded="false" aria-controls="ui-basic">
                        <i className="icon-power menu-icon text-danger" />
                        <span className="">Logout</span>
                    </a>
                </li>
            </ul>
        </nav>}

        {Auth.email === 'admin@p2p.com' &&
            <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/dashboard"}>
                            <i className="icon-grid menu-icon" />
                            <span className="menu-title role">    Dashboard</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/globalsetting"}>
                            <i className="icon-globe menu-icon" />
                            <span className="menu-title role">Global Doc. Setting</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/globalbusinesssetting"}>
                            <i className="icon-cog menu-icon" />
                            <span className="menu-title role">Global Business Setting</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/currency"}>
                            <i className="mdi mdi-cash menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title role">Currency</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listproductfunction"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title role">Product Categories</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listproductgeneralization"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title role">Product Generalization</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listproduct"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title role">Product</span>
                        </NavLink>
                    </li>
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/productpacklist"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title role">Product Pack</span>
                        </NavLink>
                    </li>

                    {/* IsraelAsidere */}
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listlicencepaymentcharge"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title role">License Payment Charge</span>
                        </NavLink>
                    </li>
                    
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listwithdrawalcharge"}>
                            <i className="icon-box menu-icon" />
                            <span className="menu-title role">Withdrawal Charge Setup</span>
                        </NavLink>
                    </li>
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/conferencelist"}>
                            <i className="icon-target menu-icon" />
                            <span className="menu-title role">Conference</span>
                        </NavLink>
                    </li>
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listadvert"}>
                            <i className="icon-target menu-icon" />
                            <span className="menu-title role">Advert</span>
                        </NavLink>
                    </li>
                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/conferenceexhibitorlist"}>
                            <i className="icon-target menu-icon" />
                            <span className="menu-title role">Conference Exhibitor</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/businesslist"}>
                            <i className="icon-briefcase menu-icon" />
                            <span className="menu-title role">Business List</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/aggregatedreport"}>
                            <i className="icon-briefcase menu-icon" />
                            <span className="menu-title role">Document Report</span>
                        </NavLink>
                    </li>


                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/locationlist"}>
                            <i className="mdi mdi-map-marker-multiple menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title role">Locations</span>
                        </NavLink>
                    </li>


                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/verifyhauler"}>
                            <i className="mdi mdi-truck-delivery menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title role">Hauler Verification</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/mywallet"}>
                            <i className="mdi mdi-wallet-membership menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title role">Wallet</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/fundwallet"}>
                            <i className="mdi mdi-wallet-giftcard menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title role">Fund Bonus Wallet</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/walletTransactions"}>
                            <i className="mdi mdi-wallet-giftcard menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title role">Wallet Transactions</span>
                        </NavLink>
                    </li>

                    <li className="nav-item" type="button" data-toggle="offcanvas">
                        <NavLink className="nav-link" to={"/admin/listbillrate"}>
                            <i className="mdi mdi-currency-btc menu-icon" style={{ fontSize: "20px" }} />
                            <span className="menu-title role">Bill Rate</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <a className="nav-link" data-toggle="collapse" href="#modules" aria-expanded="false" aria-controls="ui-basic">
                            <i className="icon-layout menu-icon" />
                            <span className="menu-title">Modules</span>
                            <span className="menu-titles"><i className="menu-arrow" /></span>
                        </a>
                        <div className="collapse" id="modules">
                            <ul className="nav flex-column sub-menu">
                                <li className="nav-item" type="button" data-toggle="offcanvas">
                                    <NavLink to={"/admin/chargelist"} className="nav-link"><i className="mdi mdi-cash menu-icon" style={{ fontSize: "20px" }} />
                                        <span className="role">Charges</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item" type="button" data-toggle="offcanvas">
                                    <NavLink to={"/admin/discountlist"} className="nav-link"><i className="mdi mdi-cash menu-icon" style={{ fontSize: "20px" }} />
                                        <span className="role">Discounts</span>

                                    </NavLink>
                                </li>
                                <li className="nav-item" type="button" data-toggle="offcanvas">
                                    <NavLink to={"/admin/listcountries"} className="nav-link menu-title">
                                        <i className="icon-flag menu-icon" /><span className="role">Country</span></NavLink>
                                </li>
                                <li className="nav-item" type="button" data-toggle="offcanvas">
                                    <NavLink to={"/admin/listregion"} className="nav-link">
                                        <i className="icon-map menu-icon" />
                                        <span className="role">Region</span></NavLink>
                                </li>
                                <li className="nav-item" type="button" data-toggle="offcanvas">
                                    <NavLink to={"/admin/listdocumenttypes"} className="nav-link menu-title">
                                        <i className="icon-folder menu-icon" />
                                        <span className="role">Document Types</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item" type="button" data-toggle="offcanvas">
                                    <NavLink to={"/admin/listparticipanttype"} className="nav-link menu-title">
                                        <i className="icon-head menu-icon" />
                                        <span className="role">Participants</span>
                                    </NavLink>
                                </li>
                                <li className="nav-item" type="button" data-toggle="offcanvas">
                                    <NavLink to={"/admin/listpaymenttype"} className="nav-link menu-title">
                                        <i className="mdi mdi-cash-multiple menu-icon" style={{ fontSize: "20px" }} />
                                        <span className="role">Payment Types</span></NavLink>
                                </li>


                            </ul>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" onClick={logout} aria-expanded="false" aria-controls="ui-basic">
                            <i className="icon-power menu-icon text-danger" />
                            <span className="">Logout</span>
                        </a>
                    </li>
                </ul>
            </nav>}
    </>;
}
