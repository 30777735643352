import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import api from '../../utils/api'
import { notifySuccess } from '../../utils/toast'

export const RoleAccess = () => {
  const [rolesList, setrolesList] = useState()
  const [rolePermissionItems, setRolePermissionItems] = useState()
  const [collapse, setcollapse] = useState(true)
  let outputdata = { rolePermissions: [] }
  const { id } = useParams()
  const [userpriviledges, setuserpriviledges] = useState()
  const [itemToEdit, setItemToEdit] = useState()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    resetField
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const {
    register: register2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    getValues: getValues2,
    reset: reset2
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const handleOnChangeRole = async e => {
    const selectedRoleId = e.target.value
    const response = await api.Roles.view(selectedRoleId)
    setRolePermissionItems(response?.data?.rolePermission)
    console.log('This is the selected Role per:', response?.data?.rolePermission)
  }

  const handleOnChange = async (e, index) => {
    let { name, value, checked, type, id } = e.target
    if (type === 'checkbox') {
      if (name === `canCreate${id}` && checked === true) {
        rolePermissionItems[id].canCreate = true
        rolePermissionItems[id].isVisible = true
        console.log("canCreate set to true")
        setValue2(`isVisible${id}`, true)
      } else if (name === `canCreate${id}` && checked === false) {
        rolePermissionItems[id].canCreate = false
        console.log("canCreate set to false")
      }

      if (name === `canEdit${id}` && checked === true) {
        rolePermissionItems[id].canEdit = true
        rolePermissionItems[id].isVisible = true
        console.log("canEdit set to true")
        setValue2(`isVisible${id}`, true)
      } else if (name === `canEdit${id}` && checked === false) {
        rolePermissionItems[id].canEdit = false
        console.log("canEdit set to false")
      }

      if (name === `canApprove${id}` && checked === true) {
        rolePermissionItems[id].canApprove = true
        rolePermissionItems[id].isVisible = true
        setValue2(`isVisible${id}`, true)
      } else if (name === `canApprove${id}` && checked === false) {
        rolePermissionItems[id].canApprove = false
      }

      if (name === `canDelete${id}` && checked === true) {
        rolePermissionItems[id].canDelete = true
        rolePermissionItems[id].isVisible = true
        setValue2(`isVisible${id}`, true)
      } else if (name === `canDelete${id}` && checked === false) {
        rolePermissionItems[id].canDelete = false
      }

      if (name === `canView${id}` && checked === true) {
        rolePermissionItems[id].canView = true
        rolePermissionItems[id].isVisible = true
        setValue2(`isVisible${id}`, true)
      } else if (name === `canView${id}` && checked === false) {
        rolePermissionItems[id].canView = false
      }

      if (name === `isVisible${id}` && checked === true) {
        rolePermissionItems[id].isVisible = true
        rolePermissionItems[id].canCreate = true
        rolePermissionItems[id].canEdit = true
        rolePermissionItems[id].canApprove = true
        rolePermissionItems[id].canDelete = true
        rolePermissionItems[id].canView = true
        setValue2(`canApprove${id}`, true)
        setValue2(`canCreate${id}`, true)
        setValue2(`canDelete${id}`, true)
        setValue2(`canEdit${id}`, true)
        setValue2(`canView${id}`, true)
      } else if (name === `isVisible${id}` && checked === false) {
        rolePermissionItems[id].isVisible = false
        rolePermissionItems[id].canCreate = false
        rolePermissionItems[id].canEdit = false
        rolePermissionItems[id].canApprove = false
        rolePermissionItems[id].canDelete = false
        rolePermissionItems[id].canView = false
        setValue2(`canApprove${id}`, false)
        setValue2(`canCreate${id}`, false)
        setValue2(`canDelete${id}`, false)
        setValue2(`canEdit${id}`, false)
        setValue2(`canView${id}`, false)
      }
    }

    if (name === 'name') {
      //   setcollapse(false);
      //   setuserpriviledges(value);
      //   const RoleItems = await api.Roles.load(value);
      //   const data = RoleItems.data;
      //   console.log('New ROle Items Here:', RoleItems)
      //   const RolePermissions = RoleItems.data[0].RolePermission

      //   console.log('New ROle Permission Here:', RolePermissions)
      //   reset2();


      setcollapse(false)
      setRolePermissionItems()
      const getRoleName = rolesList.find(x => x.id === value)
      setuserpriviledges(getRoleName.name)
      const roleItemsResponse = await api.Roles.view(value)
      const rolePermission = roleItemsResponse?.data?.rolePermission
      reset2();

      //   try {
      //     if (rolePermission && rolePermission.length > 0) {

      //     //   if (rolePermission) {
      //         console.log('New Role Permissions Here:', rolePermission)

      setTimeout(() => {
        setRolePermissionItems(rolePermission)
        setValue2(`canApprove${id}`, rolePermission.canApprove)
        setValue2(`canCreate${id}`, rolePermission.canCreate)
        setValue2(`canDelete${id}`, rolePermission.canDelete)
        setValue2(`canEdit${id}`, rolePermission.canEdit)
        setValue2(`canView${id}`, rolePermission.canView)
        setValue2(`isVisible${id}`, rolePermission.isVisible)
      }, 100)

      if (name === 'name' && value === '') {
        setcollapse(true)
      }
      setItemToEdit({
        ...itemToEdit,
        [name]: value ?? JSON.parse(value)
      })
      //   } else {
      //     console.error('RolePermission property not found in the response.')
      //   }
      // } else {
      //   console.error('No data or empty array in the response.')
      // }
      //   } catch (error) {
      //     console.error('Error fetching role items:', error)
      //   }
    }
  }

  useEffect(() => {
    const rolesAsync = async () => {
      const response = await api.Roles.loadRoles()
      setrolesList(response.data)
      console.log('Roles List', rolesList)
    }
    rolesAsync()
  }, [])

  const save = async () => {
    try {
      for (const val of rolePermissionItems) {
        let payload = {
          id: val.id,
          moduleName: val.module.moduleName,
          roleId: val.roleId,
          businessId: val.businessId,
          canCreate: val.canCreate,
          canView: val.canView,
          canEdit: val.canEdit,
          canDelete: val.canDelete,
          canApprove: val.canApprove,
          isVisible: val.isVisible
        }
        outputdata.rolePermissions.push(payload)
        console.log("payload: ", payload)
        payload = {}
      }

      const response = await api.Roles.edit(outputdata)
      const lc = JSON.parse(localStorage.getItem("auth"));
      lc.role.rolePermission = response.data;
      localStorage.setItem("auth", JSON.stringify(lc));
      notifySuccess(response.sucessMessage)
    }
    catch (error) {
      console.log(error)
    }
  }


  return (
    <div className='content-wrapper'>
      <div className='card mt-5 mb-1'>
        <div className='card-body'>
          <div className='text-center'>
            <span className='card-title'>ROLE ACCESS</span>
          </div>
          <div className='mt-1 d-flex float-right align-item-center'>
            <Link to={'/roles'}>
              <button class='btn btn-primary btn-sm btn-icon-text d-flex float-right mr-1'>
                <i className='ti-plus mr-1' title='Back'></i>
                <span className='d-none d-md-block'>ADD ROLES</span>
              </button>
            </Link>
            <button
              class='btn btn-primary btn-sm btn-icon-text d-flex float-right mr-1'
              onClick={save}
            >
              <i className='ti-save mr-1' title='Back'></i>
              <span className='d-none d-md-block'>SAVE</span>
            </button>
          </div>



          <div className='row mt-5'>
            <span className='card-title text-uppercase'>
              <div className='form-group col-md-3'>
                <label htmlFor='roleName' className="form-label">
                  Roles<span className='text-danger'>*</span>
                </label>
                <select
                  className='form-control2 form-control-sm text-uppercase'
                  type='text'
                  id='roleName'
                  name='roleName'
                  {...register("name", { required: true, onChange: (e) => handleOnChange(e) })}
                >
                  <option value=''>Select Role</option>
                  {rolesList?.map(role => (
                    <option key={role.id} value={role.id}>
                      {role.name}
                    </option>
                  ))}
                </select>
              </div>
            </span>
          </div>
        </div>
      </div>
      <div
        className={collapse ? 'card mb-1 collapse' : 'card mb-1'}
        id='collapseExample'
      >
        <div className='card-body'>
          <div className='text-center'>
            <span className='card-title'>{userpriviledges}</span>
          </div>
          <div
            className='table-responsive'
            style={{ height: '500px', overflow: 'auto' }}
          >
            <table className='table'>
              <thead
                style={{
                  position: 'sticky',
                  top: '0',
                  zIndex: '1',
                  backgroundColor: 'white'
                }}
              >
                <tr>
                  <th>S/N</th>

                  <th>Module</th>
                  <th>Can Create</th>
                  <th>Can Edit</th>
                  <th>Can Delete</th>
                  <th>Can View</th>
                  <th>Is Visible</th>
                  <th>Can Approve</th>
                </tr>
              </thead>
              <tbody>
                {rolePermissionItems &&
                  rolePermissionItems?.map((module, index) => (
                    <tr
                      key={index}
                      className='flex justify-content-center align-items-center'
                    >
                      <td>{index + 1}</td>

                      <td className='text-capitalize'>
                        {module.module.moduleName}
                      </td>
                      <td>
                        {/* <div className='form-check form-switch'>
                          <div className={`form-group custom-switch in${index}`}>
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`canCreate${index}`}
                              defaultChecked={module?.canCreate}
                              name="canCreate"
                              {...register2(`canCreate${index}`, {
                                onChange: e => handleOnChange(e)
                              })}
                            />
                            <label className="custom-control-label" htmlFor={`canCreate${index}`}></label>
                          </div>
                        </div> */}

                        <div className='form-check form-switch'>
                          <div className='custom-switch'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name={`canCreate${index}`}
                              id={index}
                              defaultValue={module.canCreate}
                              defaultChecked={module.canCreate}
                              {...register2(`canCreate${index}`, {
                                onChange: e => handleOnChange(e)
                              })}
                            />
                          </div>
                        </div>
                      </td>

                      <td>

                        {/* <div className='form-check form-switch'>
                          <div className={`form-group custom-switch in${index}`}>
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name={`canEdit${index}`}
                              id={index}
                              defaultChecked={module?.canEdit}
                              {...register2(`canEdit${index}`, {
                                onChange: e => handleOnChange(e)
                              })}
                            />
                            <label className="custom-control-label" htmlFor={`canEdit${index}`}></label>
                          </div>
                        </div> */}

                        <div className='form-check form-switch'>
                          <div className='custom-switch'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name={`canEdit${index}`}
                              id={index}
                              defaultChecked={module.canEdit}
                              defaultValue={module.canEdit}
                              {...register2(`canEdit${index}`, {
                                onChange: e => handleOnChange(e)
                              })}
                            />
                          </div>
                        </div>
                      </td>

                      <td>
                        {/* <div className='form-check form-switch'>
                          <div className={`form-group custom-switch in${index}`}>
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`canDelete${index}`}
                              defaultChecked={module?.canDelete}
                              name="canDelete"
                              {...register2(`canDelete${index}`, {
                                onChange: e => handleOnChange(e)
                              })}
                            />
                            <label className="custom-control-label" htmlFor={`canDelete${index}`}></label>
                          </div>
                        </div> */}

                        <div className='form-check form-switch'>
                          <div className='custom-switch'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name={`canDelete${index}`}
                              id={index}
                              defaultChecked={module.canDelete}
                              defaultValue={module.canDelete}
                              {...register2(`canDelete${index}`, {
                                onChange: e => handleOnChange(e)
                              })}
                            />
                          </div>
                        </div>
                      </td>

                      <td>

                        {/* <div className='form-check form-switch'>
                          <div className={`form-group custom-switch in${index}`}>
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`canView${index}`}
                              defaultChecked={module?.canView}
                              name="canView"
                              {...register2(`canView${index}`, {
                                onChange: e => handleOnChange(e)
                              })}
                            />
                            <label className="custom-control-label" htmlFor={`canView${index}`}></label>
                          </div>
                        </div> */}


                        <div className='form-check form-switch'>
                          <div className='custom-switch'>
                            <input
                              type='checkbox'
                              className='form-check-input'
                              name={`canView${index}`}
                              id={index}
                              defaultChecked={module.canView}
                              defaultValue={module.canView}
                              {...register2(`canView${index}`, {
                                onChange: e => handleOnChange(e)
                              })}
                            />
                          </div>
                        </div>


                      </td>
                      <td>

                        {/* <div className='form-check form-switch'>
                          <div className={`form-group custom-switch in${index}`}>
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id={`isVisible${index}`}
                              defaultChecked={module?.isVisible}
                              name="isVisible"
                              {...register2(`isVisible${index}`, {
                                onChange: e => handleOnChange(e)
                              })}
                            />
                            <label className="custom-control-label" htmlFor={`isVisible${index}`}></label>
                          </div>
                        </div> */}

                        <div className='form-check form-switch'>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            name={`isVisible${index}`}
                            id={index}
                            defaultChecked={module.isVisible}
                            defaultValue={module.isVisible}
                            {...register2(`isVisible${index}`, {
                              onChange: e => handleOnChange(e)
                            })}
                          />
                        </div>
                      </td>
                      <td>
                        {/* <div className='form-check form-switch'> */}
                        {/* <div className={`form-group custom-switch in${index}`}>
    <input
      type="checkbox"
      className="custom-control-input"
      id={`canApprove${index}`}
      name='canApprove'
      defaultChecked={module?.canApprove}
      onChange={(e) => handleOnChange(e, index)}
    />
    <label className="custom-control-label" htmlFor={`canApprove${index}`}></label>
  </div>
</div> */}


                        <div className='form-check form-switch'>
                          <input
                            type='checkbox'
                            className='form-check-input'
                            name={`canApprove${index}`}
                            id={index}
                            defaultChecked={module.canApprove}
                            defaultValue={module.canApprove}
                            {...register2(`canApprove${index}`, {
                              onChange: e => handleOnChange(e)
                            })}
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}
