import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { regex, validFileType } from '../../utils/rejex';
import { notifySuccess } from '../../utils/toast';
import { useHistory, Link } from "react-router-dom";
import api from '../../utils/api';
import Backbutton from '../../components/backbutton';

export default function Createbankdetail() {
    const { register, handleSubmit, formState: { errors }, reset, watch, trigger, control, setValue } = useForm({
        mode: "onChange",
        reValidateMode: 'onChange'
    });
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [bankData, setBankData] = useState({
        bankName: "",
        accountName: "",
        bankCode: "",
        accountNumber: "",
        accountType: "",
        isDefault: false,
    })

    const handleOnChange = (e) => {
        const { name, value, checked } = e.target
        setBankData({
            ...bankData,
            [name]: value === 'on' ? checked : value ?? JSON.parse(value)
        })
    }

    // get all bank
    const [banks, setBanks] = useState([]);

    useEffect(() => {
        const getBanks = async () => {
            const response = await api.Utility.getAllBanks1();
            setBanks(response.data);
        };
        getBanks();
    }, []);

    const saveBankDetails = async (bankData) => {
        try {
            setLoading(true);
            const getBankCode = banks.find(x => x.name === bankData.bankName);
            bankData.bankCode = getBankCode.code;
            // console.log(bankData)

            const response = await api.Business.saveBankDetails(bankData);
            notifySuccess(response.sucessMessage)
            setLoading(false)
            if (response?.sucessMessage) {
                reset(bankData.value);
                history.push('/listbankdetails')
            }
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }

    return <>
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="card mb-5">
                    <div className="card-body">
                        <div className="text-center">
                            <span className="card-title">CREATE PAYMENT COLLECTION BANK ACCOUNT</span>
                        </div>
                        <div className="d-flex float-right mt-2">
                            <Backbutton ></Backbutton>
                            <button type="submit" onClick={handleSubmit(saveBankDetails)} class="btn btn-primary btn-sm btn-icon-text text-white d-flex" disabled={loading ? true : false}>
                                <i className="ti-save mr-1" title="Submit"></i>
                                <span className="d-none d-md-block">{loading ? "SAVING..." : "SAVE"}</span>
                            </button>
                        </div>

                        <form className="row createBankDetail mt-5">
                            <div className="form-group col-md-3">
                                <label HtmlFor="bankName">Select Bank Name<span className="text-danger">*</span>
                                    {errors.bankName && (
                                        <span className="text-danger">required</span>
                                    )}
                                </label>
                                <select className="form-control" name="bankName" id="bankName"
                                    onChange={(e) => handleOnChange(e)}

                                    {...register("bankName", {
                                        required: true
                                    })}
                                >
                                    <option value="">Select Bank</option>
                                    {banks.map((bank) => (
                                        <option key={bank.id} value={bank.name}>{bank.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="accountName">Account Name<span className='text-danger'>*</span>
                                    {errors.accountName && (
                                        <span className="text-danger"> required</span>
                                    )}
                                </label>
                                <input type="text"
                                    className="form-control text-capitalize"
                                    name="accountName"
                                    id="accountName"
                                    placeholder="Account Name"
                                    {...register("accountName", {
                                        required: true,
                                        onChange: (e) => { handleOnChange(e) }
                                    })}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="accountNumber">Account Number<span className='text-danger'>*</span>
                                    {errors.accountNumber && (
                                        <span className="text-danger"> required</span>
                                    )}
                                </label>
                                <input type="text"
                                    className="form-control text-capitalize"
                                    name="accountNumber"
                                    id="accountNumber"
                                    placeholder="Account Number"
                                    {...register("accountNumber", {
                                        required: true,
                                        onChange: (e) => { handleOnChange(e) }
                                    })}
                                />
                            </div>
                            <div className="form-group col-md-3">
                                <label HtmlFor="accountType">Account Type <span className="text-danger">*</span>
                                    {errors.accountType && (
                                        <span className="text-danger">required</span>
                                    )}
                                </label>
                                <select className="form-control" name="accountType" id="accountType"
                                    {...register("accountType", {
                                        required: false,
                                        onChange: (e) => { handleOnChange(e) }
                                    })}
                                >
                                    <option value="" >Select Account Type</option>
                                    <option value="savings" >Savings Account</option>
                                    <option value="current" >Current Account</option>
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <label htmlFor="canChangeIsCredited">Is Default</label>
                                <div className="custom-control custom-switch">
                                    <input type="checkbox"
                                        className="custom-control-input"
                                        id="isDefault"
                                        name="isDefault"
                                        // disabled={existedDefaultMeasure ? true : false}
                                        {...register("isDefault", { onChange: (e) => handleOnChange(e) }
                                        )}
                                    />
                                    <label className="custom-control-label" htmlFor="isDefault"></label>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </>;
}
