import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import api from '../utils/api';


export default function Paginate({ pageCount, apiToCall, setData, limit, loc, bus, proCategory, proManufacturer, proOwner, proGenre, sortBy, setPage, sortBy1, haulerRequestType, documentTypeId, type, fromDate, toDate, search, participant }) {


    const handlePageClick = async (selectedPage) => {
        let currentPage = selectedPage.selected + 1;
        if (sortBy) {
            if (window.location.pathname === "/placeorder") {
                const { data: { data } } = await (apiToCall(currentPage, limit, loc, bus, "", proCategory, proManufacturer, proOwner, proGenre, sortBy));
                setData(data);
                window.scrollTo(0, 0)

            } else {
                if (window.location.pathname === "/addProduct") {
                    setPage(currentPage)
                }
                const { data: { data } } = await apiToCall(currentPage, limit, "", proCategory, proManufacturer, proOwner, proGenre, sortBy);
                setData(data);
                window.scrollTo(0, 0)
            }

        } else if (window.location.pathname === "/admin/businesslist") {
            const { data: { data } } = await apiToCall(currentPage, limit, "", sortBy1, participant);
            setData(data);
            window.scrollTo(0, 0)
        } else if (haulerRequestType) {
            const { data: { data } } = await apiToCall(currentPage, limit, "", haulerRequestType);
            setData(data);
            window.scrollTo(0, 0)
        } else if (documentTypeId) {
            const { data: { data } } = await apiToCall(currentPage, limit, search, documentTypeId, fromDate, toDate, type);
            setData(data);
            window.scrollTo(0, 0)
        }
        else {
            const { data: { data } } = await apiToCall(currentPage, limit, "", loc || null, bus || null);
            // if (window.location.pathname === "/orderlist") {
            //     for (var value of data.values()) {
            //         const responses = await api.Ordering.getById(value.id);
            //         if (responses?.data?.issueNoteNo) {
            //             const issueno = responses?.data?.issueNoteNo
            //             const a = await api.Ordering.getDeliveryNote(issueno);
            //             if (a.data) {
            //                 value.edit = false
            //             }
            //             else {
            //                 value.edit = true
            //             }
            //         } else {
            //             value.edit = true
            //         }
            //     }
            // }
            setData(data);

            window.scrollTo(0, 0)
        }
    };

    return <>

        <div className='row mt-3'>
            <div className="mx-auto">
                <nav aria-label="Page navigation example" style={{ zIndex: "auto" }}>
                    <ReactPaginate
                        previousLabel={"previous"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={3}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination justify-content-center"}
                        pageClassName={"page-item"}
                        pageLinkClassName={"page-link"}
                        previousClassName={"page-item"}
                        previousLinkClassName={"page-link"}
                        nextClassName={"page-item"}
                        nextLinkClassName={"page-link"}
                        breakClassName={"page-item"}
                        breakLinkClassName={"page-link"}
                        activeClassName={"active"}
                    />
                </nav >
            </div >
        </div >
    </>
}