import React, { useEffect, useRef, useState } from 'react'
import Backbutton from '../../components/backbutton'
import { useForm } from 'react-hook-form';
import { notifySuccess } from '../../utils/toast';
import api from '../../utils/api';
import Limit from '../../components/limit';
import Paginate from '../../components/paginate';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Swal from 'sweetalert2';
import moment from 'moment';

export const Roles = () => {
    const { register, handleSubmit, formState: { errors } } = useForm({
        mode: "onChange",
        reValidateMode: 'onChange'
    });
    const page = 1;
    const [limit, setLimit] = useState(15);
    const [search, setSearch] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [listRoles, setListRoles] = useState([]);
    const [itemToCreate, setItemToCreate] = useState({
        roleName: "",
    })
    const auth = JSON.parse(window.localStorage.getItem('auth'));
    const getRoles = async () => {
        const response = await api.Roles.load(page, limit, search);
        const count = response.data.totalCount;
        setPageCount(Math.ceil(count / limit))
        setListRoles(response.data)
        console.log('List Roles:', listRoles)
    }
    const history = useHistory();
    const nul = search || limit;
    const nu = (nul ? null : listRoles);
    useEffect(() => {
        getRoles();
    }, [nu, limit]);

    const handleOnChange = (e) => {
        const { name, value } = e.target
        let parsedValue = value
        setItemToCreate({
            ...itemToCreate,
            [name]: parsedValue ?? JSON.parse(parsedValue),
        })
    }
    const saveRole = async () => {
        const hasPermissionToSave = (auth?.role.rolePermission.find(e => e.module.moduleName === "Role Setting"))?.canCreate
        if (hasPermissionToSave) {

            try {
                // setLoading(true);

                setLoading(true)
                const result = await api.Roles.save(itemToCreate);
                notifySuccess(result.sucessMessage);
                setLoading(false);

                if (result?.sucessMessage) {
                    setLoading(false)
                    getRoles();
                } else {
                    setLoading(false);
                }
            }
            catch (error) {
                console.log(error)
                setLoading(false);
            }
        } else {
            history.push('/403')
        }
    }

    const deleteRole = async (id) => {
        const hasPermissionToDelete = (auth?.role.rolePermission.find(e => e.module.moduleName === "Role Setting"))?.canDelete
        if (hasPermissionToDelete) {
            try {
                Swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#4B49AC',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete it!'
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        const del = await api.Roles.delete(id);
                        if (del.sucessMessage) {
                            notifySuccess(del.sucessMessage)
                            const response = await api.Roles.load(page, limit, "");
                            const count = response.data.totalCount;
                            setPageCount(Math.ceil(count / limit))
                            setListRoles(response.data);
                            console.log('List Rolesss', setListRoles)

                        }
                    }
                })
            }
            catch (error) {
                console.log(error)
            }
        } else {
            history.push('/403');
        }
    }

    const searchPartner = async (value) => {
        if (!value) {
            const getPartners = async () => {
                const response = await api.Roles.load(page, limit, "")
                setListRoles(response.data)
            }
            getPartners();
        }
        else if (value) {
            // setSearch(value)
            const getPartners = async () => {
                const response = await api.Roles.load(page, limit, value);
                setListRoles(response.data)
            }
            getPartners();
        }
    }
    return (
        <div className="main-panel">
            <div className="content-wrapper">
                <div className="card mb-1">
                    <div className="card-body">
                        <div className="text-center page-head">
                            <span className="card-title">CREATE ROLE</span>
                        </div>
                        <div className="d-flex float-right mt-2">
                            <Backbutton ></Backbutton>
                            <button type="submit" onClick={handleSubmit(saveRole)} class="btn btn-primary btn-sm btn-icon-text text-white d-flex" disabled={loading ? true : false}>
                                <i className="ti-save mr-1" title="Submit"></i>
                                <span className="d-none d-md-block">{loading ? "SAVING..." : "SAVE"}</span>
                            </button>
                        </div>


                        <form className="row mt-3">
                            <div className="form-group col-md-3">
                                <label htmlFor="roleName">Role Name<span className="text-danger">*</span>
                                    {errors.roleName && (
                                        <span className="text-danger"> required</span>
                                    )}
                                </label>
                                <input type="text"
                                    className="form-control text-capitalize"
                                    name="roleName"
                                    id="roleName"
                                    placeholder="Role Name"
                                    {...register("name", {
                                        required: true,
                                        onChange: (e) => { handleOnChange(e) }
                                    })}
                                />
                            </div>
                        </form>
                    </div>
                </div>
                <div class="mt-5 stretch-card grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <div className="text-center page-head">
                                <span className="card-title">ROLES LIST</span>
                            </div>
                            <form className="mt-3">
                                <Limit setLimit={setLimit} />

                                <div className="form-group col-md-6 mx-auto mt-3">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search Roles" aria-label="Recipient's username" onKeyUp={e => searchPartner(e.target.value)} />
                                    </div>
                                </div>
                            </form>
                            <div className='table-responsive'>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>
                                                ACTION
                                            </th>
                                            <th>
                                                S/N
                                            </th>
                                            <th>
                                                ROLE NAME
                                            </th>
                                            <th>
                                                CREATED BY
                                            </th>
                                            <th>
                                                CREATED AT
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {listRoles &&
                                            listRoles.map((listRole, index) =>
                                                <tr className="py-0" key={listRole.id}>
                                                    <td>
                                                        <div className="">
                                                            <Link to={`/editrole/${listRole.id}`}><i className="ti-pencil btn-icon-append text-success mr-3" /></Link>
                                                            <i style={{ cursor: "pointer" }} onClick={() => deleteRole(listRole.id)} className='ti-trash btn-icon-append text-danger mr-3' />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {index + 1}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {listRole?.name}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {listRole?.createdBy}
                                                    </td>
                                                    <td className="text-uppercase text-wrap">
                                                        {listRole?.createdAt ? moment(listRole?.createdAt).format("DD-MMM-YYYY") : "N/A"}
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            {
                                listRoles.length === 0 &&
                                <div className='row'>
                                    <strong className='text-muted mx-auto mt-5 h5'>No Role Record</strong>
                                </div>
                            }
                            {
                                listRoles.length !== 0 &&
                                <Paginate
                                    limit={limit}
                                    setData={setListRoles}
                                    apiToCall={api.Partnership.load}
                                    pageCount={pageCount}
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
