export const MenuRoute = [
    { menuName: "Dashboard", route: ["dashboard"] },
    { menuName: "Global Doc. Setting", route: ["globalsetting"] },
    { menuName: "Global Business Setting", route: ["globalbusinesssetting"] },
    { menuName: "Product Categories", route: ["viewproductfunction", "editproductfunction", "listproductfunction"] },
    { menuName: "Product Generalization", route: ["listproductgeneralization", "viewproductgeneralization", "editproductgeneralization", "createproductgeneralization"] },
    { menuName: "Product", route: ["listproduct", "viewproduct", "editproduct"] },
    { menuName: "Product Pack", route: ["productpacklist", "viewproductPack", "editproductPack"] },
    { menuName: "Business List", route: ["businesslist", "viewbusiness"] },
    { menuName: "Locations", route: ["locationlist", "viewlocation"] },
    { menuName: "Hauler Verification", route: ["verifyhauler", "viewhauler"] },
    { menuName: "Wallet", route: ["mywallet", "wallettransaction"] },
    { menuName: "Bill Rate", route: ["listbillrate", "viewbillrate", "editbillrate"] },
    { menuName: "Licence Payment Charge", route: ["listlicencepaymentcharge", "viewlicencepaymentcharge", "editlicencepaymentcharges", "createlicencepaymentcharges"] },
    { menuName: "Withdrawal Charge Setup", route: ["listwithdrawalcharge", "viewwithdrawalcharge", "editwithdrawalcharge", "createwithdrawalcharge"] },
    { menuName: "Fund Bonus Wallet", route: ["fundwallet", ""] },
    { menuName: "Charges", route: ["chargelist", "chargeview", "chargeedit"] },
    { menuName: "Discounts", route: ["discountlist"] },
    { menuName: "Country", route: ["listcountries", "viewcountry", "editcountry"] },
    { menuName: "Region", route: ["listregion", "editregion"] },
    { menuName: "Document Types", route: ["listdocumenttypes", "viewdocumenttype"] },
    { menuName: "Participants", route: ["listparticipanttype", "viewparticipanttype"] },
    { menuName: "Payment Types", route: ["listpaymenttype", "viewpaymenttype"] },
    { menuName: "Conference", route: ["conferencelist", "viewconference", "editconference"] },
    { menuName: "Currency", route: ["currency"] },
    { menuName: "Advert", route: ["listadvert"] },
    { menuName: "Conference Exhibitor", route: ["conferenceexhibitorlist"] },
    { menuName: "Document Report", route: ["aggregatedreport"] },
    { menuName: "Wallet Transactions", route: ["walletTransactions"] },



];

