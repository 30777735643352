import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import api from '../utils/api';
import { MenuRoute } from '../utils/menuRoute'


export function AllowAdminOnly(ComposedComponent) {


    const Authenticated = (props) => {
        const history = useHistory();
        useEffect(() => {
            if (api.Auth.isAuth() && (!api.Auth.isAdmin() || !canActivate())) {
                history.push('/403');
            }
        }, [])
        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}

const canActivate = () => {
    const Auth = JSON.parse(window.localStorage.getItem('auth'));
    const addr = window.location.pathname.split('/');
    const filtered = addr.filter(x => x !== "" && x !== "admin");
    if (Auth.email === "admin@p2p.com") { return true } else {
        for (const value of filtered) {
            const checker = MenuRoute.find(x => x.route.find(y => y === value));
            if (checker) {
                console.log(checker);
                if (Auth.roles.includes(checker.menuName)) {
                    return true;
                } else {
                    return false;
                };
            } else {
                return false;
            };
        }

    }

}