import React, { useState, useEffect } from 'react'
import api from '../../utils/api';
import { formatter, formatter1, formatter2, fourDecimal, toDate } from "../../utils/formatter";
import { usePaystackPayment } from 'react-paystack';
import { notifyError, notifySuccess } from '../../utils/toast';
import { useHistory } from 'react-router-dom';
import { addPaystackChargesToAmount } from '../../utils/addPaystackChargesToAmount';




export default function ExhibitionPayment() {

    const history = useHistory();
    const [fundAmount, setFundAmount] = useState(0);
    const [data, setData] = useState({});
    const [checked, setChecked] = useState(false);
    const auth = JSON.parse(window.localStorage.getItem('auth'));
    const [accepted, setAccepted] = useState(false);
    const [ongoingConferences, setOngoingConferences] = useState([]);
    const [metadata, setMetaData] = useState({ menuFrom: 'exhibition', conferenceId: '' })
    const [prevTransaction, setPrevTransaction] = useState();

    const config = {
        reference: (new Date()).getTime().toString(),
        email: auth.email,
        amount: fundAmount * 100,
        publicKey: process.env.REACT_APP_PAYSTACK_KEY,
        metadata
    };

    const initializePayment = usePaystackPayment(config);


    const setCheckChange = (e) => {
        setChecked(!checked);
        if (e.target.checked) {
            setFundAmount(fundAmount + data?.exhibitionAdvertAmount);
        } else {
            setFundAmount(fundAmount - data.exhibitionAdvertAmount);
        }
    }

    const setCheckAccepted = (e) => {
        setAccepted(!accepted);
    }

    const verifyPayment = async (reference) => {
        try {
            const res = await api.Wallet.verifyPayment(reference, fundAmount, 'exhibition');
            if (res.sucessMessage) {
                notifySuccess(res.sucessMessage);
                history.push('/dashboard');
            }

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const businessDocument = async () => {
            const response = await api.BusinessSetting.getByIdGlobal();
            setData(response.data);
            setFundAmount(response.data?.exhibitionTransactionAmount);
        };
        businessDocument();
    }, []);

    const onSuccess = (data) => {
        verifyPayment(data.reference);
    };

    const onClose = () => {
        console.log('closed')
        setChecked(!checked);
    }


    const handlePayNow = () => {

        if (!metadata.conferenceId) {
            return notifyError('Please select the conference');
        }
        if (!accepted) {
            return notifyError('Please accept the terms to confirm');
        }

        if (!fundAmount || fundAmount <= 0) {
            return notifyError('amount to pay cannot be 0');
        }

        initializePayment(onSuccess, onClose);
    }

    const loadConferences = async () => {
        const response = await api.Conference.load(1, 1000, "");
        setOngoingConferences(response.data.data);
    }

    useEffect(() => {
        loadConferences()
    }, []);

    const handleOnChange = async (e) => {
        setMetaData({ ...metadata, conferenceId: e.target.value });
        const r = await api.Wallet.getPrevConferenceTrans(auth.businessId, e.target.value);
        setPrevTransaction(r.data);
        if (r.data.transAmountPaid) {
            setFundAmount(fundAmount - data.exhibitionTransactionAmount);
        }
        if (r.data.advertAmountPaid) {
            setFundAmount(fundAmount - data.exhibitionAdvertAmount);
        }
    }


    return (
        <>
            <div className="main-panel">
                <div className="content-wrapper">
                    <div className="card mb-5">
                        <div className="card-body">
                            <div className="text-center">
                                <span className="card-title">EXHIBITION PAYMENT </span>
                            </div>

                            <form className="row form-group mt-5">
                                <div className="form group col-md-4 mb-3">
                                    <label HtmlFor="conferenceId" className="font-weight-bold">Select Conference<span className='text-danger'>*</span>

                                    </label>
                                    <select
                                        className="form-control text-capitalize"
                                        id="conferenceId"
                                        name="conferenceId"
                                        onChange={(e) => handleOnChange(e)}
                                    >
                                        <option value="">Select Conference</option>
                                        {ongoingConferences.map((con) => (
                                            <option key={con.id} value={con.id}>{con.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="form-group col-md-4 mb-3">
                                    <label htmlFor="exhibitionTransactionPayment" className="font-weight-bold">Exhibition Transaction Payment</label>
                                    {!prevTransaction?.transAmountPaid ? <div className="custom-control custom-switch">
                                        <span className="mr-5"><i className="mdi mdi-currency-ngn mr-1"></i>
                                            &#8358;{formatter1.format(data?.exhibitionTransactionAmount ? data?.exhibitionTransactionAmount : 0)}
                                        </span>
                                        <input type="checkbox"
                                            checked={true}
                                            disabled={true}
                                            className="custom-control-input"
                                            id="exhibitionTransactionPayment"
                                            name="exhibitionTransactionPayment"
                                        />
                                        <label className="custom-control-label" htmlFor="exhibitionTransactionPayment"></label>
                                    </div> : <div> <span className="badge-success p-1 px-2 rounded-pill"> PAID </span> </div>}
                                </div>
                                <div className="form-group col-md-4 mb-3">
                                    <label htmlFor="exhibitionAdvert" className="font-weight-bold">Exhibition Advert Payment</label>
                                    {!prevTransaction?.advertAmountPaid ? <div className="custom-control custom-switch">
                                        <span className="mr-5"><i className="mdi mdi-currency-ngn mr-1"></i>
                                            &#8358;{formatter1.format(data?.exhibitionAdvertAmount ? data?.exhibitionAdvertAmount : 0)}
                                        </span>
                                        <input type="checkbox"
                                            onChange={(e) => setCheckChange(e)}
                                            className="custom-control-input"
                                            id="exhibitionAdvert"
                                            name="exhibitionAdvert"
                                        />
                                        <label className="custom-control-label" htmlFor="exhibitionAdvert"></label>
                                    </div> : <div> <span className="badge-success p-1 px-2 rounded-pill"> PAID </span> </div>}
                                    {checked && <div className="text-success font-weight-bold my-3">Please contact Admin to post your advert.</div>}
                                </div>
                            </form>
                            {(!prevTransaction?.transAmountPaid || !prevTransaction?.advertAmountPaid) && <div className="float-right mb-3">

                                <div className='mb-2'><span className="total font-weight-bold" style={{ marginRight: "78px" }}>Total: <span className="mr-5 font-weight-normal"><i className="mdi mdi-currency-ngn mr-1"></i>{formatter2.format(fundAmount)} <span className="text-success font-weight-bold">final amount will be shown after charges have been applied</span></span></span></div>
                                <div className='mb-3'>
                                    <input type="checkbox" onChange={setCheckAccepted} className="form-control-input mr-1"
                                    />
                                    <span>I Accept Exhibition payment is non-refundable. </span>
                                </div>
                                <button disabled={!accepted} onClick={handlePayNow} type="button" class="btn btn-primary btn-sm btn-icon-text text-white d-flex float-right">
                                    <i className="mdi mdi-cash mr-1" title="Pay Now"></i>
                                    <span className="d-none d-md-block">PAY NOW</span>
                                </button>
                            </div>}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
