import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import api from '../utils/api';


export function AllowUserOnly(ComposedComponent) {
    const Authenticated = (props) => {
        const history = useHistory();
        useEffect(() => {
            if (api.Auth.isAuth() && api.Auth.isAdmin()) {
                history.push('/403');
            }
        }, [])
        return <ComposedComponent {...props} />;
    }
    return Authenticated;
}