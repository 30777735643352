import api from "./api";
import { notifySuccess } from "./toast"

export async function onLogin(payload) {

    await api.Auth.saveAuthData(payload.data);
    localStorage.setItem("locationId", payload?.data?.locationId);
    notifySuccess(payload.sucessMessage);
    const lastAccessedUrl = localStorage.getItem("lastAccessedUrl");
    if (lastAccessedUrl !== null) {
        if (payload.data.isAdmin) {
            window.location.href = `${window.location.origin}/admin/dashboard`;
        } else {
            window.location.href = `${window.location.origin}${lastAccessedUrl}`;
        }
    } else {
        window.location.href = payload.data.isAdmin ? `${window.location.origin}/admin/dashboard` : `${window.location.origin}/dashboard`;
    }
}