import React, { createContext, useEffect, useState } from 'react'
import './App.css';
import RouterPage from './routerPage';
import "react-toastify/dist/ReactToastify.min.css";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import { notifySuccess } from '../src/utils/toast';
import AppLoading from "./components/AppLoading";
import api, { API_ROOT } from './utils/api';
import io from "socket.io-client";
import { p2pContext } from './utils/p2pContext'

import PubSub from 'pubsub-js';



toast.configure({
  position: "top-center",
  style: {
    zIndex: "2000",
  },
});

// const socket = io.connect('http://localhost:4000');

function App() {

  // function handleLoad() {
  //   // Remove the event listener to prevent it from firing again
  //   window.removeEventListener('load', handleLoad);

  //   // Make the API request to fetch updated roles
  //   api.Roles.updated()
  //     .then(res => {
  //       const getAuth = JSON.parse(localStorage.getItem('auth'));
  //       getAuth.role.rolePermission = res?.data;
  //       localStorage.setItem('auth', JSON.stringify(getAuth));
  //       // console.log('first', getAuth);
  //       console.log("res", res);
  //     })
  //     .catch(error => {
  //       console.error('Error fetching role data:', error);
  //     });
  // }

  // window.addEventListener('load', handleLoad);

  // useEffect(() => {


  window.addEventListener('load', async () => {
    try {
      const auth = JSON.parse(localStorage.getItem('auth'));

      if (auth?.token) {
        const res = await api?.Roles.updated();
        // .then(res => {
        const getAuth = JSON.parse(localStorage.getItem('auth'));
        getAuth.role.rolePermission = res?.data;
        localStorage.setItem('auth', JSON.stringify(getAuth));
        // console.log('first', getAuth);
        console.log("res", res);
      }

    } catch (error) {
      console.error('Error fetching role data:', error);
    }
  })

  // useEffect(() => {
  //   if(window.location.pathname === '/' || window.location.pathname === '/signin') {
  //     const loggedInUser = JSON.parse(localStorage.getItem("auth"));
  //     if (loggedInUser) {

  //       return window.location.href = loggedInUser.isAdmin ?  `${window.location.origin}/admin/dashboard` : `${window.location.origin}/dashboard`;
  //     }
  //   }

  // }, []);

  // const [notificationData, setNotificationData] = useState([])

  // useEffect(() => {
  //   const notiGateWayUrl = process.env.REACT_APP_GATEWAY_URL
  //   const notisocket = io(`${notiGateWayUrl}/notigateway`, {
  //     withCredentials: true,
  //     extraHeaders: {
  //       "my-custom-header": "abcd"
  //     }
  //   });
  //   const auth = JSON.parse(localStorage.getItem("auth"))

  //   notisocket.on('connect', () => {
  //     console.log("i have connected")
  //     if (auth && auth.businessId) {
  //       notisocket.emit('notimsgToServer', {
  //         socketId: auth.businessId,
  //       })
  //     }

  //     notisocket.on("notimsgToClient", async (data) => {
  //       console.log("i have data", data)

  //       PubSub.publish("notiCount", data);
  //     });
  //   })

  //   return () => notisocket.disconnect();
  // }, []);

  //   const notificationData = async() => {
  //     const response = await api.Notification.load();
  // return response?.data.data
  //   }

  // const [incrementQuoteCount, setincrementQuoteCount] = useState({});
  // const [incrementCartCount, setincrementCartCount] = useState({});
  // const [notificationCount, setnotificationCount] = useState({});


  return (
    //<p2pContext.Provider 
    //   value={
    //     { incrementQuoteCount, setincrementQuoteCount, 
    //       incrementCartCount, setincrementCartCount, 
    //       notificationCount, setnotificationCount 
    //     }
    // }>
    <React.Suspense fallback={<AppLoading />}>
      <RouterPage />
    </React.Suspense>
    //</p2pContext.Provider>

  );
}

export default App;
