import React from 'react';
import moment from 'moment'

export default function Footer() {
  return <>
    <footer className="footer">
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block"> Copyright © <span className='font-weight-bold'>{moment().format('YYYY')}</span>
          . All rights reserved.</span>
      </div>
      <div className="d-sm-flex justify-content-center justify-content-sm-between">
        <span className="text-muted text-center text-sm-left d-block d-sm-inline-block">Powered By <a href="https://www.netopconsult.com/" target="_blank">Netop Business Consulting Ltd.</a></span>
      </div>
    </footer>


  </>;
}
